/** The ConsentManager expects ConfirmicBootstrapArgs to be available
 *  on the window when embedded into a client's page.
 *  Unfortunately, this dependency has bled out into the components themselves
 *  There's a larger piece of work required to clean that dependency up, especially if
 *  we're going to make the components available as a library outside the company
 * 
 *  Until then, this shim ensures that the library components don't crash :\
 */
window.ConfirmicBootstrapArgs = {
    parentWindow: window
}