import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { storeTokens } from '../../../utils/multiAccounts'
import actorFields from './actorFields'

export const loginMutation = gql`
    mutation login ($emailAddress:String!, $password:String!) {
        Account {
            login(email:$emailAddress, password:$password) {
                ${actorFields}
            }
        }
    }
`

const useLogin = () => {
	const [mutate] = useMutation(loginMutation)

	return variables => mutate({
		variables,
	}).then(({ data }) => {
		const { Account: { login: { tokens, user } } } = data
		storeTokens(tokens, user)
		return data
	})
}

export default useLogin