import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Flipped } from 'react-flip-toolkit'

import { InputGroup, Label } from '../../../NewComponents/Form'
import { ButtonWithIcon, TextButtonLink } from '../../../NewComponents/Form/Button'

import Input from '../components/Input'

import useValues from '../hooks/useValues'
import useResetPassword from '../hooks/useResetPassword'
import useParams from '../../../hooks/useParams'

import Toast from '../../../NewComponents/Toast'

const ResetPassword = ({ history }) => {
	const [values, setValue] = useValues({})
	const resetPassword = useResetPassword()
	const params = useParams()
	const [error, setError] = useState(null)

	return (
		<>
			{error && <Toast type="error" message={error} />}
			<Flipped flipId="password">
				<InputGroup>
					<Label>
						New Password
					</Label>
					<Input type="password" autocomplete="new-password" minlength="8" onChange={val => setValue('newPassword', val)} />
				</InputGroup>
			</Flipped>
			<Flipped flipId="password2">
				<InputGroup>
					<Label>
						Confirm Password
					</Label>
					<Input type="password" autocomplete="new-password" minlength="8" onChange={val => setValue('confirmNewPassword', val)} />
				</InputGroup>
			</Flipped>
			<Flipped flipId="ctas">
				<div>
					<InputGroup>
						<ButtonWithIcon
							icon="->"
							onClick={async (e, { animationPromise }) => {
								setError(null)
								try {
									await Promise.all([
										resetPassword({
											...values,
											passwordResetToken: params.get('token'),
										}),
										animationPromise,
									])

									history.push('/account/login')
								} catch (e) {
									setError('There was an issue resetting your password, please try again.')
								}
							}}
							disabled={!values.newPassword || !values.confirmNewPassword}
						>
							Reset Password
						</ButtonWithIcon>
					</InputGroup>

					<InputGroup>
						<TextButtonLink to="/account/login">
							Remembered your password?&nbsp;
							<span style={{ color: 'rgb(251, 22, 81)' }}>Log in</span>
						</TextButtonLink>
					</InputGroup>
				</div>
			</Flipped>
		</>
	)
}

export default withRouter(ResetPassword)