import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Flipped } from 'react-flip-toolkit'

import { InputGroup, Label } from '../../../NewComponents/Form'
import { ButtonWithIcon, TextButtonLink } from '../../../NewComponents/Form/Button'
import Toast from '../../../NewComponents/Toast'

import Input from '../components/Input'

import useValues from '../hooks/useValues'
import useLogin from '../hooks/useLogin'
import { sendBackToWp } from '../../../utils/wordpress'

const Login = ({ history }) => {
	const [values, setValue] = useValues({})
	const login = useLogin()
	const [error, setError] = useState(null)

	return (
		<form onSubmit={e => {
			e.preventDefault()
		}}>
			{error && <Toast type="error" message={error} />}
			<Flipped flipId="email">
				<InputGroup>
					<Label>
						Email Address
					</Label>
					<Input tabIndex={1} placeholder="you@company.com" type="email" onChange={val => setValue('emailAddress', val)} />
				</InputGroup>
			</Flipped>

			<Flipped flipId="password">
				<InputGroup>
					<Label>
						Password
						<Link style={{ textDecoration: 'none', color: '#58466D' }} to="/account/forgot">Forgot?</Link>
					</Label>
					<Input tabIndex={2} placeholder="password" type="password" minlength="4" onChange={val => setValue('password', val)} />
				</InputGroup>
			</Flipped>

			<Flipped flipId="ctas">
				<div>
					<InputGroup>
						<ButtonWithIcon tabIndex={3} icon="->" onClick={(e, { animationPromise }) => {
							setError(null)
							login(values)
								.then(({ data }) => {
									if (window.localStorage.isWordpress) {
										try {
											const projectId = data.Account.login.user.organisation.defaultProject.id
											sendBackToWp({ projectId })
										} catch (e) {
											//
										}
									} else {
										animationPromise.then(() => {
											history.push('/dashboard')
										})
									}
								})
								.catch(() => {
									setError('There was an issue with your email or password, please try again.')
								})
							}
							} disabled={!values.emailAddress || !values.password}>
							Log in
						</ButtonWithIcon>
					</InputGroup>

					<InputGroup>
						<TextButtonLink to="/account/signup">
							Don't have an account?&nbsp;
							<span style={{ color: 'rgb(251, 22, 81)' }}>Sign up</span>
						</TextButtonLink>
					</InputGroup>
				</div>
			</Flipped>
		</form>
	)
}

export default withRouter(Login)