import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { compose } from 'redux'
import Developers from './Developers'
import { doesProjectSupportAutoblocking } from '../ThirdParties/useAutoblockingSettings'

const getProject = gql`
  query getProject($id: ID!) {
		Project {
      get(id: $id) {
        id
        onboardingState
        autoblockingEnabled
			}
		}
	}
`

export default compose(
    graphql(getProject, {
      options: (props) => ({
        variables: {
          id: props.selectedProjectId
        }
      }),
        props: ({ data: { Project }, loading }) => Project
          ? ({
            clientId: Project.get.id,
            loading,
            autoblockingEnabled: Project.get.autoblockingEnabled,
            isSnippetValid: doesProjectSupportAutoblocking(Project.get),
          })
          : ({ loading }),
    }),
)(Developers)

