import React, { useContext } from "react";
import styles from "./ProjectSwitcher.module.css";
import Select from "NewComponents/Form/Select";
import TrackingContext, { EventCategories } from "contexts/TrackingContext";

const ProjectSwitcher = ({ projects, selected, setSelected }) => {
  const { track } = useContext(TrackingContext);
  return (
    <div className={styles.projectSwitcher}>
      <div className={styles.projectSwitcherIcon} />

      <Select
        options={projects.map((opt) => ({ value: opt.id, name: opt.name }))}
        className={styles.selectWrapper}
        value={selected}
        showPlaceholder={true}
        placeholder="Select project"
        onChange={(e) => {
          setSelected(e.target.value);
          track(EventCategories.SELECT_CHANGED, {
            eventAction: "Project selected",
          });
        }}
      />
    </div>
  );
};

export default ProjectSwitcher;
