const { updates, ops } = require('fn-update')

function getLeafNodes(nodes, result = [], parent) {
	for (let i = 0, length = nodes.length; i < length; i++){
		if (!nodes[i].children || nodes[i].children.length === 0){
			result.push({
				...nodes[i],
				parent,
			})
		} else {
			result = getLeafNodes(nodes[i].children, result, {
				...nodes[i],
				parent,
			})
		}
	}

	return result
}

const tagManagers = ['google_tag_manager', 'segment', 'cloudflare', 'shopify_stats'];

const itemsToProviders = (items) => {

  const { providers, domainTree, providerTree } = items

	const topLevelProviders = []

	// If the site we're scanning _is_ a provider ... flatten it
	const flattenedTopLevelProvider = updates({
		...providerTree[domainTree.providerId],
		[domainTree.providerId] : ops.delete,
	})(providerTree)

	Object.entries(flattenedTopLevelProvider)
		.forEach(([providerId, children]) => {
			const provider = providers[providerId]

			if (tagManagers.includes(providerId)) {
				topLevelProviders.push(provider)
				if (children) {
					Object.keys(children)
						.forEach(providerId => {
							topLevelProviders.push({
								...providers[providerId],
								embeddedBy: provider,
							})
						})
				}
			} else {
				topLevelProviders.push(provider)
			}
		})

	const topLevelProviderIds = topLevelProviders.map(p => p.id)

	const unrecognised = domainTree.children ? getLeafNodes(domainTree.children.filter(child => !child.providerId)) : []

	unrecognised.filter(item => {
		let parent = item.parent
		const parentProviderIds = [item.providerId]

		while (parent) {
			if (parent.providerId) {
				parentProviderIds.unshift(parent.providerId)
			}
			parent = parent.parent
		}

		return !parentProviderIds.some(pid => topLevelProviderIds.includes(pid))
	}).map(item => {
		const stacktrace = []
		let parent = item.parent
		while (parent) {
			stacktrace.unshift(parent.id)
			parent = parent.parent
		}

		return {
			...item,
			id: stacktrace.join(',')+','+item.id,
			name: item.id,
			isUnrecognised: true,
			stacktrace,
		}
	}).forEach(item => {
		topLevelProviders.push(item)
	})

	const pids = {}
	topLevelProviders.forEach(a => {
		pids[a.id] = a
	})

	return Object.values(pids).map(a => ({
		...a,
		category: null,
	}))
}

export default itemsToProviders
