import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

import styles from './History.module.css'

const historyToGroups = (changes) => {
    const history = changes
        .sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())

    const groups = []

    history.forEach((entry, i, entries) => {
        if (['published', 'unpublished', 'created'].includes(entry.op)) {
            groups.push([entry])
            return
        }
        if (entries[i - 1] && moment(entries[i - 1].date).format('YYYY-MM-DD') !== moment(entry.date).format('YYYY-MM-DD')) {
            groups.push([entry])
        } else {
            if(groups.length === 0) {
                groups.push([entry])
            } else {
                groups[groups.length - 1].push(entry)
            }
        }
    })

    return groups
}

const pastTense = op => `${op}ed`.split('eed').join('ed')

const fieldToHuman = field => {
    switch (field) {
        case 'usage':
            return 'Data use'
        case 'usesCookies':
            return 'Cookie usage'
        case 'isInitial':
            return 'Include in Cookie Widget'
        case 'isMandatory':
            return 'Is "essential"'
        default:
            return field
    }
}

const getDetailsCopy = ({ op, path, value, oldValue, entityType }, index, datapoints) => {
    const un = 'You'

    if (op === 'publish') {
        return <span className={styles.published}>Version&nbsp;&nbsp; <span className={styles.entity}>{value}</span> published</span>
    }

    if (entityType === 'policy') {
        const field = fieldToHuman(path.split('/').slice(-1).join(''))
        if (op === 'replace') {
            let val = value
            if (typeof value === 'boolean') {
                val = value ? 'enabled' : 'disabled'
                return <><span className={styles.entity}>{field}</span> {val}</>
            }
            return <><span className={styles.entity}>{field}</span> changed to <span className={styles.value}>{val}</span></>
        }
        return <><span className={styles.entity}>{field}</span> {pastTense(op)}</>
    }

    if (entityType === 'provider') {

        if (op === 'remove') {
            return <><span className={styles.removedEntity}>{oldValue.name}</span>third-party {pastTense(op)}</>
        }

        if (op === 'replace') {
            // This will happen when the name is replaced as well, so let's just not show this entry
            if (path.includes('referenceId')) return null;

            return <><span className={styles.entity}>{oldValue}</span>third-party {pastTense(op)} with <span className={styles.entity}>{value}</span></>
        }

        return <><span className={styles.entity}>{value?.name}</span>third-party {pastTense(op)}</>
    }

    if (entityType === 'usage') {
        if (op === 'replace') {
            const field = fieldToHuman(path.split('/').slice(-1)[0])
            const datapoint = datapoints.find(({ id }) => id === value)

            if (field === 'datapointId') {
                const oldDatapoint = datapoints.find(({ id }) => id === oldValue)
                return <>Data point <span className={styles.entity}>{oldDatapoint.name}</span> replaced with <span className={styles.entity}>{datapoint.name}</span></>

            }

            return <><span className={styles.entity}>{datapoint?.name || 'A datapoint'}'s</span> {field} changed to <span className={styles.value}>{value}</span></>
        }

        if (op === 'remove'){
        	return <><span className={styles.removedEntity}>{oldValue.purpose}</span>data point {pastTense(op)}</>
				}

        return <><span className={styles.entity}>{value.purpose}</span>data point {pastTense(op)}</>
    }

    return `${un} ${pastTense(op)} a ${entityType}`
}

const History = ({ history = [], className, datapoints }) => {
    const groups = historyToGroups(history)

    return (
        <div className={classnames(styles.box, className)}>
            <div className={styles.boxHeader}>
                <h4 className={styles.boxTitle}>Version History</h4>
            </div>
            <div className={styles.boxBody}>
                {groups.map((group, i) => {
                    const [{ op, date }] = group
                    return (
                        <div className={styles.group} key={i}>
                            <h4 className={styles.major}>{moment(date).format('DD MMMM YYYY [at] HH:MM')}</h4>
                            <>
                                {op !== 'created' && group.map((entry, i) => {
                                    const copy = getDetailsCopy(entry, i, datapoints);
                                    return copy && <div className={styles.details} key={i}>{copy}</div>
                                })}
                            </>
                        </div>
                    )
                })}
                {
                    groups.length > 0 && (
                         <div className={styles.lastGroup}>
                            <h4 className={styles.major}>{moment(groups[groups.length - 1][0].date).format('DD MMMM YYYY [at] HH:MM')}</h4>
                            <span className={styles.details}>Micropolicy created</span>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default History