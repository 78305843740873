import styled from 'styled-components'

const Shimmer = styled.div`
	display: flex;
	border-radius: 6px;
	color: transparent;
	user-select: none;

	@keyframes shimmer {
		0% {
			background-position: -468px 0;
		}
		
		100% {
			background-position: 468px 0; 
		}
	}

	animation: shimmer 2s infinite linear;
	animation-fill-mode: forwards;

	background: #f5f3f7;
	background-image: linear-gradient(to right, #f5f3f7 0%, #ECEAEE 20%, #f5f3f7 40%, #f5f3f7 100%);
	background-repeat: no-repeat;
	background-size: 800px 104px;


	* {
		visibility: hidden;
		margin-bottom: 0;
	}
`

export default Shimmer