import styled from 'styled-components'
import Flex from 'styled-flex-component'
import {default as Input2} from './Input'
import {default as Select2} from './Select'
import {
	Button as Button2,
	TextButton as TextButton2,
	ButtonWithIcon as ButtonWithIcon2,
	TextButtonLink as TextButtonLink2,
} from './Button'
import {default as Checkbox2} from './Checkbox'

export const Input = Input2
export const Select = Select2
export const Checkbox = Checkbox2

export const Button  = Button2
export const TextButton = TextButton2
export const ButtonWithIcon  = ButtonWithIcon2
export const TextButtonLin  = TextButtonLink2

export const InputGroup = styled.div`
	margin-top: 30px;
	width: 100%;

	:last-child {
		margin-top: 10px;
	}
`

export const Label = styled(Flex).attrs({
	as: 'label',
	alignItems: 'center',
})`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	color: #58466D;
	margin-bottom: 8px;
	justify-content: space-between;
`