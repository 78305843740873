import React from "react";
import gql from "graphql-tag";
import Immutable from "immutable";
import client from "../../../../graphql/client";
import logger from "../../../../utils/logger";
import Integrate from "./Integrate";

const getScanResults = gql`
  query getScanResults($domain: String!, $after: Date) {
    Scan {
      results(domain: $domain, after: $after)
    }
  }
`;

const domainTreeToItems = (domainTree) => {
  return Immutable.fromJS(
    domainTree.map((domain) => ({
      ...domain,
      name: domain.id,
      type: domain.type.toLowerCase(),
      children: domain.children && domainTreeToItems(domain.children),
    }))
  );
};

class IntegrateContainer extends React.PureComponent {
  state = {};

  startScan({ domain }) {
    const date = new Date();
    this.setState({
      date,
      domain,
      loading: true,
      complete: false,
      error: false,
    });

    setTimeout(() => {
      this.fetchResults();
    }, 50);
  }

  async fetchResults() {
    try {
      const { date, domain } = this.state;

      const data = await client.query({
        query: getScanResults,
        variables: {
          domain,
          after: date,
        },
        fetchPolicy: "network-only",
      });

      const scanResults = data.data.Scan.results.results;
      const scanMeta = data.data.Scan.results;

      this.setState({
        scanResults,
        scanMeta,
      });

      switch (scanMeta.status) {
        case "LOADING":
          setTimeout(() => {
            this.fetchResults();
          }, 1000);
          break;
        case "ERROR":
          this.setState({
            loading: false,
            error: true,
          });
          break;
        case "READY":
          this.setState({
            loading: false,
            complete: true,
          });
          break;
        default:
          throw new Error("Incorrect status");
      }
    } catch (e) {
      logger.error(e);
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  render() {
    const { loading, complete, error, scanResults, scanMeta } = this.state;
    const children =
      scanResults && scanResults.fullTree && scanResults.fullTree.children;

    return (
      <Integrate
        {...this.props}
        scanLoading={loading}
        scanComplete={complete}
        scanError={error}
        scanResults={
          children && { items: domainTreeToItems(children), meta: scanMeta }
        }
        fetchResults={(domain) => this.startScan({ domain })}
      />
    );
  }
}

export default IntegrateContainer;
