import React from 'react'
import classnames from 'classnames'
import styles from './Loading.module.css'

const Loading = ({ size = 30, className, color = 'white' }) => (
	<svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={classnames(styles.loading, className)}>
		<mask id="path-1-inside-1" fill={color}>
			<path d="M28.0108 15C29.1094 15 30.0136 14.1055 29.8683 13.0165C29.5152 10.3699 28.46 7.8526 26.7959 5.73405C24.7201 3.0915 21.8171 1.22249 18.5523 0.426695C15.2875 -0.369104 11.8501 -0.0456008 8.79112 1.34534C5.73215 2.73627 3.22883 5.11408 1.6825 8.09752C0.136182 11.081 -0.363568 14.4972 0.263379 17.7986C0.890325 21.0999 2.60765 24.0951 5.14002 26.304C7.67239 28.5129 10.8731 29.8075 14.229 29.9802C16.9195 30.1186 19.5847 29.5296 21.9508 28.2924C22.9244 27.7833 23.134 26.5288 22.5006 25.6311V25.6311C21.8673 24.7334 20.6308 24.5381 19.634 25C18.0171 25.7493 16.233 26.0996 14.4335 26.007C11.9677 25.8801 9.61591 24.9288 7.7552 23.3058C5.89449 21.6828 4.63266 19.482 4.172 17.0563C3.71134 14.6306 4.07854 12.1204 5.21473 9.92827C6.35091 7.73614 8.19028 5.989 10.4379 4.96699C12.6855 3.94497 15.2113 3.70727 17.6101 4.292C20.009 4.87672 22.142 6.25001 23.6672 8.19167C24.7803 9.60862 25.5226 11.2684 25.8425 13.0215C26.0397 14.1023 26.9121 15 28.0108 15V15Z"/>
		</mask>
		<path d="M28.0108 15C29.1094 15 30.0136 14.1055 29.8683 13.0165C29.5152 10.3699 28.46 7.8526 26.7959 5.73405C24.7201 3.0915 21.8171 1.22249 18.5523 0.426695C15.2875 -0.369104 11.8501 -0.0456008 8.79112 1.34534C5.73215 2.73627 3.22883 5.11408 1.6825 8.09752C0.136182 11.081 -0.363568 14.4972 0.263379 17.7986C0.890325 21.0999 2.60765 24.0951 5.14002 26.304C7.67239 28.5129 10.8731 29.8075 14.229 29.9802C16.9195 30.1186 19.5847 29.5296 21.9508 28.2924C22.9244 27.7833 23.134 26.5288 22.5006 25.6311V25.6311C21.8673 24.7334 20.6308 24.5381 19.634 25C18.0171 25.7493 16.233 26.0996 14.4335 26.007C11.9677 25.8801 9.61591 24.9288 7.7552 23.3058C5.89449 21.6828 4.63266 19.482 4.172 17.0563C3.71134 14.6306 4.07854 12.1204 5.21473 9.92827C6.35091 7.73614 8.19028 5.989 10.4379 4.96699C12.6855 3.94497 15.2113 3.70727 17.6101 4.292C20.009 4.87672 22.142 6.25001 23.6672 8.19167C24.7803 9.60862 25.5226 11.2684 25.8425 13.0215C26.0397 14.1023 26.9121 15 28.0108 15V15Z" stroke={color} strokeWidth="10" mask="url(#path-1-inside-1)"/>
	</svg>
)

export default Loading