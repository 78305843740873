import React from 'react'
import styled from 'styled-components'

import { Input } from '../../../NewComponents/Form'

const StyledAccountInput = styled(Input)`
	background: #FFFFFF;
	border: 1px solid rgba(96,96,96, 0.4);
	box-sizing: border-box;
	box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #58466D;
	min-width: none;	
`

const AccountInput = ({ onChange, ...props}) => <StyledAccountInput onChange={e => onChange(e.currentTarget.value)} {...props} />

export default AccountInput