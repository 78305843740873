import { useCallback } from 'react';
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const getBillingInfo = gql`
query getBillingInfo($includePaymentDetails: Boolean!) {
  me {
    id
    user {
      id
      organisation {
          id
          highestProjectPlan
          hasPaymentDetails
          paymentDetails @include(if: $includePaymentDetails) {
            coupon
            cardBrand
          }
      }
    }
  }
}
`;


export default function useBilling(includePaymentDetails = false) {
  const { data, refetch, loading, error } = useQuery(getBillingInfo, { variables: { includePaymentDetails }})
  const billingData = data?.me?.user?.organisation

  const refetchBilling = useCallback((...args) => refetch(...args)
    .then(({ data,...rest }) => ({
      ...rest,
      data: data?.me?.user?.organisation
    })), [ refetch ])

  return {
    data: billingData,
    refetch: refetchBilling,
    loading,
    error,
  }
}