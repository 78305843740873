import React from 'react'
import styled from 'styled-components'

import { Title, HR, Text } from '../../../NewComponents/Box'
import { InputGroup } from '../../../NewComponents/Form'
import { TextButtonLink } from '../../../NewComponents/Form/Button'

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const Body = styled(Text)`
	text-align: center;
	margin-top: 42px;
	flex: 1;
`

const EmailSent = (props) => (
	<Container {...props}>
		<Title>Email Sent</Title>
		<HR />

		<Body>
			If we found your account you'll have an email from us.
		</Body>

		<InputGroup>
			<TextButtonLink to="/account/login">
				Remembered your password?&nbsp;
				<span style={{ color: 'rgb(251, 22, 81)' }}>Log in</span>
			</TextButtonLink>
		</InputGroup>
	</Container>
)

export default EmailSent
