import styled from 'styled-components'

const Input = styled.input`
	padding: 12px;
	width: 100%;

	min-width: 139px;
	font-family: Inter;

	background: #ECEAEE;
	border: 1px solid rgba(88, 70, 109, 0.02);
	box-sizing: border-box;
	border-radius: 2px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	/* identical to box height */
	display: flex;
	align-items: center;

	color: #58466D;
	box-shadow: none;

	:focus {
		outline: none;
	}

	::placeholder {
		color: #7A6B8B;
		opacity: 0.7;
	}
`

export default Input