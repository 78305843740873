import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { compose } from 'redux'
import Policies from './Policies'

export const policyFragment = gql`
    fragment PolicyFragment on Policy {
        id
        version
        slug
        name
        description
        icon
        isPaused
        isMandatory
        usesCookies
        allowAnonymous
        isInitial
        type
        usages {
            id
            datapoint {
                id
                name
            }
            purpose
        }
        providers {
            id
            name
            privacyUrl
            referenceId
        }
        history {
            op
            path
            value
            oldValue
            date
            entityType
        }
    }
`

export const getPolicies = gql`
    ${policyFragment}
    query getPolicies($draft:Boolean!) {
        Policy {
            list(draft:$draft) {
                ...PolicyFragment
            }
        }

    }
`

export const createPolicy = gql`
    ${policyFragment}
    mutation createPolicy($policy:CreatePolicyInput!) {
        Policy {
            create(policy:$policy) {
                ...PolicyFragment
            }
        }
    }
`

export default compose(
    graphql(getPolicies, {
        options: {
            variables: {
                draft: true,
            },
        },
        props: ({ data: { Policy }, loading }) => Policy ? ({
            policies: Policy.list,
            loadingPolicies: loading,
        }) : ({ loadingPolicies: loading }),
    }),
    graphql(createPolicy, {
        props: ({ mutate, loading }) => ({
            createPolicy: (policy) => mutate({
                variables: { policy },
                update: (proxy, { data }) => {
                    const policy = data.Policy.create
                    const { Policy } = proxy.readQuery({ query: getPolicies, variables: { draft: true } })
                    const newPolicy = {
                        ...Policy,
                        list: [
                            ...Policy.list,
                            policy,
                        ],
                    }

                    proxy.writeQuery({
                        query: getPolicies,
                        variables: { draft: true },
                        data: {
                            Policy: newPolicy
                        },
                    })
                },
            }),
            loadingCreatePolicy: loading,
        }),
    })
)(Policies)
