import React, { useState, useContext } from 'react'
import Immutable from 'immutable'

import logger from '../../../../utils/logger'

import styles from './Appearance.module.css'
import CSMPreview from '../../../../Components/CSMPreview'
import Input from '../../../../Components/Input'
import Button from '../../../../Components/Button'
import NavBar from '../../../../Components/NavBar'
import {HideChat} from '../../../../Components/ChatWithUs/ChatWithUs'

import FeatureGate from './FeatureGate'
import {Plans} from 'hooks/usePlans'
import Loading from 'Components/Loading'
import Alert from 'NewComponents/Alert';
import TrackingContext, { EventCategories } from 'contexts/TrackingContext';


export const langs = [
	{
		text: 'English',
		value: 'en',
	},
	{
		text: 'English (British)',
		value: 'en-gb',
	},
	{
		text: 'Bulgarian',
		value: 'bg',
	},
	{
		value: 'cmn',
		text: 'Chinese (Mandarin)',
	},
    {
        value: 'cs',
        text: 'Czech',
    },
	{
		value: 'cy',
		text: 'Welsh',
	},
	{
		value: 'da',
		text: 'Danish',
	},
	{
		text: 'German',
		value: 'de',
	},
	{
		text: 'Spanish',
		value: 'es',
	},
	{
		text: 'French',
		value: 'fr',
	},
	{
		text: 'Hungarian',
		value: 'hu',
	},
	{
		text: 'Italian',
		value: 'it',
	},
	{
		text: 'Japanese',
		value: 'ja',
	},
	{
		text: 'Korean',
		value: 'ko',
	},
	{
		text: 'Dutch',
		value: 'nl',
	},
	{
		text: 'Romanian',
		value: 'ro',
	},
	{
		text: 'Portugese',
		value: 'pt',
	},
	{
		text: 'Polish',
		value: 'pl',
	},
	{
		text: 'Russian',
		value: 'ru',
	},
	{
		text: 'Slovak',
		value: 'sk',
	},
	{
		text: 'Swedish',
		value: 'swe',
	},
	{
		text: 'Norwegian',
		value: 'nor'
	}
].sort((a, b) => {
	if (b.value === 'en') {
		return 1;
	}
	if (a.text < b.text) {
		return -1;
	} else {
		return 1;

	}
})

const v2ThresholdDate = '2020-01-27T00:00:00Z';

const v2Option = { title: 'Appearance', key: 'isV2', type: 'checkbox', label: 'Use latest design', className: styles.reverse };

const options = [
  {
    title: "Color",
    label: "Primary Color",
    key: "colorPrimary",
    placeholder: "#5FCF91",
    defaultValue: "#5FCF91",
    type: "color",
  },
  {
    title: "\"No\" button style",
    key: "outlineDeclineButton",
    options: [
      { text: "Solid", value: false },
      { text: "Outline", value: true },
    ],
    type: "radio",
  },
  {
    title: "Position",
    key: "position",
    type: "radio",
    options: [
      { text: "Bottom Left", value: "bottom-left" },
      { text: "Bottom Right", value: "bottom-right" },
    ],
  },
  {
    title: "Scroll to consent",
    label: "Enable",
    key: "consentOnScroll",
    type: "checkbox",
    icon: "check",
    color: "#58466D",
    className: styles.scrollToConsent,
    inlineLabel: false,
    description: (
      <Alert type="warn">
        Scroll to consent does not constitute active consent in certain regions.
				<br />Check with your local&nbsp;
				<a href="https://www.notion.so/confirmic/Europe-s-Data-Protection-Authorities-e57d67328583428da08a0bf904f0b7be" target="_blank" rel="noreferrer noopener">
					Data Protection Authority
				</a>&nbsp;to remain in compliance.
			</Alert>
    ),
  },
  {
    title: "Default Language",
    label: "Language",
    key: "language",
    type: "options",
    options: langs,
  },
  {
    title: "Custom Prompt Text",
    key: "customHtml",
    type: "textarea",
    description: "HTML is allowed here",
  },
  {
    plan: Plans.pro,
    title: "Branding",
    key: "removeBrandingTopLevel",
    type: "checkbox",
    label: "Remove top level Confirmic branding",
    className: styles.reverse,
  },
  { title: "Custom Accept Text", key: "customCopy/prompt.yes" },
];

const Appearance = ({ queryloading, preferences = {}, error, updatePreferences, updatePending, policies, createdAt, match }) => {
	const [prefs, onPrefsChange] = useState(Immutable.Map({}))
	const [complete, setComplete] = useState(false)
	const { track } = useContext(TrackingContext);

	const appearence = Immutable.fromJS(preferences || {}).mergeDeep(prefs)
  const allOptions = createdAt < v2ThresholdDate ? [{...v2Option, defaultValue: false}, ...options] : [v2Option, ...options];

	if (error) {
		logger.error(error)
	}

  if (queryloading) {
    return <Loading />
	}

	return (
    <div className={styles.mainContainer}>
    		<HideChat/>
			<NavBar topRightComponent={(
				<Button
					error={error}
					size="large"
					complete={complete}
					className={styles.saveButton}
					onClick={async () => {
						setComplete(false)
						await updatePreferences(appearence.toJS())
						setComplete(true)
						setTimeout(() => {
							setComplete(false)
						}, 1500)
					}}
					loading={updatePending}
				>
					{!updatePending && !complete && 'Save'}
					{updatePending && 'Saving'}
					{complete && 'Saved!'}
				</Button>
			)} />


        <div className={styles.inputBoxes}>
					{allOptions.map(({ plan, key, ...input }) =>
            <div className={styles.inputBox} key={input}>
              <FeatureGate needsPlan={plan}>
								<span className={styles.inputTitle}>{input.title}</span>
								<Input
									inlineLabel
									value={appearence.getIn(key.split('/'))}
									onChange={e => {
										const v = e.target ? e.target.value : e
										if (prefs.getIn(key.split('/')) !== v) {
											const newValues = prefs.setIn(key.split('/'), v)
											onPrefsChange(newValues)

											if (key === v2Option.key) {
												track(EventCategories.SWITCH_TOGGLED, match.path, { eventAction: 'V2 enabled', eventValue: Number(v) })
											}
										}

									}}
									className={styles.inputContainer} inputLabelClassName={styles.inputLabel} {...input} />
              </FeatureGate>
            </div>

					)}
				</div>

        <div className={styles.csmContainer}>
          <CSMPreview
            appearance={appearence.toJS()}
            policies={policies}
            mode="appearence"
          />
        </div>

		</div>
	)
}

export default Appearance
