import client from '../graphql/client'
import {ops,updateAt} from 'fn-update'

const getFromLocalStorage = (key) => {
    try {
        const str = window.localStorage[key]
        const obj = JSON.parse(str)
        return obj
    } catch (e) {

    }

    return null
}

const setInLocalStorage = (key, obj) => {
    try {
        window.localStorage[key] = JSON.stringify(obj)
        return obj
    } catch (e) {

    }

    return null
}

const storeMultiaccountTokens = ({ tokens, user }) => {
  
    let mtmAccounts = getFromLocalStorage('mtmAccounts')

    if (!mtmAccounts) {
        mtmAccounts = {}
    }

    mtmAccounts[user.id] = { user, tokens }

    setInLocalStorage('mtmAccounts', mtmAccounts)
}

export const getMultiAccounts = () => getFromLocalStorage('mtmAccounts') || {}

export const storeTokens = (tokens, user) => {
    window.localStorage.setItem('accessToken', tokens.accessToken)
    window.localStorage.setItem('expiresAt', tokens.expiresAt)

	if (user) {
		storeMultiaccountTokens({ tokens, user })
	}

	client.resetStore()
}

const omit = (key) => updateAt(key,ops.delete)
export const removeAccount = (accountId) => {
  setInLocalStorage('mtmAccounts', omit(accountId)(getMultiAccounts()))
}

export const useTokens = ({ tokens }) => storeTokens(tokens)