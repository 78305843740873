import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import Auth from '../../utils/Auth'

const CompletedSignupGate = ({ component: Component, error, loading, me, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			const isAuthed = Auth.isAuthenticated()

			if (isAuthed && error) {
				Auth.logout(true)
				return (
					<Redirect
						to={{
							pathname: '/account',
							state: { from: props.location }
						}}
					/>
				)
			}

			if (!isAuthed) {
				return (
					<Redirect
						to={{
							pathname: '/account',
							state: { from: props.location }
						}}
					/>
				)
			}

			if (loading || !me) {
                return null
			}

			const hasCompletedSignup = me && me.state === 'READY'

			try {
				window.Rollbar.configure({
					payload: {
						person: {
							id: me.id,
						},
					},
				})
			} catch (e) {
				//
			}
			

			if (!hasCompletedSignup) {
				return (
					<Redirect
						to={{
							pathname: '/onboarding',
							state: { from: props.location }
						}}
					/>
				)
			}

			return <Component {...props} />
        }}
	/>
)

export default CompletedSignupGate