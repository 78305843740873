import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useContext, useCallback} from 'react'
import {SelectedProjectContext} from 'contexts/ProjectContext'

const FragmentAutoblockingSetting = `
	id
	enabled
	placeholder{
		enabled
		source
		params
	}
`

export const queryGetAutoblockingConfig = gql`
  query getAutoblocking($projectId: ID!) {
		Project {
      get(id: $projectId) {
					id
				 	autoblockingEnabled
					onboardingState,
			}
		}
		Provider {
			list {
				id
				name
				reference {
					id
					name
					logo
					websiteUrl
				}
				associatedPolicies{
					id
					slug
				}
				privacyUrl
				autoblockSetting {
					${FragmentAutoblockingSetting}
				}
			}
		}
	}
`

const mutationUpdateAutoblockingEnabledSetting = gql`
	mutation updateAutoblockingEnabledSetting($projectId: ID!, $enabled: Boolean!) {
		Project {
			updateAutoblockingEnabled(id: $projectId, enabled: $enabled) {
				id
				autoblockingEnabled
			}
		}
	}
`

const mutationSaveProjectProviderSetting = gql`
	mutation updateAutoblockingSetting($input: UpdateAutoblockSettingInput!) {
		AutoblockSetting {
			update(input: $input) {
				${FragmentAutoblockingSetting}
			}
		}
	}
`

export const useAutoblockingEnabledMutation = () => {
	const {selectedProject} = useContext(SelectedProjectContext)
	const [save, { loading, error }] = useMutation(mutationUpdateAutoblockingEnabledSetting)
	const mutator = useCallback(
		(enabled) => save({ variables: { enabled, projectId: selectedProject?.id } }),
		[save, selectedProject]);
	return [
		mutator,
		{ loading, error }
	]
}


export const doesProjectSupportAutoblocking = project =>
	project?.onboardingState === 'snippet-v3' ||
	!(project?.onboardingState) // If onboardingState has not been set, dont show the warning

const useAutoblockingSettings = (skip) => {
  const {selectedProject} = useContext(SelectedProjectContext)
  const { loading: queryLoading, error: queryError, data } = useQuery(queryGetAutoblockingConfig, { skip, variables: {projectId: selectedProject.id} })
	const [setEnabled, { loading: saveEnabledLoading, error: saveEnabledError }] = useAutoblockingEnabledMutation()
	const [saveProvider, { loading: saveProviderLoading, error: saveProviderError }] = useMutation(mutationSaveProjectProviderSetting)

	const{
		Project: {
			get: project = {},
		} = {},
		Provider: {
			list: autoblockedProviders = [],
		} = {}
	} = data || {};
	const {
		id: projectId,
		autoblockingEnabled = false,
	} = project;


	const saveSetting = (input) => saveProvider({ variables: {input}})
	const setProviderEnabled = (providerId, enabled) => saveSetting({ providerId, enabled })
	const setPlaceholderEnabled = (providerId, enabled) => saveSetting({ providerId, placeholderEnabled: enabled })


	return {
			loading: queryLoading || saveProviderLoading || saveEnabledLoading,
			error: queryError || saveProviderError || saveEnabledError,

			projectId,
			autoblockedProviders,
			autoblockingEnabled,
			isSnippetValid: doesProjectSupportAutoblocking(project),

			queryLoading,
			queryError,

			saveProviderLoading,
			saveProviderError,

			setEnabled,
			setProviderEnabled,
			setPlaceholderEnabled,
	}
}

export default useAutoblockingSettings
