import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'

import styles from './Policies.module.css'

import PolicyHeader,{draggingClass} from '../../../Components/PolicyHeader'

import NewPolicy from './NewPolicy'
import NavBar from '../../../Components/NavBar'
import Button from '../../../Components/Button'
import TrackingContext, { EventCategories } from 'contexts/TrackingContext';


import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import usePolicyOrdering from 'routes/Onboarding/hooks/usePolicyOrdering'

const DraggablePolicy = SortableElement(({policy, isDragging}) => (
    <PolicyHeader key={policy.id} {...policy} isDragging={isDragging} />
))

const PoliciesDragContainer = SortableContainer(({policies, indexOfDraggedPolicy}) => (
    <div className={styles.policies}>
        {policies.map((p,i) => <DraggablePolicy key={p.id} index={i} policy={p} isDragging={indexOfDraggedPolicy === i}  /> )}
    </div>
))

const PoliciesListView = ({policies, onPoliciesReordered, matchPath}) => {
    const { track } = useContext(TrackingContext)
    const [draggingIndex,setDraggingIndex] = useState(null)
    const onSortEnd = ({oldIndex, newIndex}) => {
        setDraggingIndex(null)
        onPoliciesReordered(arrayMove(policies,oldIndex,newIndex))
    }
    const onSortStart = ({index}) => {
        track(EventCategories.ITEM_DRAGGED, matchPath, { eventAction: 'Policy reordered' })
        setDraggingIndex(index)
    }
    return (
        <div className={styles.container}>
            <PoliciesDragContainer
                policies={policies}
                indexOfDraggedPolicy={draggingIndex}

                axis="y"
                lockAxis="y"
                onSortEnd={onSortEnd}
                onSortStart={onSortStart}
                useDragHandle
                helperClass={draggingClass}
            />
        </div>
    )
}

const Policies = ({ match, createPolicy, loadingCreatePolicy, policies = [] }) => {
    const { trackClick } = useContext(TrackingContext)
    const [newPolicy, setNewPolicy] = useState(false)
    const [redirectToPolicyId, setRedirectToPolicyId] = useState(false)
    const reorderPolicies = usePolicyOrdering()
    const onPoliciesReordered = (policies) => reorderPolicies(policies.map(p => p.id))

    if (redirectToPolicyId) {
        return <Redirect to={'/dashboard/policies/' + redirectToPolicyId} />
    }

    return (
        <>
            <div className={styles.mainContainer}>
                <NavBar topRightComponent={<Button onClick={() => {
                    setNewPolicy(true)
                    trackClick('Create new policy', match.path)
                }}>Create new</Button>} />
                <PoliciesListView
                    policies={policies}
                    onPoliciesReordered={onPoliciesReordered}
                    matchPath={match.path}
                />
            </div>

            <NewPolicy
                isVisible={newPolicy}
                setIsVisible={setNewPolicy}
                loading={loadingCreatePolicy}
                onSubmit={policy => {
                    const payload = {
                        ...policy,
                        type: policy.policyType,
                        policyType: undefined,
                    }

                    return createPolicy(payload)
                        .then(({ data }) => {
                            setNewPolicy(false)
                            const policyId = data.Policy.create.id
                            setRedirectToPolicyId(policyId)
                            setTimeout(() => {
                                setRedirectToPolicyId(null)
                            }, 150)
                        })
                }}
            />
        </>
    )
}

export default Policies
