import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const getTemplatesQuery = gql`
  query getTemplates {
    Policy {
      getTemplates(i18n: true) {
        id
        slug
        name
        icon
        description
        type
        allowAnonymous
        lang
        isTranslationOf
        isInitial
        usesCookies
      }
    }
  }
`;

const useTemplates = () => {
  const { data } = useQuery(getTemplatesQuery);
  if (!data) {
    return [[]];
  }

  const {
    Policy: { getTemplates },
  } = data;

  return [getTemplates];
};

export const ESSENTIAL_TEMPLATE_ID = "tpl:essential";
export default useTemplates;
