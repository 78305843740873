import React from 'react'
import styled, { css } from 'styled-components'
import Input from './Input'

const StyledSelect = styled(Input).attrs({ as: 'select' })`
	appearance: none;
	padding: 0;
	padding-left: 12px;
	padding-right: 28px;
	font-weight: bold;
	height: 100%;
	line-height: normal;

	/* FF seemingly doesn't support using webfonts in <option /> so fallback to sans-serif */
	@-moz-document url-prefix() {
		option {
			font-family: sans-serif;
		}
	}

	${props => !props.value && css`
		color: #7A6B8B;
	`}

	${props => props.variant === 'green-on-value' && props.value && css`
		color: #08AC7A;
		background: #F2FAF0;
	`}

	${props => props.hasIcons && css`
		padding-left: 28px;
	`}
`

const SelectWrapper = styled.div`
	position: relative;
	border-radius: 2px;
	height: 31px;
	color: #58466D;
	overflow: hidden;
	border: 1px solid rgba(88, 70, 109, 0.02);

	${props => props.variant === 'green-on-value' && props.value && css`
		color: #08AC7A;
		background: #F2FAF0;
		border: 1px solid rgba(8, 172, 122, 0.1);
	`}

	:after {
		position: absolute;
		right: 12px;
		top: 10%;
		width: 15px;
		height: 80%;
		pointer-events: none;

		${props => props.variant === 'large' && css`
			background: white;
		`}

		content: '';
		display: table;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url("data:image/svg+xml,%3Csvg width='7' height='6' viewBox='0 0 7 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.23438 4.96875C3.375 5.10938 3.60938 5.10938 3.75 4.96875L6.79688 1.9375C6.9375 1.78125 6.9375 1.54688 6.79688 1.40625L6.4375 1.04688C6.29688 0.90625 6.0625 0.90625 5.90625 1.04688L3.5 3.45312L1.07812 1.04688C0.921875 0.90625 0.6875 0.90625 0.546875 1.04688L0.1875 1.40625C0.046875 1.54688 0.046875 1.78125 0.1875 1.9375L3.23438 4.96875Z' fill='%237A6B8B'/%3E%3C/svg%3E");

		${props => props.variant === 'green-on-value' && props.value && css`
			opacity: 0.8;
		`}
	}
`

const SelectIconContainer = styled.div`
	position: absolute;
	left: 0;
	height: 100%;
	width: 28px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
`

const SelectIcon = ({ icon }) => (
	<SelectIconContainer>
		{icon && icon({ color: 'currentColor', width: 15, height: 15 })}
	</SelectIconContainer>
)

const Select = ({ options, value, variant = 'default', hasIcons, placeholderIcon, placeholder = 'Choose one', className, showPlaceholder = false, ...props }) => (
	<SelectWrapper variant={variant} value={value} className={className}>
		{hasIcons &&
			<SelectIcon icon={
				value
					? options.find(option => (option === value || option.value === value))?.icon
					: placeholderIcon
				}
			/>
		}
		<StyledSelect hasIcons={hasIcons} variant={variant} value={value} {...props}>
			<option hidden={!showPlaceholder} disabled value="" selected>{placeholder}</option>
			{options.map((opt) => {
				const option = (typeof opt === 'string') ? ({
					name: opt,
					value: opt,
					icon: null,
				}) : opt
				return <option key={option.value} value={option.value}>{option.name}</option>
			})}
		</StyledSelect>
	</SelectWrapper>
)

export default Select
