import React, { useState } from 'react'
import gql from 'graphql-tag'

import styled from 'styled-components'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { queryGetProjectsList } from "hooks/useProjectsList"
import { StepFooter } from '../../../NewComponents/Stepper'
import { InputGroup } from '../../../NewComponents/Form'
import { ButtonWithIcon, TextButtonLink } from '../../../NewComponents/Form/Button'
import { Title, Text, HR } from '../../../NewComponents/Box'
import Toast from '../../../NewComponents/Toast'
import { Plans } from 'hooks/usePlans'

import Input from '../components/Input'

//import useBrand from '../hooks/useBrand'
import useCheckUrlVisibility from 'hooks/useCheckUrlVisibility'

export const completeSignupMutation = gql`
	mutation completeSignup($primaryDomain: String!) {
		Account {
			completeSignup(primaryDomain:$primaryDomain) {
				id
				state
			}
		}
	}
`


const Description = styled(Text)`
	font-size: 12px;
	margin-top: -4px;
	margin-bottom: 4px;
`

const Form = styled.form`
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: flex-start;
`

const Skip = styled(TextButtonLink)`
	padding-bottom: 0px;
`

const ScanStep = ({paymentState,setPaymentState,...props}) => {
	const [endpointVisible, setEndpointVisible] = useState(true)
	const [created, setCreated] = useState(false)
	const [domain, setDomain] = useState(null)
	//const [{ brand }, getBrand] = useBrand()
	const [completeSignup] = useMutation(completeSignupMutation, {
		refetchQueries: [{ query: queryGetProjectsList }],
		awaitRefetchQueries: true,
	})
	const [checkUrlVisibility] = useCheckUrlVisibility()

	const onSubmit = () => {
		setEndpointVisible(true)
		checkUrlVisibility(domain).then(isVisible => {
			if (!isVisible) {
				return setEndpointVisible(false)
			}
			completeSignup({ variables: { primaryDomain: domain } })
			//getBrand(domain)
			setCreated(true)
		})
	}

	if (created) {
		return <Redirect to={`/onboarding?domain=${domain}`} />
	}

	return (
		<div {...props}>
			<Form onSubmit={e => {
				e.preventDefault()
			}}>
				{paymentState === 'success' && <Toast duration={3000} type="success" message={`Success! Your project will have access to the ${Plans.pro.name} features`} onHide={() => setPaymentState(null)} />}
				{paymentState === 'cancelled' && <Toast duration={3000} type="error" message={`Your project will be created with only the ${Plans.community.name} features.`} onHide={() => setPaymentState(null)} />}
				{!endpointVisible && <Toast type="error" message={`We can't seem to access your domain. Check the spelling, or hit "skip" if it's not publicly accessible.`} />}
				<Title>Scan your first domain</Title>
				<HR />
				<Description>
					Scanning your domain lets us understand how data is flowing through your website.
				</Description>
				<InputGroup style={{ marginTop: 8 }}>
					<Input placeholder="example.com" onChange={val => setDomain(val)} />
				</InputGroup>
				<StepFooter style={{ paddingBottom: 16 }}>
					<InputGroup>
						<ButtonWithIcon icon="->" onClick={(e, { animationPromise }) => animationPromise.then(onSubmit)} disabled={!domain}>
							Next
						</ButtonWithIcon>

						{!endpointVisible && ( // we can't skip without setDomain being ran as their account will have state "incomplete"
							<Skip to="/dashboard">
								Skip
							</Skip>
						)}
					</InputGroup>
				</StepFooter>
			</Form>
		</div>
	)
}

export default ScanStep
