import React, { useEffect, useState, useContext } from "react"
import { gql } from "apollo-boost"
import { useMutation } from "react-apollo"
import NewProject from "./NewProject"
import { queryGetProjectsList } from "hooks/useProjectsList"
import { withRouter } from "react-router-dom"
import { SelectedProjectContext } from "contexts/ProjectContext"
import useCheckUrlVisibility from 'hooks/useCheckUrlVisibility'
import Toast from 'NewComponents/Toast'


const defaultInput = {
  name: "",
  domain: ""
}

const CREATE_PROJECT = gql`
  mutation createProject($input: CreateProjectInput!) {
    Project {
      createProject(input: $input) {
        id
        name
        primaryDomain
      }
    }
  }
`

const NewProjectContainer = ({ visible, close, history }) => {
  const [isDomainVisible, setDomainVisibility] = useState(true)

  const { updateSelectedProject } = useContext(SelectedProjectContext)
  const [input, setInput] = useState(defaultInput)
  const [createProject, {data}] = useMutation(CREATE_PROJECT, {
    variables: { input },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: queryGetProjectsList }]
  })
  const [checkUrlVisibility] = useCheckUrlVisibility()

  useEffect(() => {
    if(data) {
      updateSelectedProject(data.Project.createProject.id)
      history.push(`/onboarding?domain=${input.domain}`)
    }
  }, [data, history, updateSelectedProject, input])

  return (
    <>
      {!isDomainVisible && <Toast type="error" message="We can't seem to access your domain. Check the spelling and try again" />}
      <NewProject
        onSubmit={() => {
          checkUrlVisibility(input.domain).then((isVisible) => {
            if (!isVisible) return setDomainVisibility(isVisible)
            createProject()
          })
        }}
        visible={visible}
        close={close}
        input={input}
        setField={(keyVal) => setInput({ ...input, ...keyVal })}
      />
    </>
  )
}

export default withRouter(NewProjectContainer)
