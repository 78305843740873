import React from "react";

export const Check = ({width=48,height=48}) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.8281 5.875L16.1719 30.5312L7.17188 21.4375C6.70312 21.0625 5.95312 21.0625 5.57812 21.4375L2.85938 24.1562C2.48438 24.5312 2.48438 25.2812 2.85938 25.75L15.4219 38.2188C15.8906 38.6875 16.5469 38.6875 17.0156 38.2188L45.1406 10.0938C45.5156 9.71875 45.5156 8.96875 45.1406 8.5L42.4219 5.875C42.0469 5.40625 41.2969 5.40625 40.8281 5.875Z"
      fill="#5FCF91"/>
  </svg>
)

export const Clipboard = (props) => (
  <svg {...props} viewBox="0 0 448 512">
    <path fill="currentColor" d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z" />
  </svg>
)

export const Cross = ({ color='red', width= 10, height=10, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.43475 0.246139C1.10656 -0.0820463 0.574474 -0.0820463 0.246291 0.246139C-0.0818922 0.574324 -0.0818922 1.10642 0.246291 1.4346L3.81159 4.99992L0.246137 8.5654C-0.0820457 8.89358 -0.0820458 9.42568 0.246137 9.75386C0.57432 10.082 1.10641 10.082 1.43459 9.75386L5.00005 6.18839L8.56548 9.75385C8.89367 10.082 9.42576 10.082 9.75394 9.75385C10.0821 9.42566 10.0821 8.89357 9.75394 8.56538L6.1885 4.99992L9.75379 1.43462C10.082 1.10643 10.082 0.574338 9.75379 0.246153C9.4256 -0.0820322 8.89351 -0.0820322 8.56533 0.246153L5.00005 3.81146L1.43475 0.246139Z" fill={color}/>
  </svg>
)

export const UserAvatar = (props) => (
	<svg {...props} viewBox="0 0 448 512">
		<path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
	</svg>
)
