import { useState } from 'react'

const useValues = (initial) => {
	const [values, setValues] = useState(initial)
	const setValue = (key, value) => setValues({
		...(values || {}),
		[key]: value,
	})

	return [
		values,
		setValue,
		setValues,
	]
}

export default useValues