import { graphql } from 'react-apollo'
import { compose } from 'redux'
import gql from 'graphql-tag'

import SuggestedDomains from './SuggestedDomains'

const getDomain = gql`
    query getDomain {
        me {
            id
            user {
                id
                organisation {
                    id
                    defaultProject {
                        id
                        primaryDomain
                    }
                }
            }
        }
    }
`

export default compose(
    graphql(getDomain, {
        props: ({ data: { me }, loading }) => {
            return me ? ({ domain: me.user.organisation.defaultProject.primaryDomain, loading }) : ({ loading })
        },
    }),
)(SuggestedDomains)