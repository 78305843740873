import React, { useState } from 'react'
import { Flipped } from 'react-flip-toolkit'
import { withRouter } from 'react-router-dom'

import { InputGroup, Label } from '../../../NewComponents/Form'
import { ButtonWithIcon, TextButtonLink } from '../../../NewComponents/Form/Button'
import Toast from '../../../NewComponents/Toast'

import Input from '../components/Input'

import useValues from '../hooks/useValues'
import useRequestPasswordReset from '../hooks/useRequestPasswordReset'

const ForgotPassword = ({ history }) => {
	const [values, setValue] = useValues({})
	const requestPasswordReset = useRequestPasswordReset()
	const [error, setError] = useState(null)

	return (
		<form onSubmit={e => {
			e.preventDefault()
		}}>
			{error && <Toast type="error" message={error} />}
			<Flipped flipId="email">
				<InputGroup>
					<Label>
						Email Address
					</Label>
					<Input placeholder="you@company.com" type="email" onChange={val => setValue('emailAddress', val)} />
				</InputGroup>
			</Flipped>
			<Flipped flipId="ctas">
				<div>
					<InputGroup>
						<ButtonWithIcon icon="->" onClick={({ animationPromise }) => {
							setError(null)
							Promise.all([
								animationPromise,
								requestPasswordReset({ variables: { emailAddress: values.emailAddress } }),
							]).then(() => {
								history.push('/account/forgot/sent')
							}).catch(() => {
								setError('There was an issue resetting your password, please try again.')
							})
						}} disabled={!values.emailAddress}>
							Reset Password
						</ButtonWithIcon>
					</InputGroup>

					<InputGroup>
						<TextButtonLink to="/account/login">
							Remembered your password?&nbsp;
							<span style={{ color: 'rgb(251, 22, 81)' }}>Log in</span>
						</TextButtonLink>
					</InputGroup>
				</div>
			</Flipped>
		</form>
	)
}

export default withRouter(ForgotPassword)