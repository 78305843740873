import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { SketchPicker } from "react-color";

import OutsideClickHandler from "../../Components/Modal/OutsideClickHandler";

const Color = styled.div`
  width: 29px;
  height: 29px;
  border-radius: 100%;
  box-sizing: content-box;
  border: 3px solid transparent;
  position: relative;

  cursor: pointer;

  ${(props) => css`
    background: ${props.color};
  `}

  ${(props) =>
    props.selected &&
    css`
      :after {
        content: "";
        position: absolute;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.47762 10.4484C5.90285 10.8283 6.59715 10.8283 7.02238 10.4484L15.0614 3.26511C15.5129 2.86176 15.5129 2.16791 15.0614 1.76455L14.0771 0.884973C13.6518 0.505009 12.9849 0.505009 12.5597 0.884973L6.26249 6.51176L3.44035 4.01237C3.4401 4.01215 3.43986 4.01194 3.43962 4.01172C3.01435 3.63241 2.34792 3.63263 1.92293 4.01237L0.938558 4.89195C0.487147 5.29531 0.487147 5.98916 0.938558 6.39251L5.47762 10.4484Z' fill='white' stroke='black' stroke-width='0.8'/%3E%3C/svg%3E%0A");
      }
    `}
`;

const AddColor = styled(Color)`
  background: #eceaee;
  border: 1px solid rgba(88, 70, 109, 0.02);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #58466d;
  font-size: 18px;
  padding-left: 1px;
  padding-bottom: 1px;
  box-sizing: border-box;

  width: 34px;
  height: 34px;
`;

const ColorPicker = styled(SketchPicker)`
  height: 215px;
  overflow: hidden;
  div input {
    height: 18px;
  }
  > div:nth-child(2) > div:nth-child(2) {
    display: none !important;
  }
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
    display: none !important;
  }
`;

const Colors = styled.div`
  display: flex;
  height: 31px;
  position: relative;

  ${Color} {
    margin-right: 27px;
  }

  ${AddColor} {
    margin-right: 0;
  }

  ${ColorPicker} {
    position: absolute;
    right: 0;
    top: 42px;
    z-index: 9;
  }
`;

const ColorList = ({ options = [], value, onChange, maxNum, className }) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const [values, setValues] = useState(options);
  const [pickerValue, setPickerValue] = useState("#FFFFFF");
  const [pickerEditing, setPickerEditing] = useState(0);

  useEffect(() => {
    setValues([...values, ...options]);
  }, [options]);

  const lastNValues = maxNum ? values.slice(-maxNum) : values;

  return (
    <Colors className={className}>
      {lastNValues.map((color, i) => (
        <Color
          key={i}
          color={color}
          selected={color === value}
          onClick={() => {
            onChange(color);
          }}
        />
      ))}

      <AddColor
        onClick={() => {
          setPickerValue("#FFFFFF");
          setPickerEditing(values.length);
          setPickerVisible(true);
        }}
      >
        +
      </AddColor>

      {pickerVisible && (
        <OutsideClickHandler onClickOutside={() => setPickerVisible(false)}>
          <ColorPicker
            color={pickerValue}
            onChangeComplete={(color) => {
              const pickerValue = color.hex;
              setPickerValue(pickerValue);
              if (pickerValue && pickerValue !== "#FFFFFF") {
                const newSet = [...values];
                newSet[pickerEditing] = pickerValue;
                setValues(newSet);
              }
              onChange(pickerValue);
            }}
          />
        </OutsideClickHandler>
      )}
    </Colors>
  );
};

export default ColorList;
