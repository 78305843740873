import styled, { css } from 'styled-components'

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	appearance: none;
	width: 20px;
	height: 20px;
	border: 1px solid #58466D;
	box-sizing: border-box;
	border-radius: 5px;
	cursor: pointer;

	:checked {
		background: #58466D;
	}

	:focus {
		outline: none;
	}

	${props => props.checked && css`
		position: relative;

		:after {
			/* check */
			content: '';
			width: 100%;
			height: 100%;
			background-position: 2px 4px;
			display: block;
			position: absolute;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M5.38869 8.83639C5.584 9.05454 5.91603 9.05454 6.11135 8.83639L11.8536 2.42274C12.0489 2.20459 12.0489 1.83373 11.8536 1.61558L11.1504 0.830239C10.9551 0.612088 10.6426 0.612088 10.4473 0.830239L5.75978 6.06587L3.55274 3.62257C3.35743 3.40442 3.04493 3.40442 2.84961 3.62257L2.14649 4.40792C1.95117 4.62607 1.95117 4.99693 2.14649 5.21508L5.38869 8.83639Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0.333325' y='0.666626' width='13.3334' height='11.6667' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='1.66667'/%3E%3CfeGaussianBlur stdDeviation='0.833337'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
		}
	`}
`

export default Checkbox