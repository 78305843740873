import React, { useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { darken } from 'polished'

import Stepper from '../Stepper'
import Spinner from '../Spinner'

export const Button = styled.button`
	background: #0095F8; //#58466D;
	border: 1px solid rgba(88, 70, 109, 0.02);
	box-sizing: border-box;
	border-radius: 5px;
	display: inline-block;

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	color: #FFFFFF;

	width: 100%;
	padding-left: 61px;
	padding-right: 61px;
	text-align: center;
	padding-top: 13px;
	padding-bottom: 13px;
	cursor: pointer;
	text-decoration: none;

	:hover {
		background: ${darken(0.05, '#58466D')};
	}

	:focus {
		outline: none;
	}

	&[disabled] {
		pointer-events: none;
		background: #979797;
		border: 1px solid rgba(88, 70, 109, 0.02);
	}
`

export const TextButton = styled(Button)`
	background: none;
	border: none;
	color: #58466D;
	padding-left: 0;
	padding-right: 0;

	:hover {
		background: none;
		color: ${darken(0.05, '#58466D')};
	}
`

export const IconSpinnerContainer = styled(Stepper)`
	width: 16px;
	height: 14px;
	position: absolute;
	margin-left: 4px;
`

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

// and i
const noop = () => {}

export const ButtonWithIcon = ({ icon="->", onClick = noop, to, children, ...props }) => {
	const [isAnimating, setIsAnimating] = useState(false)
	const animationDuration = 450

	return (
		<Button
			onClick={(e) => {
				setIsAnimating(true)
				const animationPromise = wait(animationDuration).then(() => setIsAnimating(false))
				onClick(e, { animationPromise })
			}}
			{...props}
		>
			<span>
				{children}
				<IconSpinnerContainer>
					<span data-active={!isAnimating || undefined}>{icon}</span>
					<Spinner data-active={isAnimating || undefined} />
				</IconSpinnerContainer>
			</span>
		</Button>
	)
}

export const TextButtonLink = withRouter(({ to, onClick, history, ...props }) => (
	<TextButton onClick={() => {
		history.push(to)
		onClick && onClick()
	}} {...props} />
))