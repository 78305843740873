import React, {useContext} from 'react'
import {Switch} from 'react-router-dom'

import TrackedRoute from 'NewComponents/TrackedRoute'
import Appearance from './Appearance'
import Stats from './Stats'
import {SelectedProjectContext} from 'contexts/ProjectContext'

const Consent = ({ match }) => {
  const {selectedProject} = useContext(SelectedProjectContext)
	return (<>
    <Switch>
      <TrackedRoute path={match.url+"/"} exact component={Stats} />
      <TrackedRoute path={match.url+"/appearance"} exact render={(routeProps) => <Appearance selectedProjectId={selectedProject.id} {...routeProps} />} />
    </Switch>
	</>
)}

export default Consent
