import React, { useState, useContext } from 'react'
import styles from '../Settings.module.css'

import NavBar from '../../../../Components/NavBar'
import Box from '../../../../Components/Box'
import Button from '../../../../Components/Button'
import {SelectedProjectContext} from 'contexts/ProjectContext'
import Billing from '../Billing'
import DeleteProject from './DeleteProjectComponent'

const ProjectSettings = ({ loading, error, updatePending, updateSettings, deleteProject }) => {
  const {selectedProject} = useContext(SelectedProjectContext)
	const [settings, setSettings] = useState(null)
  const [complete, setComplete] = useState(false)
  
  const doDeleteProject = () => deleteProject(selectedProject.id)

	return (
		<div className={styles.mainContainer}>
			<NavBar topRightComponent={settings &&
				<Button
					error={error}
					size="large"
					complete={complete}
					className={styles.saveButton}
					onClick={async () => {
						setComplete(false)
            
						await updateSettings(selectedProject.id, settings)

						setComplete(true)
						setTimeout(() => {
							setComplete(false)
						}, 1500)
					}}
					loading={updatePending}
				>
				Save
				</Button>
			} />
			<div className={styles.container}>
				<Box
					inlineInputLabels
          title="Project Settings"
          description={<>Project settings for <strong>{selectedProject.name}</strong></>}
					inputs={[
            { key: 'name', label: 'Project name', placeholder: selectedProject.primaryDomain ||'My Project', description: '(visible only in the dashboard)' },
						{ key: 'primaryDomain', label: 'Domain', placeholder: 'mywebsite.com' },
					]}
					loading={loading}
          defaultValues={selectedProject}
					className={styles.box}
					onChange={s => setSettings(s)}
				/>
				<Billing loading={loading} />
        <DeleteProject deleteProject={doDeleteProject} />
			</div>
		</div>
	)
}
export default ProjectSettings
