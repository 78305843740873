import React from 'react'
import styles from './SuggestedDomains.module.css'
import Button from '../../../../../Components/Button/Button'

const Domain = ({ domain, onClick }) => (
	<Button type="white" size="x-small" className={styles.domain} onClick={onClick}>
		{domain}
	</Button>
)

class SuggestedDomains extends React.PureComponent {
	state = {
		domains: [],
	}

	componentDidMount() {
		let sh = window.localStorage.getItem('scan-history')
		if (sh) {
			try {
				sh = JSON.parse(sh)
			} catch (e) {
				//
				sh = []
			}

			this.setState({
				domains: sh,
			})
		}
	}

	componentDidUpdate(prevProps) {
		const { currentDomain, domain } = this.props
		const { domains } = this.state

		if (prevProps.currentDomain !== currentDomain) {
			if (currentDomain && currentDomain !== domain && !domains.includes(currentDomain)) {
				const newDomains = [currentDomain, ...domains]
				window.localStorage.setItem('scan-history', JSON.stringify(newDomains))
				this.setState({
					domains: newDomains,
				})
			}
		}
	}

	clearHistory() {
		this.setState({
			domains: [],
		})
		window.localStorage.removeItem('scan-history')
	}

	render() {
		const { domain, onClick, currentDomain, ...props } = this.props
		const { domains } = this.state

		return (
			<div {...props}>
				<span className={styles.text}>suggested sites</span>
				<div className={styles.domains}>
					<Domain domain={domain} onClick={() => onClick(domain)} />
					{domains.map(domain => <Domain key={domain} domain={domain} onClick={() => onClick(domain)} />)}
					<Button size="x-small" onClick={() => this.clearHistory()}>clear history</Button>
				</div>
			</div>
		)
	}
}

export default SuggestedDomains