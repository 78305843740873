import React, { useState, useRef, useEffect } from 'react'
import { Flipped } from 'react-flip-toolkit'
import { withRouter } from 'react-router-dom'

import { InputGroup, Label } from '../../../NewComponents/Form'
import { ButtonWithIcon, TextButtonLink } from '../../../NewComponents/Form/Button'
import Toast from '../../../NewComponents/Toast'

import Input from '../components/Input'

import useValues from '../hooks/useValues'
import useSignup from '../hooks/useSignup'
import useParams from '../../../hooks/useParams'
import Auth from 'utils/Auth'

const Signup = ({ onSubmit, history }) => {
	const [values, setValue] = useValues({})
	const signup = useSignup()
	const params = useParams()
	const [error, setError] = useState(null)
	const formRef = useRef()

  // Ensure we are logged out when signin in
  useEffect(() => {
    Auth.logout(true)
  }, [])

	// const tier = params.get('tier')
	// const domain = params.get('domain')

	return (
		<form onSubmit={e => {
			e.preventDefault()
			e.target.reportValidity()
		}} ref={formRef}>
			{error && <Toast type="error" message={error} />}
			<Flipped flipId="name">
				<InputGroup>
					<Label>What should we call you?</Label>
					<Input maxLength={80} placeholder="Your Name" onChange={val => setValue('name', val)} />
				</InputGroup>
			</Flipped>

			<Flipped flipId="email">
				<InputGroup>
					<Label>
						What's your email?
					</Label>
					<Input placeholder="you@company.com" type="email" onChange={val => setValue('emailAddress', val)} />
				</InputGroup>
			</Flipped>

			<Flipped flipId="password">
				<InputGroup>
					<Label>
						Set a password
					</Label>
					<Input placeholder="Minimum 8 characters" type="password" minLength={8} onChange={val => setValue('password', val)} />
				</InputGroup>
			</Flipped>

			<Flipped flipId="ctas">
				<div>
					<InputGroup>
						<ButtonWithIcon icon="->" onClick={async (e, { animationPromise }) => {
							if (!formRef.current.reportValidity()) {
								return
							}
							setError(null)
							try {
								await Promise.all([
									signup({
										...values,
										referrer: params.get('ref'),
									}).then(() => onSubmit(values)),
									animationPromise,
								])

								history.push('/account/details?'+params)
							} catch (e) {
								setError('There was an issue signing you up, please try again.')
							}
						}} disabled={!values.emailAddress || !values.password}>
							Create your Account
						</ButtonWithIcon>
					</InputGroup>

					<InputGroup>
						<TextButtonLink to="/account/login">
							Already have an account?&nbsp;
							<span style={{ color: 'rgb(251, 22, 81)' }}>Log in</span>
						</TextButtonLink>
					</InputGroup>
				</div>
			</Flipped>
		</form>
	)
}

export default withRouter(Signup)
