import { useMutation } from '@apollo/react-hooks'
import { getPolicies } from '../../Dashboard/Policies/PoliciesContainer'
import keyBy from 'lodash/keyBy'
import gql from 'graphql-tag'
import {updateAt} from 'fn-update'
//import { updateUser } from 'routes/Dashboard/Settings/SettingsContainer'

const reorderInitialPoliciesMutation = gql`
    mutation reorderInitialPolicies($policyIds: [ID!]) {
        Policy {
            reorder(ordering:$policyIds)
        }
    }
`

const orderListByIds = (ids) => list => {	
	const byId = keyBy(list,'id')
	return ids.map(id => byId[id])
}

export default () => {
	const [mutate] = useMutation(reorderInitialPoliciesMutation)
	return policyIds => {
		return mutate({
			variables:{policyIds},
			// Gotcha moment: This optimistic response is to force an immediate trigger of the update() function below
			optimisticResponse: {},
			update(cache) {
				const params = {
					query: getPolicies,
					variables: { draft: true }
				}
				// Optimistically reorder our local copy of Policy.list to match the provided id list
				const oldData = cache.readQuery(params)
				const reorderPolicies = updateAt(['Policy','list'], orderListByIds(policyIds))
				const newData = reorderPolicies(oldData)
				cache.writeQuery({
					...params,
					data: newData
				})
				
			}
		})
	}
}