import { SelectedProjectContext } from "contexts/ProjectContext";
import SimpleBannerContext from "contexts/SimpleContext";
import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Consent from "./Consent";
import styles from "./Dashboard.module.css";
import Developers from "./Developers";
import Policies from "./Policies";
import Policy from "./Policies/Policy";
import Settings from "./Settings";
import ProjectSettings from "./Settings/ProjectSettings";
import Sidebar from "./Sidebar";
import ThirdParties from "./ThirdParties";
import TrackedRoute from "NewComponents/TrackedRoute";

const Dashboard = ({ match }) => {
  const { selectedProject } = useContext(SelectedProjectContext);

  useEffect(() => {
    window.Confirmic &&
      window.Confirmic(
        "getConsentState",
        { slug: "statistics" },
        ({ enabled, state }) => {
          if (state === "UNANSWERED") {
            window.Confirmic("ConsentManager:requestConsentFor", {
              slug: "statistics",
            });
          }
        }
      );
  }, []);

  return (
    <SimpleBannerContext>
      <div className={styles.dashboardContainer}>
        <Sidebar match={match} />
        <Switch>
          <Route path={match.url + "/"} exact>
            <Redirect to={`${match.url}/consent`} />
          </Route>

          <Route path={match.url + "/consent"} component={Consent} />
          <TrackedRoute
            path={match.url + "/autoblocking"}
            component={ThirdParties}
          />
          <TrackedRoute
            path={match.url + "/settings"}
            exact
            component={Settings}
          />
          <TrackedRoute
            path={match.url + "/settings/project"}
            component={ProjectSettings}
          />
          <TrackedRoute
            path={match.url + "/policies"}
            exact
            render={(props) => (
              <Policies selectedProjectId={selectedProject.id} {...props} />
            )}
          />
          <Route
            path={match.url + "/policies/:id"}
            render={(props) => (
              <Policy {...props} selectedProjectId={selectedProject.id} />
            )}
          />
          <Route
            path={match.url + "/developers"}
            render={(props) => (
              <Developers selectedProjectId={selectedProject.id} {...props} />
            )}
          />
        </Switch>
      </div>
    </SimpleBannerContext>
  );
};

export default Dashboard;
