import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { compose } from 'redux'
import LoggedInGate from './LoggedInGate'

const getActorState = gql`
    query getActorState {
        me {
            id
            state
        }
    }
`

export default compose(
    graphql(getActorState, {
        props: ({ data: { me }, loading }) => ({ me, loading }),
    }),
)(LoggedInGate)
