import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import TrackingContext, { EventCategories } from "contexts/TrackingContext";

import NavBar from "Components/NavBar";
import Input from "Components/Input";
import Badge from "NewComponents/Badge";
import Alert from "NewComponents/Alert";

import styles from "../Consent/Appearance/Appearance.module.css";
import ProviderList, { List as ProviderListContainer } from "./ProviderList";
import useAutoblockingSettings from "./useAutoblockingSettings";

const EmptyStateContainer = styled.div`
  flex: 1;
  display: flex;
  font-family: "Inter";
  align-items: center;
  justify-content: center;
  padding-top: 64px;

  text-align: center;

  h3 {
    margin-bottom: 16px;
  }
`;

const EmptyState = () => (
  <EmptyStateContainer>
    <div>
      <h3>Your Cookie Widget doesn’t have any third parties in yet</h3>
      <h5>
        Head over to <Link to="/dashboard/policies">Micropolicies</Link> to add
        some
      </h5>
    </div>
  </EmptyStateContainer>
);

const LoadingText = styled.div`
  font-family: Inter;
  color: #5fcf91;
  font-weight: 600;
`;

const ListWrapper = styled.div`
  padding: 0 0 90px 0;
  opacity: 1;
  transition: opacity 0.3s ease-in;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      ${ProviderListContainer} {
        pointer-events: none;
      }
    `}
`;

const Intro = styled.div`
  padding: 42px 0;
  box-sizing: border-box;
  max-width: 860px;
  width: 80%;
  line-height: 150%;
  margin: auto;
  font-family: Inter;

  label {
    display: flex;
    align-items: center;
  }

  h4 {
    font-family: Eina;
    display: flex;
    align-items: center;
    font-size: 21px;
    margin-bottom: 12px;
    flex: 1;

    svg {
      margin-left: 8px;
    }
  }
`;

const ThirdParties = ({ match }) => {
  const {
    loading,
    projectId,
    autoblockingEnabled,
    autoblockedProviders,
    isSnippetValid,
    setEnabled,
    setProviderEnabled,
    setPlaceholderEnabled,
  } = useAutoblockingSettings();

  const { track } = useContext(TrackingContext);

  return (
    <div className={styles.mainContainer}>
      <NavBar
        topRightComponent={
          <LoadingText>{loading ? "Updating…" : "Up to date"}</LoadingText>
        }
      />
      <Intro>
        <label className={styles.inputLabel}>
          <h4>
            Automatic Script Blocking &nbsp;<Badge type="positive">BETA</Badge>
          </h4>
          <Input
            onChange={(evt) => {
              const { value } = evt.target;
              setEnabled(value);
              track(EventCategories.SWITCH_TOGGLED, match.path, {
                eventAction: "Autoblocking enabled",
                eventValue: Number(value),
              });
            }}
            value={autoblockingEnabled}
            type="switch"
            disabled={!projectId}
          />
        </label>
        <p>
          Confirmic can automate part of your website compliance by disabling
          third party scripts as they load, and enable them when your users
          consent. We're constantly adding support for more third parties, but
          for those that are currently not supported, you may{" "}
          <a
            href="https://docs.confirmic.com/docs/manual-blocking"
            target="_blank"
            rel="noopener noreferrer"
          >
            manually block
          </a>{" "}
          them.
        </p>
        {!loading && !isSnippetValid && (
          <Alert type="error" style={{ marginTop: 24 }}>
            <p>
              The Cookie Widget Snippet currently running on your website does
              not support Autoblocking. You may still manage this list of
              autoblocked third parties, but these will not take effect until
              you{" "}
              <Link to="/dashboard/developers">
                update to the latest snippet
              </Link>
              .
            </p>
          </Alert>
        )}
      </Intro>
      <ListWrapper
        className={styles.inputBoxes}
        disabled={!autoblockingEnabled}
      >
        {autoblockedProviders.length ? (
          <ProviderList
            matchPath={match.path}
            providers={autoblockedProviders}
            setEnabled={setProviderEnabled}
            setPlaceholderEnabled={setPlaceholderEnabled}
          />
        ) : (
          <EmptyState />
        )}
      </ListWrapper>
    </div>
  );
};

export default ThirdParties;
