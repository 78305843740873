import { graphql } from "react-apollo";
import { compose } from "redux";
import gql from "graphql-tag";
import Immutable from "immutable";

import { getPolicies } from "../../Policies/PoliciesContainer";
import { getUserInfo } from "routes/Dashboard/Settings/SettingsContainer";

import Stats from "./Stats";

export const getStats = gql`
  query getStats {
    Stats {
      get {
        summary {
          totalConsentedAllTime
          totalConsented
          totalDeclined
        }
        entries {
          policyId
          policyVersion
          totalConsented
          totalDeclined
        }
      }
    }
  }
`;

export default compose(
  graphql(getStats, {
    props: ({ data: { Stats }, loading }) => ({
      stats: Stats ? Immutable.fromJS(Stats.get) : Immutable.Map({}),
      loading,
    }),
  }),
  graphql(getPolicies, {
    options: {
      variables: {
        draft: true,
      },
    },
    props: ({ data: { Policy }, loading }) =>
      Policy
        ? {
            policies: Policy.list,
            loadingPolicies: loading,
          }
        : { loadingPolicies: loading },
  }),
  graphql(getUserInfo, {
    props: ({
      data: {
        me,
      },
    }) => ({ email: me?.user?.email }),
  })
)(Stats);
