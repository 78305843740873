import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { compose } from 'redux'
import Appearance from './Appearance'
import { getPolicies } from '../../Policies/PoliciesContainer'

const projectFragment = gql`
    fragment ProjectFragment on Project {
        id
        name
        primaryDomain
        alternativeDomains
        onboardingState
        preferences
        createdAt
    }
`

export const getProject = gql`
    ${projectFragment}
    query getProject($id: ID!) {
        Project {
          get(id: $id) {
                ...ProjectFragment
            }
        }
    }
`

export const updatePreferences = gql`
    mutation updatePreferences(
        $preferences: Preferences!,
        $id: ID!
    ) {
        Project {
            updatePreferences(id: $id, preferences: $preferences)
        }
    }
`

export default compose(
    graphql(getProject, {
      options: (props) => ({
        variables: {
          id: props.selectedProjectId,
        }
      }),
        props: ({ data: { Project , loading, me }, error }) => {
            if (Project) {
                const { get: { preferences, primaryDomain, createdAt } } = Project
                return { preferences, domain: primaryDomain, queryLoading: loading, error, createdAt }
            }

          return { queryloading: loading, error }
        },
    }),
    graphql(updatePreferences, {
      props: ({ mutate, loading, error, ownProps: {selectedProjectId}}) => ({
            updatePreferences: (preferences) => mutate({
              variables: { preferences, id: selectedProjectId },
                update: (proxy, { data }) => {
                    if (data.Project && data.Project.updatePreferences) {
                        const preferences = data.Project.updatePreferences
                      const { Project } = proxy.readQuery({ query: getProject, variables: {id: selectedProjectId } })
                        const { get } = Project

                        proxy.writeQuery({
                            query: getProject,
                            data: {
                                Project: {
                                    ...Project,
                                    get: {
                                        ...get,
                                        preferences,
                                    }
                                }
                            },
                        })
                    }
                },
            }),
            updatePending: loading,
            error,
        }),
    }),
    graphql(getPolicies, {
        options: {
            variables: {
                draft: true,
            },
        },
        props: ({ data: { Policy }, loading }) => Policy ? ({
            policies: Policy.list,
            loadingPolicies: loading,
        }) : ({ loadingPolicies: loading }),
    }),
)(Appearance)
