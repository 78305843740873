import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const resetPasswordMutation = gql`
	mutation ResetPassword($passwordResetToken: String!, $newPassword: String!, $confirmNewPassword: String!) {
		Account {
			resetPassword(passwordResetToken: $passwordResetToken, newPassword: $newPassword, confirmNewPassword: $confirmNewPassword)
		}
	}
`

const useResetPassword = () => {
	const [resetPassword] = useMutation(resetPasswordMutation)
	return variables => resetPassword({ variables })
}

export default useResetPassword
