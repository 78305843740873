import React, { useContext } from "react";
import styled, { css } from "styled-components";

import TrackingContext, { EventCategories } from "contexts/TrackingContext";
import { SwitchInput } from "Components/Input";

import Select from "NewComponents/Form/Select";
import { Check, Cross } from "Components/Icons";
import getAt from "lodash/get";
import { Link } from "react-router-dom";

export const List = styled.div`
  width: 80%;
  margin: auto;
  box-sizing: border-box;

  max-width: 860px;
  padding-bottom: 96px;
`;

const Pill = styled.span`
  display: inline-block;
  ${(props) =>
    ({
      primary: css`
        background-color: #ebe3ff;
        color: #6e4ba5;
      `,
      default: css`
        background-color: #cecece;
        color: #353535;
      `,
    }[props.intent || "default"])}
  padding: 3px 8px;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 500;
`;

const Provider = styled.div`
  background: #ffffff;
  /* Stroke/Entry */
  border: 1px solid rgba(88, 70, 109, 0.2);
  box-sizing: border-box;
  border-radius: 4px;

  padding: 19px 13px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;

  label {
    user-select: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #58466d;

    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;

const ProviderLogo = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;

  background-size: contain;

  ${(props) => css`
    background-image: url("${props.url}");
  `}
`;

// const ProviderIdentity = styled.div`
//   display: flex;
// `;

const Header = styled.div`
  display: flex;
  margin-right: auto;
  flex-direction: column;
  margin-left: 8px;
  ${Pill} {
    margin-top: 6px;
    margin-left: -4px;
  }
`;

const ProviderLabel = ({ href, children }) => (
  <label>
    {href ? (
      <a href={href} target="_blank" rel="noopener nofollow noreferrer">
        {children} ↗
      </a>
    ) : (
      children
    )}
  </label>
);

const CantBlockText = styled.span`
  font-family: "Inter";
  font-size: 12px;
  opacity: 0.6;
`;

const PlaceholderSetting = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  label {
    font-size: 12px;
    margin-right: 8px;
  }
  opacity: ${(props) => (props.isEnabled ? 1 : 0.6)};

  svg {
    transform: scale(0.6);
  }
`;

export function ProviderRow({
  id,
  name,
  logo,
  url,
  isEnabled,
  matchPath,
  isBlockable,
  policyName,
  policyId,
  placeholderSetting,
  setEnabled,
  setPlaceholderEnabled,
}) {
  const { track } = useContext(TrackingContext);
  return (
    <Provider key={id}>
      <ProviderLogo url={logo} />
      <Header>
        <ProviderLabel href={url}>{name}</ProviderLabel>
        <Link to={`/dashboard/policies/${policyId}/third-parties`}>
          <Pill intent="primary">{policyName}</Pill>
        </Link>
      </Header>

      {placeholderSetting && (
        <PlaceholderSetting isEnabled={isEnabled}>
          <Select
            disabled={!isEnabled}
            hasIcons
            variant={placeholderSetting.enabled && "green-on-value"}
            options={[
              { value: "true", name: "Placeholder enabled", icon: Check },
              { value: "false", name: "Placeholder disabled", icon: Cross },
            ]}
            value={String(placeholderSetting.enabled)}
            onChange={(e) => setPlaceholderEnabled(e.target.value === "true")}
          />
        </PlaceholderSetting>
      )}

      {isBlockable ? (
        <SwitchInput
          value={isEnabled}
          onChange={(e) => {
            setEnabled(e.target.value);
            track(EventCategories.SWITCH_TOGGLED, matchPath, {
              eventAction: "Third party autoblocked",
              eventName: name,
              eventValue: Number(e.target.value),
            });
          }}
        />
      ) : (
        <CantBlockText>This third party is not yet supported</CantBlockText>
      )}
    </Provider>
  );
}

export default function ProviderList({
  providers,
  matchPath,
  setEnabled,
  setPlaceholderEnabled,
}) {
  return (
    <List>
      {/** this could do with a refactor - way too reach-inny here */}
      {providers.map(
        ({ id, name, reference, associatedPolicies, autoblockSetting }) => (
          <ProviderRow
            key={id}
            id={id}
            matchPath={matchPath}
            name={name}
            logo={reference?.logo}
            url={reference?.websiteUrl}
            policyName={getAt(associatedPolicies, [0, "slug"])}
            policyId={getAt(associatedPolicies, [0, "id"])}
            isBlockable={Boolean(autoblockSetting)}
            placeholderSetting={autoblockSetting?.placeholder}
            isEnabled={Boolean(autoblockSetting?.enabled)}
            setEnabled={(v) => setEnabled(reference.id, v)}
            setPlaceholderEnabled={(v) =>
              setPlaceholderEnabled(reference.id, v)
            }
          />
        )
      )}
    </List>
  );
}
