import React, { useState, useContext, useEffect } from "react";
import Modal from "Components/Modal";
import Box from "Components/Box";
import Spinner from "NewComponents/Spinner";
import styled from "styled-components";
import styles from "Components/Box/Box.module.css";
import Button from "Components/Button";
import Flex, { FlexItem } from "styled-flex-component";

import gql from "graphql-tag";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { SelectedProjectContext } from "contexts/ProjectContext";
import useProjectsList from "hooks/useProjectsList";
import {
  Plans,
  MAX_PAID_PRO,
  PRO_MONTHLY_COST_GBP,
  FREE_PRO_COUPON,
} from "hooks/usePlans";
import useBilling from "hooks/useBilling";

const cancelSubscriptionMutation = gql`
  mutation cancelSubscription($removePaymentMethods: Boolean) {
    Account {
      cancelSubscription(removePaymentMethods: $removePaymentMethods)
    }
  }
`;

const getRedirectUrl = gql`
  query getBillingPortalUrl($returnUrl: String!) {
    Organisation {
      getBillingPortalUrl(returnUrl: $returnUrl)
    }
  }
`;

const Footer = styled(Flex).attrs({
  justifyEnd: true,
  alignCenter: true,
  className: styles.buttonsContainer,
})`
  button {
    margin-left: 8px;
  }
`;

const Dialog = styled(Box)`
  && {
    width: 500px;
    max-width: calc(100vw - 16px);
    min-width: 400px;
  }

  ${Footer} {
    margin-top: 20px;
  }
`;

const Text = styled.p.attrs({
  className: styles.description,
})`
  && {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default function CancelSubscription({ onCancelled }) {
  const { projects } = useProjectsList();
  const [
    getPortalUrl,
    { loading: redirectLoading, data: redirectData },
  ] = useLazyQuery(getRedirectUrl);
  const numProProjects = projects.filter(
    (project) => project.plan !== Plans.community.key
  ).length;
  const [removePaymentMethods, setRemovePaymentMethods] = useState(false);
  const [cancelSubscription] = useMutation(cancelSubscriptionMutation);
  const { selectedProject, refetchCurrentProject } = useContext(
    SelectedProjectContext
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const {
    refetch: refetchBilling,
    data: { paymentDetails: { coupon, cardBrand } = {} } = {},
    loading: loadingBilling,
  } = useBilling(true);
  useEffect(() => {
    if (!redirectData) return;
    window.location.href = redirectData.Organisation.getBillingPortalUrl;
  }, [redirectData]);
  const hasFreePro = coupon === FREE_PRO_COUPON;
  const hasProProjects = numProProjects > 0;
  const hasCreditCard = Boolean(cardBrand);

  const confirmButton = (
    <Button
      type="secondary"
      disabled={isCancelling}
      onClick={() => {
        setIsCancelling(true);
        cancelSubscription({
          variables: {
            removePaymentMethods: hasProProjects ? removePaymentMethods : true,
          },
        })
          .then(() => refetchCurrentProject(selectedProject.id))
          .then(() => {
            setIsModalVisible(false);
            refetchBilling();
            onCancelled("Subscription cancelled");
          })
          .finally(() => setIsCancelling(false));
      }}
    >
      {isCancelling && <Spinner />}
      Yes
    </Button>
  );

  return (
    <>
      <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible}>
        <Dialog
          title={
            hasProProjects ? "Cancel subscription?" : "Remove payment details?"
          }
          inputs={[]}
        >
          <Text>Are you sure you want to cancel your subscription?</Text>
          <Text>
            This will downgrade{" "}
            <strong>
              {numProProjects > 1
                ? `${numProProjects} projects`
                : selectedProject.name}
            </strong>{" "}
            to the Community plan.
          </Text>
          <Footer>
            {hasCreditCard && (
              <label className={styles.description}>
                <input
                  type="checkbox"
                  checked={removePaymentMethods}
                  onChange={(e) => setRemovePaymentMethods(e.target.checked)}
                />
                Also remove my credit card details
              </label>
            )}
            {confirmButton}
          </Footer>
        </Dialog>
      </Modal>
      {loadingBilling ? (
        <Spinner />
      ) : (
        <Footer>
          <FlexItem grow={1}>
            {hasProProjects ? (
              <Text>
                {numProProjects === 1
                  ? "1 project is"
                  : `${numProProjects} projects are`}{" "}
                on a paid plan
              </Text>
            ) : (
              <Text>
                All your projects are on the{" "}
                <strong>{Plans.community.name}</strong> plan
              </Text>
            )}
          </FlexItem>
          {hasProProjects && (
            <Text>
              {hasFreePro ? (
                <>
                  You have unlimited free <strong>{Plans.pro.name}</strong>{" "}
                  projects
                </>
              ) : (
                hasCreditCard &&
                ``
              )}
            </Text>
          )}
          {/* `You are currently paying £${
                  Math.min(numProProjects, MAX_PAID_PRO) * PRO_MONTHLY_COST_GBP
                } / month` */}
          {/**
           * Ideally we return something from the server indicating if this
           * organisation has a stripe customer id, but for now this is a good
           * enough proxy.
           */}
          {(hasProProjects || hasCreditCard) && (
            <Button
              type="dark"
              loading={redirectLoading}
              onClick={() =>
                getPortalUrl({ variables: { returnUrl: window.location.href } })
              }
            >
              Manage Billing
            </Button>
          )}
          {hasProProjects && !hasFreePro && (
            <Button type="secondary" onClick={() => setIsModalVisible(true)}>
              Cancel Subscription
            </Button>
          )}
        </Footer>
      )}
    </>
  );
}
