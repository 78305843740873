import { useState } from 'react'
import { getMultiAccounts, removeAccount, useTokens as switchToTokens } from '../../../utils/multiAccounts'

const useMultiAccounts = () => {
	const [accounts, setAccounts] = useState(getMultiAccounts())

	const remove = (id) => {
		removeAccount(id)
		setAccounts(getMultiAccounts())
	}

	const chooseAccount = account => {
		switchToTokens(account)
	}

	return [Object.entries(accounts), chooseAccount, remove]
}

export default useMultiAccounts