// Previously CurrentDomain.js if you are looking for that.
//
// Moved and renamed to ProjectSwitcher
import NewProject from "Components/NewProject"
import {SelectedProjectContext} from "contexts/ProjectContext"
import React, {useContext, useState} from "react"
import ProjectSwitcher from "./ProjectSwitcher"
import { withRouter } from "react-router-dom"

const NEW_PRJ_ID = "create-new"

const ProjectSwitcherContainer = ({history}) => {
  const { selectedProject, projects = [], updateSelectedProject } = useContext(SelectedProjectContext)
  const [showNewProjectModal, setShowNewProjectModal] = useState(false)
  const projectsWithNew = [...projects, { id: NEW_PRJ_ID, name: "+ Create new project" }]

  const switchAndNavigate = (projectId) => {
    updateSelectedProject(projectId)
    history.push("/")
  }

  const switchProject = (projectId) => projectId === NEW_PRJ_ID ? setShowNewProjectModal(true) : switchAndNavigate(projectId)

  return (
    <>
      <ProjectSwitcher projects={projectsWithNew} selected={selectedProject?.id} setSelected={switchProject} />
      <NewProject visible={showNewProjectModal} close={() => setShowNewProjectModal(false)} />
    </>
  )
}

export default withRouter(ProjectSwitcherContainer)
