import React, {useState} from "react"
// import styles from "./Policy.module.css"
import boxStyles from "../../../../Components/Box/Box.module.css"
import logger from '../../../../utils/logger'
import Button from '../../../../Components/Button'
import {withRouter} from "react-router-dom"

const DeleteProject = ({id, deleteProject, history}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [complete, setComplete] = useState(false)

  return (<div className={boxStyles.box} style={{ padding: "20px" }}>
    <h4 className={boxStyles.boxTitle}>
      <span>Delete Project</span>
    </h4>
    <p className={boxStyles.description}>
      This project will be removed from your account, however we will still keep the data around for auditing purposes.
    </p>
    <div style={{ marginTop: "20px" }}>
      <Button
        onClick={() => {
          setLoading(true)
          deleteProject(id).then(() => {
            setComplete(true)
            setLoading(false)
            history.push('/dashboard')
          }).catch(e => {
            setLoading(false)
            setError(e)
            logger.error(e)
          })
        }}
        type="secondary"
        verb="deleting"
        verbPastTense="deleted"
        size="medium"
        loading={loading}
        error={error}
        complete={complete}
      >
        Delete
      </Button>
    </div>
  </div>)
}

export default withRouter(DeleteProject)