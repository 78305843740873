import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const policyFragment = gql`
    fragment PolicyFragment on Policy {
        id
        version
        slug
        name
        description
        icon
        isPaused
        isMandatory
        usesCookies
        allowAnonymous
        isInitial
        type
        usages {
            id
            datapoint {
                id
                name
            }
            purpose
        }
        providers {
            id
            name
            privacyUrl
            referenceId
        }
        history {
            op
            path
            value
            oldValue
            date
            entityType
        }
    }
`

export const publishPolicy = gql`
    ${policyFragment}
    mutation publishPolicy($id:ID!) {    
        Policy {
            publish(id:$id) {
                ...PolicyFragment
            }
        }
    }
`

export const createPolicy = gql`
    ${policyFragment}
    mutation createPolicy($policy:CreatePolicyInput!) {    
        Policy {
            create(policy:$policy) {
                ...PolicyFragment
            }
        }
    }
`

const normalizeSlug = isTranslationOf => {
    let slug

    try {
        if (isTranslationOf) {
            slug = isTranslationOf.split(':')[1].split(' ').join('-')
        }
    } catch (e) {
        // swallow
    }

    return slug
}

const useCreatePolicies = (autopublish) => {
	const [mutate, { data }] = useMutation(createPolicy)
	const [publishMutate] = useMutation(publishPolicy)

	const createPolicies = (policies) => {
		const cleaned = policies.map(({ id, __typename, providers, ...policy }) => {
			return {
                ...policy,
                slug: normalizeSlug(policy.isTranslationOf),
                isTranslationOf: undefined,
                isInitial: true,
				providers: providers.map(p => ({
					name: p.name,
					privacyUrl: p.privacyUrl,
					referenceId: p.id
				}))
			}
		})

		return Promise.all(cleaned.map(policy => mutate({ variables: { policy } }).then(d => {
            if (autopublish && d && d.data) {
                return publishMutate({ variables: { id: d.data.Policy.create.id } })
            } else {
                return d
            }
        })))
	}

	return [createPolicies, data]
}

export default useCreatePolicies
