import React, { useState } from 'react'
import styled from 'styled-components'
import { withRouter, Redirect } from 'react-router-dom'

import useMultiAccounts from '../hooks/useMultiAccounts'

import { Cross } from '../../../Components/Icons'

import { Title, HR } from '../../../NewComponents/Box'
import { InputGroup } from '../../../NewComponents/Form'
import { TextButtonLink, IconSpinnerContainer } from '../../../NewComponents/Form/Button'
import Spinner from '../../../NewComponents/Spinner'

const AccountList = styled.div`
	flex: 1;
	overflow: scroll;
`

const StyledAccountSwitcher = styled.div`
	display: flex;
	flex-direction: column;
`

const AccountRow = styled.div`
	background: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	border-radius: 6px;

	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	position: relative;

	/* Accent/Entry */
	color: #58466D;
	height: 50px;
	box-sizing: content-box;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 12px;
	padding-right: 12px;

	padding-top: 4px;
	padding-bottom: 4px;

	cursor: pointer;

	margin-top: 19px;
	margin-bottom: 19px;
`

const RowBody = styled.div`
	flex: 1;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
`

const Account = withRouter(({ id, tokens, emailAddress, domain, history, setAccount, removeAccount }) => {
	const [isLoading, setIsLoading] = useState(false)

	return (
		<AccountRow onClick={() => {
			setIsLoading(true)
			setAccount({ tokens })
			setTimeout(() => {
				history.push('/dashboard')
			}, 1500)
		}}>
			
			<RowBody>
				<div>{emailAddress}</div>
				{domain && <div style={{ marginTop: 2 }}>{domain}</div>}
			</RowBody>
			<IconSpinnerContainer style={{ right: 6 }} onClick={(e) => {
					e.stopPropagation()
					if (!isLoading) {
						removeAccount(id)
					}
				}}>
				<Cross data-active={!isLoading || undefined} style={{ width: 10, height: 10 }} />
				<Spinner data-active={isLoading || undefined} />
			</IconSpinnerContainer>
		</AccountRow>
	)
})

const AccountSwitcher = (props) => {
	const [accounts, setAccount, removeAccount] = useMultiAccounts()

	if (props['data-active'] && accounts.length === 0) {
		return <Redirect to="/account/login" />
	}

	return (
		<StyledAccountSwitcher {...props}>
			<Title>Choose Account</Title>
			<HR />
			<AccountList>
				{accounts.map(([id, account]) => (
					<Account
						setAccount={setAccount}
						removeAccount={removeAccount}
						key={id}
						id={id}
						emailAddress={account.user.email}
						domain={account.user.organisation.defaultProject.primaryDomain}
						tokens={account.tokens}
					/>
				))}
			</AccountList>
			<InputGroup>
				<TextButtonLink to="/account/login">
					Want to use another account?&nbsp;
					<span style={{ color: 'rgb(251, 22, 81)' }}>Log in</span>
				</TextButtonLink>
			</InputGroup>
		</StyledAccountSwitcher>
	)
}

export default AccountSwitcher