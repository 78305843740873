
const isAuthenticated = () => !!localStorage.accessToken
const getAccessToken = () => localStorage.accessToken

const storeTokens = ({ accessToken, expiresAt }) => {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('expiresAt', expiresAt)
}

const logout = (dontRedirect) => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('expiresAt')
    if (!dontRedirect) {
        window.location.href = '/'
    }
}

export default {
    isAuthenticated,
    storeTokens,
    getAccessToken,
    logout,
}