import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import Modal from 'react-modal'
import { ConnectedRouter } from 'connected-react-router'

import client from './graphql/client'
import configureStore from './redux/store'
import history from './redux/history'
import App from './App'
import { TrackingProvider } from 'contexts/TrackingContext'

import './index.css'
import 'inter-ui/inter.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'animate.css'

const store = configureStore({})

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

ReactDOM.render(
	<ApolloProvider client={client}>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<TrackingProvider>
					<App />
				</TrackingProvider>
			</ConnectedRouter>
		</Provider>
	</ApolloProvider>
	,document.getElementById('root')
)
