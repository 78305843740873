import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

const Wrapper = styled(Flex).attrs({
  column: true,
})`
  /* so that the offset top is relative to this element */
  position: relative;
`;

const StepLabel = styled.span`
  font-size: 14px;
  line-height: 1.5;
  color: white;
`;

const Path = styled.div`
  ${({ margins }) => css`
    margin-left: ${margins[0]}px;
    width: calc(100% - ${margins[0]}px - ${margins[1]}px);
  `};
  margin-bottom: 8px;
  height: 2px;
  background-color: rgba(255,255,255,0.4);
  position: relative;
  border-radius: 3px;

  /* Current step indicator */
  &::before {
    content: '';
    position: absolute;
    top: -3px;
    transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateX(${({ indicatorX }) => indicatorX - 4}px);
    border-radius: 100%;
    display: block;
    width: 8px;
    height: 8px;
    background-color: white
  }

  /* Completed steps segment */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: white;
    border-radius: 3px;
    transition: width 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: ${({ indicatorX }) => indicatorX}px;
  }
`;

export default function ProgressIndicator({ className, steps, currentStep }) {
  const firstRef = useRef();
  const lastRef = useRef();
  const currentRef = useRef();
  const [margins, setMargins] = useState([0, 0]);
  const [indicatorX, setIndicatorX] = useState(0);
  useEffect(() => {
    const leftMargin = firstRef.current.getBoundingClientRect().width / 2;
    const rightMargin = lastRef.current.getBoundingClientRect().width / 2;
    setMargins([leftMargin, rightMargin]);
  }, [steps]);
  useEffect(() => {
    const left = currentRef.current.offsetLeft;
    const width = currentRef.current.getBoundingClientRect().width;
    setIndicatorX(left + width / 2 - margins[0]);
  }, [margins, currentStep, steps]);

  return (
    <Wrapper className={className}>
      <Path margins={margins} indicatorX={indicatorX} />
      <Flex justifyBetween>
        {steps.map((step, index) => {
          const ref =
            (index === 0 && firstRef) ||
            (index === steps.length - 1 && lastRef) ||
            undefined;
          const isDone = currentStep > index;
          const isCurrent = currentStep === index + 1;
          return (
            <FlexItem grow={0} key={step} ref={ref}>
              <StepLabel
                isDone={isDone}
                ref={isCurrent ? currentRef : undefined}
              >
                {step}
              </StepLabel>
            </FlexItem>
          );
        })}
      </Flex>
    </Wrapper>
  );
}
