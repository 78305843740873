import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { compose } from "redux";
import Policy from "./Policy";
import { policyFragment, getPolicies } from "../PoliciesContainer";

import { getProject } from "../../Consent/Appearance/AppearanceContainer";
import { queryGetAutoblockingConfig } from "../../ThirdParties/useAutoblockingSettings";

const getDatapoints = gql`
  query getDatapoints {
    Datapoint {
      list {
        id
        name
      }
    }
  }
`;
const getProviders = gql`
  query getProviders {
    Provider {
      Reference {
        list {
          id
          name
          category
          logo
          privacyUrl
        }
      }
    }
  }
`;

const createDatapoint = gql`
  mutation createDatapoint($name: String!) {
    Datapoint {
      create(name: $name) {
        id
        name
      }
    }
  }
`;

const getPolicy = gql`
  ${policyFragment}
  query getPolicy($id: ID!, $draft: Boolean!) {
    Policy {
      get(id: $id, draft: $draft) {
        ...PolicyFragment
      }
    }
  }
`;

const updatePolicy = gql`
  ${policyFragment}
  mutation updatePolicy($policy: UpdatePolicyInput!) {
    Policy {
      update(update: $policy) {
        ...PolicyFragment
      }
    }
  }
`;

export const publishPolicy = gql`
  ${policyFragment}
  mutation publishPolicy($id: ID!) {
    Policy {
      publish(id: $id) {
        ...PolicyFragment
      }
    }
  }
`;

export const archivePolicy = gql`
  mutation archivePolicy($id: ID!) {
    Policy {
      archive(id: $id)
    }
  }
`;

export default compose(
  graphql(getPolicy, {
    options: (props) => ({
      variables: {
        id: props.match.params.id,
        draft: true,
      },
    }),
    skip: (ownProps) => !ownProps.match.params.id,
    props: ({ data: { Policy }, loading }) =>
      Policy
        ? {
            policy: Policy.get,
            loadingPolicy: loading,
          }
        : { loadingPolicy: loading },
  }),
  graphql(getDatapoints, {
    props: ({ data: { Datapoint }, loading }) =>
      Datapoint
        ? {
            /** Hotfix bug: datapoints can be empty */
            datapoints: Datapoint.list?.filter(Boolean),
            loading,
          }
        : { loading },
  }),
  graphql(getProviders, {
    props: ({ data: { Provider }, loading }) =>
      Provider
        ? {
            providers: Provider.Reference.list,
            loading,
          }
        : { loading },
  }),
  graphql(createDatapoint, {
    props: ({ mutate, loading }) => ({
      createDatapoint: (variables) =>
        mutate({
          variables,
          update: (proxy, { data }) => {
            const datapoint = data.Datapoint.create;
            const { Datapoint } = proxy.readQuery({ query: getDatapoints });
            proxy.writeQuery({
              query: getDatapoints,
              data: {
                Datapoint: {
                  ...Datapoint,
                  list: [...Datapoint.list, datapoint],
                },
              },
            });
          },
        }),
      loading,
    }),
  }),
  graphql(updatePolicy, {
    props: ({ mutate, loading }) => ({
      updatePolicy: (policy) =>
        mutate({
          variables: { policy },
        }),
      loading,
    }),
  }),
  graphql(publishPolicy, {
    props: ({ mutate, loading }) => ({
      publishPolicy: (id, projectId) =>
        mutate({
          variables: { id },
          refetchQueries: [
            { query: queryGetAutoblockingConfig, variables: { projectId } },
          ],
        }),
      loading,
    }),
  }),
  graphql(archivePolicy, {
    props: ({ mutate, loading }) => ({
      archivePolicy: (id) =>
        mutate({
          variables: { id },
          refetchQueries: [{ query: getPolicies, variables: { draft: true } }],
          awaitRefetchQueries: true,
        }),
      loading,
    }),
  }),
  graphql(getProject, {
    options: (props) => ({
      variables: {
        id: props.selectedProjectId,
      },
    }),
    props: ({ data: { Project }, loading, error }) => {
      if (Project) {
        const {
          get: { preferences },
        } = Project;
        return { appearance: preferences };
      }

      return { loading, error };
    },
  })
)(Policy);
