import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import Portal from './Portal'

import { Check, ExclamationCircle, Cross } from './Icons'

const AlertContainer = styled.div`
	display: inline-flex;
	align-items: center;
	flex-direction: row;
	max-width: 650px;

	border-radius: 49px;

	padding: 14px;

	margin: auto;
	margin-top: 16px;

	box-shadow: 0px 4px 10px rgba(88, 70, 109, 0.1);

	background: #08AC7A;

	${props => props.type === 'error' && css`
		background: #EB4967;
	`}
`

const IconContainer = styled.div`
	width: 24px;
	height: 24px;
	color: white;

	> svg {
		width: 24px;
		height: 24px;
	}
`

const Body = styled.div`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 146.28%;
	display: flex;
	align-items: center;

	color: #FFFFFF;

	margin-left: 14px;
	margin-right: 14px;
`

const Close = styled(Cross)`
	cursor: pointer;

	:hover {
		opacity: 0.8;
	}
`

const Alert = ({ children, type = 'error', onExit, duration }) => {
	const [isExiting, setIsExiting] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setIsExiting(true)
		}, duration)
	}, [duration])

	return (
		<AlertContainer type={type} className={`animated ${isExiting ? 'fadeOutUp' : 'slideInDown'}`} style={{
			animationDuration: '350ms',
		}} onAnimationEnd={(evt) => {
			if (evt.target === evt.currentTarget && evt.animationName === 'fadeOutUp' && onExit) {
				onExit();
			}
		}}>
			<IconContainer>
				{type === 'success' && <Check />}
				{type === 'error' && <ExclamationCircle />}
			</IconContainer>

			<Body>
				{children}
			</Body>

			<Close onClick={() => {
				setIsExiting(true)
			}} />
		</AlertContainer>
	)
}

const NotificationArea = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	display: flex;
`

const Toast = ({ type, message, onHide, duration=2000 }) => (
	<Portal id="notifications">
		<NotificationArea>
			<Alert type={type} onExit={onHide} duration={duration}>
				{message}
			</Alert>
		</NotificationArea>
	</Portal>
)

export default Toast