import React, { useState, useContext } from 'react'

import IntegrationHelper from '../../../../Components/IntegrationHelper'

import styles from './Integrate.module.css'
import Input from '../../../../Components/Input'
import Button from '../../../../Components/Button'
import SuggestedDomains from './SuggestedDomains'
import Box from '../../../../Components/Box'
import Loading from '../../../../Components/Loading'
import TrackingContext from 'contexts/TrackingContext';


const Integrate = ({ fetchResults, scanResults, scanError, scanLoading, scanComplete, match }) => {
    const [domain, setDomain] = useState('')
    const { trackClick } = useContext(TrackingContext)

    return (
        <div className={styles.container}>
            <Box centered headerHeight={32} className={styles.box} title="Integration Helper">
                <div className={styles.body}>
                    <p className={styles.text}>Enter a domain to find and fix third parties who drop cookies on visitors to your site</p>
                    <div className={styles.header} style={scanLoading ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
                        <Input value={domain} className={styles.inputContainer} inputClassName={styles.input} onChange={e => setDomain(e.target.value)} placeholder="yourdomain.com" />
                        <Button size="small" onClick={() => {
                            fetchResults(domain)
                            trackClick('Integration Scan', match.path, { eventName: domain })
                        }} disabled={!domain.includes('.')} className={styles.button}>Scan</Button>
                    </div>

                    <SuggestedDomains style={scanLoading ? { opacity: 0.5, pointerEvents: 'none' } : {}} currentDomain={scanResults && scanResults.meta && scanResults.meta.domain} onClick={domain => {
                        setDomain(domain)
                        fetchResults(domain)
                    }} />
                </div>
            </Box>

            {scanLoading && (
                <Box className={styles.animatedBox} title={`Scanning ${domain}`}>
                    <div className={styles.loadingBody}>
                        <Loading size={51} color={'#2ECA88'} />
                        <h2 className={styles.loadingText}>This could take a minute or two depending how complex the site is</h2>
                    </div>
                </Box>
            )}

            {scanComplete && (
                <IntegrationHelper
                    meta={scanResults && scanResults.meta}
                    items={scanResults && scanResults.items}
                    error={scanError}
                    complete={scanComplete}
                />
            )}
        </div>
    )
}

export default Integrate