import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import _ from 'lodash'

import { storeTokens } from '../../../utils/multiAccounts'
import actorFields from './actorFields'
import {useContext} from 'react'
import {SelectedProjectContext} from 'contexts/ProjectContext'

export const signupMutation = gql`
    mutation signup(
        $name: String!,
        $emailAddress: String!,
        $password:String!
        $referrer:String
    ) {
        Account {
            signup(nickname:$name, email:$emailAddress, password:$password, referrer: $referrer) {
                ${actorFields}
            }
        }
    }
`

const useSignup = () => {
	const [mutate] = useMutation(signupMutation)
  const {updateSelectedProject} = useContext(SelectedProjectContext)

	return variables => mutate({
		variables,
	}).then(({ data }) => {
		const { Account: { signup: { tokens, user } } } = data
		storeTokens(tokens, user)
    const projectId = _.get(user, ['organisation', 'defaultProject', 'id'])
    updateSelectedProject(projectId)
		return data
	})
}

export default useSignup
