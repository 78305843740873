import React from 'react'
import styled from 'styled-components'
import usePlans from 'hooks/usePlans'
import Spinner from 'NewComponents/Spinner'

const Gate = styled.button`
	background: rgba(255, 255, 255, 0.4);
	border: 0;
	padding: 0;
	margin: 0;
	appearance: none;
	position: absolute;
	left: 0; right: 0;
	top: 0; bottom: 0;
	width: 100%;
	height: 100%;
	cursor: ${p => p.disabled ? 'default' : 'pointer'};

	&:focus {
		outline: 0;
	}
`

const Badge = styled.div`
	font-family: Inter;
	position: absolute;
	border-radius: 15px;
	padding: 6px 10px;
	top: 16px;
	right: 16px;
	font-size: 12px;
	text-align: center;
	box-sizing: border-box;
	background: #58476dbd;
	color: white;
	text-transform: capitalize;
	cursor: pointer;
`

export default function FeatureGate ({ needsPlan, children }) {
	const {
		currentPlan,
		confirmUpdateProjectPlan,
		loading
	} = usePlans({
		onError: () => {}
	});

	if (!needsPlan || currentPlan === needsPlan) {
		return children
	}

	return (
		<>
			<Badge>
				Requires {needsPlan.name} Plan
			</Badge>
			{children}
			<Gate
				disabled={loading}
				onClick={() => confirmUpdateProjectPlan(needsPlan)}
			>
				{loading && <Spinner />}
			</Gate>
		</>
	)
}