import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { compose } from 'redux'
import CompletedSignupGate from './CompletedSignupGate'

const getActorState = gql`
    query getActorState {
        me {
            id
            state
        }
    }
`
export default compose(
    graphql(getActorState, {
        props: ({ data: { me, error }, loading }) => ({ me, loading, error }),
    }),
)(CompletedSignupGate)
