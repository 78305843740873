import styled from 'styled-components'
import Stepper from './Stepper'

export const Box = styled.div`
	background: #FCFCFC;
	border: 1px solid rgba(88, 70, 109, 0.3);
	box-sizing: border-box;
	box-shadow: 0px 4px 10px rgba(88, 70, 109, 0.1), inset 0px 0px 10px rgba(255, 255, 255, 0.1);
	border-radius: 6px;

	width: 354px;
	height: 500px;
	padding: 30px;
	padding-bottom: 5px;

	transition: 350ms height;

	${Stepper} {
		transition: 350ms height;
	}
`

export const Footer = styled.footer`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */
	display: flex;
	align-items: center;
	text-align: center;

	color: #58466D;

	text-shadow: 0px 1px 0px #FFFFFF;

	height: 25px;
	width: 348px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: 36px;
	margin-bottom: 12px;

	a {
		color: inherit;
	}
`

export const Title = styled.h4`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-align: center;

	width: 100%;
	margin-bottom: 5px;

	color: #58466D;
`

export const HR = styled.hr`
	opacity: 0.2;
	border: none;
	border-bottom: 1px solid #7A6B8B;
	width: 114px;
	margin-bottom: 15px;
`

export const Text = styled.p`
	font-family: Inter;
	color: #58466D;
	line-height: 150%;
`

export const Content = styled.div`
	flex: 1;
`
