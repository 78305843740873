import React from 'react'
import { Redirect } from 'react-router-dom'

import client from '../../graphql/client'

export const logout = (redirectTo) => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('expiresAt')
  localStorage.removeItem('selectedProjectId')

  client.resetStore()
  window.location.href = redirectTo || '/'
}

class Logout extends React.PureComponent{
  componentDidMount() {
    logout()
  }

  render(){
    return <Redirect to="/account" />
  }
}

export default Logout
