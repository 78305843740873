
const actor = `
	id
	state
	user {
		id
		email
		organisation {
			id
			defaultProject {
				id
				primaryDomain
			}
		}
	}
	tokens {
		accessToken,
		refreshToken,
		expiresAt
	}
`

export default actor