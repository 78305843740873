import { useState, useEffect, useMemo } from 'react'
import { updates, mapObj } from 'fn-update'
import groupBy from 'lodash/groupBy'

import useProviderAssignments from './useProviderAssignments'
import useProviderScan from './useProviderScan'
import useTemplates, { ESSENTIAL_TEMPLATE_ID } from './useTemplates'

export const selectorPreviewPolicies = ({ templates, providers, providerAssignments }) => {
	if (Object.keys(providers).length === 0) {
		return [{
			id: 'tpl:essential-cookie free',
			type: 'INFO',
			providers: [],
		}]
	}

	const assignmentEntries = Object.entries(providerAssignments).map(([providerId,templateId]) => ({providerId,templateId}))
	return updates([
		entries => groupBy(entries,'templateId'),
		mapObj((entries,key) => ({
			id: key,
			...templates[key],
			providers: entries.map(entry => providers.find(p => p.id === entry.providerId)),
			type: key === ESSENTIAL_TEMPLATE_ID ? 'INFO' : 'OPT_IN'
		})),
		Object.values,
	])(assignmentEntries)
}

const useOnboarding = (domain) => {
	const [scanStartedAt, setScanStartedAt] = useState()
	const [previewPolicies, setPreviewPolicies] = useState([])
	const [templates] = useTemplates()
	const {providers, loading: scanLoading} = useProviderScan({ domain, after: scanStartedAt })
	const {providerAssignments, assignProvider, assignProviders} = useProviderAssignments({})

	// Auto assign providers
	useEffect(() => {
		if (!scanLoading && Object.keys(providerAssignments).length === 0) {
			const autoAssignable = providers.filter(p => p.templateId)
			if (!autoAssignable.length) return;

			const autoAssignments = Object.assign({}, ...autoAssignable.map(p => ({ [p.id]: p.templateId })))
			assignProviders(autoAssignments)
		}
	}, [scanLoading, providers, providerAssignments, assignProviders])

	useEffect(() => {
		if (!window.location.host.includes('localhost')) {
			setScanStartedAt(new Date())
		}
	}, [])

	// Generate preview policies for the preview
	useEffect(() => {
		if (!scanLoading && templates.length > 0) {
			const newPreviewPolicies = selectorPreviewPolicies({ templates, providers, providerAssignments })
			setPreviewPolicies(newPreviewPolicies)
		}
	}, [templates, providers, providerAssignments, scanLoading])

	return useMemo(() => ({
		providers,
		providerAssignments,
		assignProvider,
		previewPolicies,
		scanLoading,
	}), [
		providers,
		providerAssignments,
		assignProvider,
		previewPolicies,
		scanLoading,
	])
}

export default useOnboarding