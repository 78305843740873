import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { compose } from 'redux'
import ProjectSettings from './ProjectSettings'
import React from 'react'
// import { SelectedProjectContext } from 'contexts/ProjectContext'
// import useProjectsList from '../../../../hooks/useProjectsList'
import ApolloClient from '../../../../graphql/client'

export const updateSettings = gql`
    mutation updateProjectSettings(
        $id: ID!,
        $input: UpdateProjectInput!
    ) {
        Project {
            update(id:$id, update:$input) {
                id,
                primaryDomain,
                name
            }
        }
    }
`
export const deleteProject = gql`
    mutation deleteProject($id: ID!) {
        Project {
            delete(id:$id)
        }
    }
`

export default compose(
  graphql(updateSettings, {
      props: ({ mutate, error, loading}) => ({
          updateSettings: (id, input) => mutate({variables: { id, input }}),
          error,
          updatePending: loading,
      }),
  }),
  graphql(deleteProject, {
    props: ({ mutate, error, loading}) => ({
        deleteProject: (id) => mutate({variables: { id }}),
        error,
        updatePending: loading,
    }),
  }),
  (Component) => props => {
    // const { updateSelectedProject } = useContext(SelectedProjectContext)
    
    const deleteProject = (...args) => ApolloClient.resetStore()
      .then(() => props.deleteProject(...args))
      .then(() => {
        
        // layers upon layers of complexity have come to bite us in the ass.
        // this project gets very unhappy if there's a selected project that doesn't exist
        // I cannot find any simple way to get the project context provider to refetch things
        // without the rest of the app continuing to operate on stale data. This results in
        // an absolute mess of an experience. This is not the "right" solution, for sure,
        // but I think we should sooner rewrite this dashboard than fix the mess that this one's in.
        // localStorage.

        localStorage.removeItem("selectedProjectId")
        window.location.href = "/"
        
      })
    return React.createElement(Component,{ ...props, deleteProject})
  }
)(ProjectSettings)
