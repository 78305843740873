import styled from 'styled-components'
import { Select } from '../../../NewComponents/Form'

const AccountSelect = styled(Select)`
	border: 1px solid rgba(0,0,0, 0.05);
	box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.13);

	border-radius: 5px;
	background: #FFFFFF;

	select {
		background: transparent;
		appearance: none;

		padding-top: 12px;
		padding-bottom: 12px;
	}

	&, select {
		box-sizing: border-box;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		color: #58466D;
		min-width: none;
	}

	height: unset;
	position: relative;

	:after {
		height: 38px;
	}
`

export default AccountSelect