import features from "../utils/features";

export default [
  {
    text: "Cookie Widget",
    path: "/consent",
    icon: "toggle",
    children: [
      {
        text: "Overview",
        path: "/consent",
      },
      {
        text: "Appearance",
        path: "/consent/appearance",
      },
    ],
  },
  features.autoblocking && {
    text: "Autoblocking",
    path: "/autoblocking",
    icon: "magic",
    children: [],
  },
  {
    text: "Micropolicies",
    path: "/policies",
    icon: "list",
    children: [
      {
        text: "Micropolicies",
        path: "/policies",
        isActive: (pathname) => pathname.includes("/policies"),
      },
    ],
  },
  {
    text: "Developers",
    path: "/developers",
    icon: "laptop",
    children: [
      {
        text: "Keys",
        path: "/developers",
      },
      {
        text: "Integration Helper",
        path: "/developers/integrate",
      },
      {
        text: "Documentation",
        path: "https://docs.confirmic.com",
      },
    ],
  },
  {
    text: "Settings",
    path: "/settings",
    icon: "settings",
    children: [
      {
        text: "Account",
        path: "/settings",
      },
      {
        text: "Project",
        path: "/settings/project",
      },
    ],
  },
].filter(Boolean);
