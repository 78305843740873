import ApolloClient from "apollo-boost"
import Auth from "../utils/Auth"

const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT

const getSelectedProjectId = () => localStorage.getItem("selectedProjectId")

const client = new ApolloClient({
  uri: endpoint,
  request: async (operation) => {
    const accessToken = Auth.getAccessToken()
    if (!accessToken) {
      localStorage.removeItem("selectedProjectId")
      return operation
    } else {
      const selectedProjectId = getSelectedProjectId()

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        ...(selectedProjectId ? { "x-project-id": selectedProjectId } : {})
      }

      operation.setContext({
        headers
      })

      return operation
    }
  }
})

export default client
