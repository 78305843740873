import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { addNotification } from '../Toast'

//import Loading from '../Loading'

import styles from './Button.module.css'

/*
	type = 
		primary
		secondary
		tertiary
		default
		white
	size =
		small
		medium
		large
		x-large
	loading: t/f
	to: path
*/

// const Check = ({ size = 20, className }) => (
// 	<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
// 		<path d="M7.43431 15.7643L2.2343 10.5344C1.9219 10.2202 1.9219 9.71077 2.2343 9.39654L3.36565 8.25865C3.67806 7.94442 4.18462 7.94442 4.49703 8.25865L8 11.7817L15.503 4.23565C15.8154 3.92145 16.3219 3.92145 16.6344 4.23565L17.7657 5.37353C18.0781 5.68774 18.0781 6.19718 17.7657 6.51141L8.56569 15.7644C8.25325 16.0786 7.74672 16.0786 7.43431 15.7643Z" fill="white"/>
// 	</svg>
// )

class Button extends React.PureComponent {
	componentDidUpdate(prevProps) {
		if (!this.props.notificationsDisabled) {
			
			if (!prevProps.complete && this.props.complete) {
				addNotification({
					title: this.props.verbPastTense || 'Saved',
					message: `${this.props.verbPastTense || 'Saved'} successfully`,
					type: 'success',
				})
			}
			

			const error = this.props.error
			if (error && error !== prevProps.error) {
				const friendlyMessage = (error.graphQLErrors
					&& error.graphQLErrors.find(e => e.message) // find first one with a friendly message
					|| ({message:`There was an issue ${this.props.verb || 'saving'}, please try again`})
				).message
				
				addNotification({
					title: 'Error',
					message: friendlyMessage,
					type: 'danger',
				})
			}
		}
	}

	render() {
		const { children, notificationsDisabled, htmlType, type = 'primary', size = 'medium', to, loading, verb, verbPastTense, complete, error, className, ...props } = this.props

		if (to) {
			return (
				<Link
					className={classnames(styles[`button-${type}-${size}`], className)}
					to={to}
					disabled={loading}
					{...props}
				>
					<span>{children}</span>
				</Link>
			)
		}

		return (
			<button
				className={classnames(styles[`button-${type}-${size}`], className)}
				type={htmlType}
				disabled={loading}
				{...props}
			>
        {React.Children.map(children, c => typeof c === 'string'
          ? <span>{c}</span>
          : c
        )}
			</button>
		)
	}
}

export default Button
