import React, { useState } from "react";
import Immutable from "immutable";
import { Link } from "react-router-dom";
import classnames from "classnames";

import logger from "../../utils/logger";

import styles from "./Box.module.css";

import Input from "../Input";
import Button from "../Button";

const getDefaultValues = (defaultValues, table, keys) =>
  table
    ? defaultValues
    : Immutable.fromJS(defaultValues).filter((v, k) => keys.includes(k));

const Box = ({
  className,
  loading,
  headerHeight,
  title,
  inputs,
  readOnly,
  boxBodyStyles,
  darkMode,
  description,
  inlineInputLabels,
  icon,
  verb,
  verbPastTense,
  noun = "item",
  centered,
  inputsClassName,
  heading,
  noTitle,
  comingSoon,
  defaultValues,
  children,
  table,
  saveBtnText = "Save",
  buttonSize = "medium",
  buttonsBottom,
  onChange,
  onCancel,
  onSave,
  noBackground,
  rightColumn,
  notificationsDisabled,
  footer,
}) => {
  let [values, setValues] = useState(null);
  const [savePending, setSavePending] = useState(false);
  const [saveComplete, setSaveComplete] = useState(false);
  //eslint-disable-next-line no-var, no-unused-vars
  const [saveError, setSaveError] = useState(false);

  if (!values && defaultValues) {
    values = Immutable.fromJS(
      getDefaultValues(
        defaultValues,
        table,
        [...inputs, ...(rightColumn || [])].map((i) => i.key)
      )
    );
  }

  if (!values || (table && values.isEmpty())) {
    values = Immutable.fromJS(table ? [{}] : {});
  }

  const buttonsContainer =
    onCancel || onSave ? (
      <div className={styles.buttonsContainer}>
        {onCancel && (
          <Button
            htmlType="button"
            type="default"
            onClick={() => {
              const newValues = defaultValues
                ? Immutable.fromJS(getDefaultValues(defaultValues))
                : Immutable.fromJS(table ? [{}] : {});
              setValues(newValues);
              onChange && onChange(newValues);
            }}
            style={{ marginRight: 20 }}
            size={buttonSize}
          >
            Cancel
          </Button>
        )}
        {onSave && (
          <Button
            type="primary"
            verb={verb}
            verbPastTense={verbPastTense}
            size={buttonSize}
            loading={savePending}
            error={saveError}
            complete={saveComplete}
          >
            {saveBtnText}
          </Button>
        )}
      </div>
    ) : (
      footer
    );

  return (
    <form
      className={classnames(table ? styles.boxTable : styles.box, className)}
      onSubmit={(e) => {
        e.preventDefault();
        if (onSave) {
          if (!notificationsDisabled) {
            setSavePending(true);

            onSave(values.toJS())
              .then(() => {
                setSavePending(false);
                setSaveComplete(true);
                setTimeout(() => {
                  setSaveComplete(false);
                }, 1000);
              })
              .catch((e) => {
                logger.error(e);
                setSavePending(false);
              });
          } else {
            onSave(values.toJS());
          }
        }
      }}
    >
      {(title || !table) && !noTitle && (
        <div
          className={centered ? styles.boxHeaderCentered : styles.boxHeader}
          style={
            noBackground
              ? { borderBottom: "none" }
              : {
                  borderBottomColor: table ? "#F2F2F2" : "#F5F5FA",
                  height: headerHeight ? headerHeight : description ? 75 : 65,
                }
          }
        >
          {icon && <div className={styles.iconContainer}>{icon}</div>}
          <h4
            className={styles.boxTitle}
            style={centered && { justifyContent: "center" }}
          >
            <span>{title}</span>
          </h4>
          {description && <p className={styles.description}>{description}</p>}
          {!buttonsBottom && buttonsContainer}
        </div>
      )}
      <div
        className={table ? styles.boxBodyTable : styles.boxBody}
        style={{
          opacity: loading ? 0.4 : 1,
          marginTop: title ? 0 : -1,
          backgroundColor: noBackground ? "transparent" : undefined,
          ...(boxBodyStyles || {}),
        }}
      >
        {inputs && inputs.length > 0 && (
          <div
            className={classnames(
              table ? styles.tableContainer : styles.inputsContainer,
              inputsClassName
            )}
          >
            {heading && <h4 className={styles.subheading}>{heading}</h4>}
            {!table &&
              inputs &&
              inputs.map((input) => (
                <Input
                  darkMode={darkMode}
                  inlineLabel={inlineInputLabels}
                  key={input.key}
                  value={values.get(input.key)}
                  onChange={(e) => {
                    const v = e.target ? e.target.value : e;
                    if (values.get(input.key) !== v) {
                      const newValues = values.set(input.key, v);
                      setValues(newValues);
                      onChange && onChange({ [input.key]: v });
                    }
                  }}
                  {...input}
                />
              ))}
            {table && (
              <table className={styles.table}>
                <thead className={styles.thead}>
                  <tr>
                    {inputs.map((input) => (
                      <th
                        className={styles.tableHeading}
                        key={input.key}
                        style={input.rightAlign && { textAlign: "right" }}
                      >
                        {input.label}
                      </th>
                    ))}
                    {!readOnly && <th />}
                  </tr>
                </thead>
                <tbody>
                  {values.map((value, i) => (
                    <tr key={i} className={styles.tr}>
                      {!readOnly &&
                        inputs.map((input) => (
                          <td className={styles.td} key={input.key}>
                            <Input
                              className={styles.mwUnset}
                              value={value.get(input.key)}
                              onChange={(e) => {
                                const v = e.target ? e.target.value : e;
                                const curr = value.get(input.key);
                                if (v === curr) return;
                                let newValue = value.set(input.key, v);
                                if (input.changesRow) {
                                  newValue = input.changesRow(newValue);
                                }
                                const newValues = values.set(i, newValue);
                                setValues(newValues);
                                onChange && onChange(newValues);
                              }}
                              {...input}
                              transparent
                              label={null}
                            />
                          </td>
                        ))}
                      {readOnly &&
                        inputs.map((input) => (
                          <td
                            className={styles.td}
                            key={input.key}
                            style={input.rightAlign && { textAlign: "right" }}
                          >
                            <span className={styles.readonlyText}>
                              {values.getIn([i, input.key, "to"]) ? (
                                <Link to={values.getIn([i, input.key, "to"])}>
                                  {values.getIn([i, input.key, "text"])}
                                </Link>
                              ) : (
                                values.getIn([i, input.key])
                              )}
                            </span>
                          </td>
                        ))}
                      {!readOnly && !(value.isEmpty() && values.size === 1) && (
                        <td
                          className={styles.clickable}
                          onClick={() => {
                            let newValues = values.delete(i);
                            if (newValues.isEmpty()) {
                              newValues = Immutable.fromJS([{}]);
                            }
                            setValues(newValues);
                            onChange && onChange(newValues);
                          }}
                        >
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.43475 0.246139C1.10656 -0.0820463 0.574474 -0.0820463 0.246291 0.246139C-0.0818922 0.574324 -0.0818922 1.10642 0.246291 1.4346L3.81159 4.99992L0.246137 8.5654C-0.0820457 8.89358 -0.0820458 9.42568 0.246137 9.75386C0.57432 10.082 1.10641 10.082 1.43459 9.75386L5.00005 6.18839L8.56548 9.75385C8.89367 10.082 9.42576 10.082 9.75394 9.75385C10.0821 9.42566 10.0821 8.89357 9.75394 8.56538L6.1885 4.99992L9.75379 1.43462C10.082 1.10643 10.082 0.574338 9.75379 0.246153C9.4256 -0.0820322 8.89351 -0.0820322 8.56533 0.246153L5.00005 3.81146L1.43475 0.246139Z"
                              fill="#979797"
                            />
                          </svg>
                        </td>
                      )}
                    </tr>
                  ))}
                  {!readOnly && (
                    <tr className={styles.tr}>
                      <td colSpan="100%" className={styles.td}>
                        <Button
                          type="white"
                          size="small"
                          htmlType="button"
                          onClick={() => {
                            const newValues = values.push(Immutable.Map({}));
                            setValues(newValues);
                            onChange && onChange(newValues);
                          }}
                          className={styles.newRowBtn}
                        >
                          + Add a {noun}
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        )}
        {rightColumn && (
          <div className={styles.rightColumn}>
            <div
              className={classnames(
                table ? styles.tableContainer : styles.inputsContainer,
                inputsClassName
              )}
            >
              {!table &&
                rightColumn &&
                rightColumn.map((input) => (
                  <Input
                    darkMode={darkMode}
                    key={input.key}
                    value={values.get(input.key)}
                    onChange={(e) => {
                      const v = e.target ? e.target.value : e;
                      const newValues = values.set(input.key, v);
                      setValues(newValues);
                      onChange && onChange({ [input.key]: v });
                    }}
                    {...input}
                  />
                ))}
            </div>
          </div>
        )}
        {children}
      </div>

      {buttonsBottom && (
        <div className={centered ? styles.boxFooterCentered : styles.boxFooter}>
          {buttonsContainer}
        </div>
      )}
    </form>
  );
};

export default Box;
