import { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { SelectedProjectContext } from "../../contexts/ProjectContext";
import gql from "graphql-tag";
//import { set } from 'immutable'
import { Plans } from "hooks/usePlans";

export const query = gql`
  query chatMetadata {
    Organisation {
      get {
        id
        highestProjectPlan
      }
    }
    me {
      id
    }
  }
`;

const safeParse = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

const useLocalStorage = (key) => {
  const [value, setValue] = useState(safeParse(localStorage.getItem(key)));
  return [
    value,
    (value) => {
      window.localStorage.setItem(key, JSON.stringify(value));
      setValue(value);
    },
  ];
};

const useChatPermission = () => {
  const [hasPermission, setStoredPermission] = useLocalStorage(
    "chat-permission"
  );

  // Track whether the user explicitly grants permission
  const [permissionWasExplicit, setPermissionExplicit] = useLatch(false);
  const setPermission = (val) => {
    if (val) {
      setPermissionExplicit();
    }
    setStoredPermission(true);
  };

  return { hasPermission, setPermission, permissionWasExplicit };
};

const bootIntercom = (params) => {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/zwwnvxnx";
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
  window.Intercom("boot", {
    app_id: "zwwnvxnx",
    ...params,
  });
};

// A hook that flips a boolean permanently. Allows you to call the "setter" without a parameter
const useLatch = (initialValue) => {
  const [latched, setLatched] = useState(false);
  return [latched ? !initialValue : initialValue, () => setLatched(true)];
};

const useChat = () => {
  const { data: metadata } = useQuery(query); // loading,
  const {
    hasPermission,
    setPermission,
    permissionWasExplicit,
  } = useChatPermission();
  const { selectedProject } = useContext(SelectedProjectContext);
  const [intercomLoaded, setIntercomLoaded] = useLatch(false);

  // Load intercom into page when dependencies available
  useEffect(() => {
    if (hasPermission && metadata) {
      const params = {
        mtm_org_id: metadata.Organisation.get.id,
        mtm_plan: metadata.Organisation.get.highestProjectPlan,
        mtm_selected_project_id: selectedProject?.id,
        mtm_selected_project_domain: selectedProject?.primaryDomain,
        mtm_user_id: metadata.me.id,
      };
      bootIntercom(params);
      setIntercomLoaded();
    }
  }, [hasPermission, metadata, selectedProject?.id]);

  // Raise intercom into view if the user explicitly granted permission for chat in this session
  useEffect(() => {
    if (permissionWasExplicit && intercomLoaded) {
      window.Intercom("show");
    }
  }, [permissionWasExplicit, intercomLoaded]);

  const canChat =
    metadata?.Organisation?.get?.highestProjectPlan === Plans.pro.key;

  return {
    hasPermission,
    setPermission,
    canChat,
    grantPermission: () => setPermission(true),
  };
};

export default useChat;
