import React, { useContext } from 'react'
import { graphql } from 'react-apollo'
import { compose } from 'redux'
import Connect from './Connect'
import { getProject } from '../Dashboard/Consent/Appearance/AppearanceContainer'
import { SelectedProjectContext } from 'contexts/ProjectContext'

const passProjectId = (component) => (props) => {
    const { selectedProject } = useContext(SelectedProjectContext)
    return selectedProject
        ? React.createElement(component,{ ...props, selectedProjectId: selectedProject.id })
        : null
}

const getProjectFromAPI = graphql( getProject, {
    options: (props) => ({
        variables: {
            id: props.selectedProjectId
        }
    }),
    props: ({ data: { Project }, loading }) => Project ? ({ projectId: Project.get.id, loading }) : ({ loading }),
})

export default compose(passProjectId, getProjectFromAPI)(Connect)