import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { compose } from 'redux'
import Settings from './Settings'

export const getUserInfo = gql`
    query getUserInfo {
        me {
            id
            state
            user {
                id
                email
                nickname
            }
        }
    }
`

export const updateUser = gql`
    mutation updateUser(
    	$nickname: String!
    ) {
        User {
            update(update:{nickname:$nickname}) {
								id
								nickname
            }
        }
    }
`


export default compose(
    graphql(getUserInfo, {
        props: ({ data: { me, refetch }, loading }) => me ? ({
            email: me.user.email,
            nickname: me.user.nickname,
            loading,
            refetch,
        }) : ({ loading }),
    }),
    graphql(updateUser,{
    	props: ({mutate,loading,error}) => ({
				updateUser: variables => mutate({variables}),
				error,
				updatePending:loading
			})
		}),

)(Settings)
