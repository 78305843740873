import React, { useState } from "react";
import Immutable from "immutable";
import Modal from "../../../../Components/Modal";
import colors from "../../../../styles/colors.module.css";
import Button from "../../../../Components/Button";
import Box from "../../../../Components/Box";

import {
  icons as policyIcons,
  PolicyIcon,
} from "../../../../Components/PolicyHeader/PolicyHeader";

import styles from "./NewPolicy.module.css";
import logger from "../../../../utils/logger";

export const icons = {
  micropolicy: ({ color = colors.PartyPink }) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 41.5229C30 42.265 30.9624 42.5564 31.374 41.9389L45.2226 21.166C45.5549 20.6676 45.1976 20 44.5986 20H34V6.47708C34 5.73503 33.0376 5.44363 32.626 6.06106L18.7774 26.834C18.4451 27.3324 18.8024 28 19.4014 28H30V41.5229Z"
        fill={color}
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.5C6 10.6716 6.67157 10 7.5 10H20.5C21.3284 10 22 10.6716 22 11.5V14.5C22 15.3284 21.3284 16 20.5 16H7.5C6.67157 16 6 15.3284 6 14.5V11.5ZM6 35.5C6 34.6716 6.67157 34 7.5 34H20.5C21.3284 34 22 34.6716 22 35.5V38.5C22 39.3284 21.3284 40 20.5 40H7.5C6.67157 40 6 39.3284 6 38.5V35.5ZM3.5 22C2.67157 22 2 22.6716 2 23.5V26.5C2 27.3284 2.67157 28 3.5 28H14.5C15.3284 28 16 27.3284 16 26.5V23.5C16 22.6716 15.3284 22 14.5 22H3.5Z"
        fill={color}
      />
    </svg>
  ),
  details: ({ color = colors.PartyPink }) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.3"
        x="8"
        y="10"
        width="32"
        height="4"
        rx="1.5"
        fill={color}
      />
      <rect
        opacity="0.3"
        x="8"
        y="26"
        width="32"
        height="4"
        rx="1.5"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 18C8.67157 18 8 18.6716 8 19.5V20.5C8 21.3284 8.67157 22 9.5 22H26.5C27.3284 22 28 21.3284 28 20.5V19.5C28 18.6716 27.3284 18 26.5 18H9.5ZM9.5 34C8.67157 34 8 34.6716 8 35.5V36.5C8 37.3284 8.67157 38 9.5 38H26.5C27.3284 38 28 37.3284 28 36.5V35.5C28 34.6716 27.3284 34 26.5 34H9.5Z"
        fill={color}
      />
    </svg>
  ),
  toggle: ({ color }) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.3"
        x="4"
        y="12"
        width="42"
        height="24"
        rx="12"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 32C38.4183 32 42 28.4183 42 24C42 19.5817 38.4183 16 34 16C29.5817 16 26 19.5817 26 24C26 28.4183 29.5817 32 34 32Z"
        fill={color}
      />
    </svg>
  ),
  clipboard: ({ color }) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6H10C8.89543 6 8 6.89543 8 8V44C8 45.1046 8.89543 46 10 46H38C39.1046 46 40 45.1046 40 44V8C40 6.89543 39.1046 6 38 6H32V7C32 8.65685 30.6569 10 29 10H19C17.3431 10 16 8.65685 16 7V6Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 4H29.5C29.7761 4 30 4.22386 30 4.5V7.5C30 7.77614 29.7761 8 29.5 8H18.5C18.2239 8 18 7.77614 18 7.5V4.5C18 4.22386 18.2239 4 18.5 4H22C22 2.89543 22.8954 2 24 2C25.1046 2 26 2.89543 26 4Z"
        fill={color}
      />
      <rect
        opacity="0.3"
        x="14"
        y="20"
        width="10"
        height="4"
        rx="2"
        fill={color}
      />
      <rect
        opacity="0.3"
        x="14"
        y="28"
        width="18"
        height="4"
        rx="2"
        fill={color}
      />
    </svg>
  ),
  chat: ({ color }) => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6C7.34315 6 6 7.34315 6 9V33C6 34.6569 7.34315 36 9 36H36.0498L42.3427 42.2929C42.9727 42.9229 44.0498 42.4767 44.0498 41.5858V30L44 30.0196V9C44 7.34315 42.6569 6 41 6H9Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 24C13.3431 24 12 22.6569 12 21C12 19.3431 13.3431 18 15 18C16.6569 18 18 19.3431 18 21C18 22.6569 16.6569 24 15 24ZM25 24C23.3431 24 22 22.6569 22 21C22 19.3431 23.3431 18 25 18C26.6569 18 28 19.3431 28 21C28 22.6569 26.6569 24 25 24ZM35 24C33.3431 24 32 22.6569 32 21C32 19.3431 33.3431 18 35 18C36.6569 18 38 19.3431 38 21C38 22.6569 36.6569 24 35 24Z"
        fill={color}
      />
    </svg>
  ),
};

// const PolicyType = ({ name, description, icon, isActive, onClick }) => (
//   <div onClick={onClick} className={styles.policyType}>
//     <div
//       className={isActive ? styles.policyTypeBoxActive : styles.policyTypeBox}
//     >
//       {icons[icon]({ color: isActive ? colors.Teal : colors.Black })}
//     </div>
//     <h4 className={styles.policyTypeName}>{name}</h4>
//     <p className={styles.policyTypeDescription}>{description}</p>
//   </div>
// );

// const types = [
//   {
//     key: "OPT_IN",
//     name: "Opt-in",
//     icon: "toggle",
//     description: "When you ask if you can do something",
//   },
//   {
//     key: "INFO",
//     name: "Informative",
//     icon: "chat",
//     description: "When you explain why you do something",
//   },
//   {
//     key: "ACKNOWLEDGMENT",
//     name: "Acknowledgement",
//     icon: "clipboard",
//     description: "When your user must agree to something to proceed",
//   },
// ];

// const PolicyTypes = ({ onSubmit }) => {
//     const [policyType, setPolicyType] = useState(types[0].key)

//     return (
//         <div className={styles.container}>
//             <div className={styles.icon}>{icons.micropolicy({})}</div>
//             <h2 className={styles.title}>What type of policy would you like to create?</h2>
//             <div className={styles.policyTypes}>{types.map(type => <PolicyType key={type.key} isActive={type.key === policyType} onClick={() => setPolicyType(type.key)} {...type} />)}</div>
//             <div className={styles.button}><Button size="medium-large" onClick={() => onSubmit(policyType)}>Next</Button></div>
//         </div>
//     )
// }

const PolicyDetails = ({ onSubmit }) => (
  <div className={styles.boxContainer}>
    <Box
      title="Create Micropolicy"
      inputs={[
        {
          label: "Name",
          required: true,
          placeholder: "Anonymous Statistics",
          key: "name",
          description:
            "The feature or category of features that require these data",
          className: styles.inputLabel,
        },
        {
          label: "Description",
          required: true,
          placeholder: "Keep this concise and use plain and simple language",
          description:
            "Convince your users to opt in by focusing on the value that they’ll get from this feature",
          key: "description",
          type: "textarea",
          className: styles.inputLabel,
        },
        {
          label: "Uses cookies",
          type: "switch",
          icon: "cookie",
          key: "usesCookies",
        },
        {
          label: "Include in Cookie Widget",
          type: "switch",
          icon: "cookie",
          key: "isInitial",
        },
      ]}
      buttonsBottom
      inputsClassName={styles.inputs}
      defaultValues={{ isInitial: true, usesCookies: true }}
      onSave={(values) => onSubmit(values)}
      buttonSize="medium-large"
      saveBtnText="Next"
      centered
      notificationsDisabled
      icon={icons.details({})}
    />
  </div>
);

const chunk = (arr, len) => {
  const chunks = [],
    n = arr.length;
  let i = 0;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
};

export const PolicyIconPicker = ({ value, loading, error, onSubmit }) => {
  const [policyIcon, setPolicyIcon] = useState(
    value || Object.keys(policyIcons)[0]
  );

  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icons.details({})}</div>
      <h2 className={styles.title}>Pick an icon to represent your policy</h2>
      <div className={styles.policyIcons}>
        {chunk(Object.entries(policyIcons), 4).map((row, i) => (
          <div className={styles.policyIconRow} key={i}>
            {row.map(([key]) => (
              <div
                key={key}
                className={
                  key === policyIcon
                    ? styles.policyIconContainerActive
                    : styles.policyIconContainer
                }
                onClick={() => setPolicyIcon(key)}
              >
                <PolicyIcon width={58} height={58} type={key} />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.button}>
        <Button
          size="medium-large"
          disabled={loading}
          error={error}
          type="primary"
          onClick={() => onSubmit(policyIcon)}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

const Back = ({ onClick }) => (
  <div className={styles.backButton} onClick={onClick}>
    {"<- back"}
  </div>
);

const Close = ({ onClick }) => (
  <div className={styles.closeButton} onClick={onClick}>
    {"x"}
  </div>
);

const NewPolicy = ({ isVisible, setIsVisible, onSubmit }) => {
  const [policy, setPolicy] = useState(Immutable.Map({}));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  // let currentStep = 1
  // if (policy.get('policyType')) {
  //     currentStep = 1
  // }
  // if (policy.get('name')) {
  //     currentStep = 2
  // }

  return (
    <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
      <div className={styles.contentContainer}>
        <Close onClick={() => setIsVisible(false)} />
        {currentStep > 1 && (
          <Back onClick={() => setCurrentStep(currentStep - 1)} />
        )}
        {/* {currentStep === 0 && <PolicyTypes onSubmit={policyType => setPolicy(policy.set('policyType', policyType))} />} */}
        {currentStep === 1 && (
          <PolicyDetails
            onSubmit={(policyDetails) => {
              setPolicy(policy.merge(policyDetails));
              setCurrentStep(2);
            }}
          />
        )}
        {currentStep === 2 && (
          <PolicyIconPicker
            onSubmit={(policyIcon) => {
              setPolicy(policy.set("icon", policyIcon));
              setLoading(true);
              setError(false);
              onSubmit(policy.set("icon", policyIcon).toJS())
                .then(() => {
                  setLoading(false);
                })
                .catch((e) => {
                  logger.error(e);
                  setLoading(false);
                  setError(e);
                });
            }}
            loading={loading}
            error={error}
          />
        )}
      </div>
    </Modal>
  );
};

export default NewPolicy;
