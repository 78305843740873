import React from 'react'
import { Redirect } from 'react-router-dom'

const RedirectWithParams = ({ to }) => {
	const params = new URLSearchParams(window.location.search)
	const url = `${to}?${params.toString()}`

	return <Redirect to={url} />
}

export default RedirectWithParams