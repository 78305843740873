import styled from 'styled-components'

const Stepper = styled.div`
	display: inline-block;
	overflow: hidden;
	position: relative;

	width: 100%;
	height: 100%;

	> * {
		position: absolute;
		left: 0;
		transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);

		transform: translateX(-100%);
		opacity: 0;

		width: 100%;
		height: 100%;
	}

	[data-active] + * {
		transform: translateX(100%);
		visibility: hidden;
	}

	[data-active] {
		transform: translateX(0%);
		opacity: 1;
		visibility: visible;
	}
`

export const StepFooter = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
    flex-direction: column;
	width: 100%;
`

export default Stepper