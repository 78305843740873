import React, { useState } from "react";

import styles from "./Settings.module.css";

import Box from "../../../Components/Box";
import Button from "../../../Components/Button";
import NavBar from "../../../Components/NavBar";

import Toast from "NewComponents/Toast";

import DataControls from "./DataControls";
import ManageSubscription from "./ManageSubscription";

const Settings = ({
  loading,
  error,
  updatePending,
  email,
  nickname,
  updateUser,
}) => {
  const [settings, setSettings] = useState(null);
  const [complete, setComplete] = useState(false);
  const [message, setMessage] = useState(null);

  return (
    <div className={styles.mainContainer}>
      {message && (
        <Toast
          type="success"
          message={message}
          onHide={() => setMessage(null)}
        />
      )}
      <NavBar
        topRightComponent={
          settings && (
            <Button
              error={error}
              size="large"
              complete={complete}
              className={styles.saveButton}
              onClick={async () => {
                setComplete(false);
                await updateUser(settings);
                setComplete(true);
                setTimeout(() => {
                  setComplete(false);
                }, 1500);
              }}
              loading={updatePending}
            >
              Save
            </Button>
          )
        }
      />
      <div className={styles.container}>
        <Box
          title="Account"
          inlineInputLabels
          inputs={[
            {
              label: "Your name",
              key: "nickname",
              placeholder: "Sam",
              description:
                "We only use this in communications with you. It stops us sounding like robots. *beep*",
            },
            {
              label: `Your email`,
              disabled: true,
              key: "email",
              placeholder: "me@example.com",
              description: (
                <span>
                  See our{" "}
                  <a
                    href="https://www.confirmic.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                  ↗
                </span>
              ),
            },
          ]}
          loading={loading}
          defaultValues={{ email, nickname }}
          onChange={(s) => setSettings(s)}
          className={styles.box}
          buttonsBottom
          footer={
            <ManageSubscription
              onCancelled={(message) => setMessage(message)}
            />
          }
        ></Box>
        <DataControls />
      </div>
    </div>
  );
};

export default Settings;
