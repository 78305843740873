import React from 'react'
import ReactNotification from 'react-notifications-component'

import 'react-notifications-component/dist/theme.css'
import styles from './Toast.module.css'
import {Cross} from '../Icons/Icons.js'

export class ToastBase extends React.PureComponent {
    constructor(props) {
        super(props)
        this.notificationDOMRef = React.createRef()
    }

    componentDidMount() {
        window.notificationDOMRef = this.notificationDOMRef
    }

    render() {
        return <ReactNotification ref={this.notificationDOMRef} />
    }
}

const activeNotifications = {}

export const addNotification = ({ title, message, type }) => {
    const toaster = window.notificationDOMRef && window.notificationDOMRef.current

    if (!toaster){
        return
    }
    
    const staticNotificationId = `${title}|${message}|${type}`
    if (activeNotifications[staticNotificationId]){
        toaster.removeNotification(activeNotifications[staticNotificationId])
    }
    
    const id = toaster.addNotification({
        title,
        message,
        type: type === 'error' ? 'danger' : type,
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeInDown', styles.fastAnimation],
        animationOut: ['animated', 'fadeOutDown', styles.fastAnimation],
        dismiss: { duration: 3000 },
        dismissable: { click: true },
        dismissIcon: {
            content: <Cross color="white" />,
            className: 'notification-close',
        }
    })
    
    activeNotifications[staticNotificationId] = id
    
    return id
}

// export class Toast extends React.PureComponent {
//     componentDidMount() {
//         const {
//             title,
//             message,
//             type,
//         } = this.props

//         this.id = window.notificationDOMRef.current.addNotification({
//             title,
//             message,
//             type,
//             insert: 'top',
//             container: 'top-center',
//             animationIn: ['animated', 'fadeIn'],
//             animationOut: ['animated', 'fadeOut'],
//             dismiss: { duration: 2000 },
//             dismissable: { click: true }
//         })
//     }

//     componentWillUnmount() {
//         window.notificationDOMRef.current.removeNotification(this.id)
//     }

//     render() {
//         return null
//     }
// }