import React, { useState, useMemo, useRef, useEffect } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import useInterval from "hooks/useInterval";
import { updateAt, updates } from "fn-update";
import { noop } from "lodash";
// From https://github.com/matomo-org/matomo/blob/62121d3c431222ea7927d404f11cbbe7f8fb9b23/js/piwik.js#L4363
function generateId(numChars, charset) {
  const charLen = charset.length;
  let id = "";

  for (let i = 0; i < numChars; i++) {
    id += charset.charAt(Math.floor(Math.random() * charLen));
  }

  return id;
}

export const generatePageViewId = () =>
  generateId(
    6,
    "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  );

export const generateVisitorId = () =>
  generateId(16, "01234567890abcdefABCDEF");

const TrackingContext = React.createContext({});

export default TrackingContext;

const mutation = gql`
  mutation clientEvents($events: [WebAnalyticsInput]!) {
    clientEvents(events: $events)
  }
`;

export const EventCategories = {
  BUTTON_CLICKED: "Button clicked",
  SWITCH_TOGGLED: "Switch toggled",
  SELCT_CHANGED: "Select changed",
  TEXT_INPUT_CHANGED: "Text Input changed",
  ITEM_DRAGGED: "Item dragged",
};

const VISITOR_ID_LOCALSTORAGE_KEY = "vid";
const LAST_VIEWED_TIME_LOCALSTORAGE_KEY = "lvts";
const MAX_ANALYTICS_PERIOD_IN_MS = 5000;

export const TrackingProvider = ({ children }) => {
  const [hasConsent, setConsentState] = useState(undefined);
  const actionQueue = useRef([]);
  const visitorId = useRef(localStorage.getItem(VISITOR_ID_LOCALSTORAGE_KEY));
  const lastVisitTime = useRef(
    localStorage.getItem(LAST_VIEWED_TIME_LOCALSTORAGE_KEY)
  );
  //const [pageViewId, setPageViewId] = useState(generatePageViewId());
  const [sendEvents] = useMutation(mutation);

  useEffect(() => {
    localStorage.setItem(
      LAST_VIEWED_TIME_LOCALSTORAGE_KEY,
      new Date().valueOf()
    );
    window.Confirmic &&
      window.Confirmic(
        "getConsentState",
        { slug: "statistics" },
        ({ enabled }) => {
          setConsentState(enabled);
        }
      );
    window.Confirmic &&
      window.Confirmic(
        "ConsentManager:onConsentStatesChange",
        ({ policyStates }) => {
          // FIXME: Use the singular onConsentStateChange hook instead once
          // it properly reports that a consent state has changed
          const state =
            policyStates[process.env.REACT_APP_STATS_MICROPOLICY_ID];
          if (typeof state !== "undefined") setConsentState(state);
        }
      );
  }, []);

  useEffect(() => {
    if (hasConsent === true) {
      if (!visitorId.current) {
        visitorId.current = generateVisitorId();
        localStorage.setItem(VISITOR_ID_LOCALSTORAGE_KEY, visitorId.current);
      }
      if (!lastVisitTime.current) {
        localStorage.setItem(
          LAST_VIEWED_TIME_LOCALSTORAGE_KEY,
          new Date().valueOf()
        );
      }
    } else if (hasConsent === false) {
      visitorId.current = null;
      lastVisitTime.current = null;
      localStorage.removeItem(VISITOR_ID_LOCALSTORAGE_KEY);
      localStorage.removeItem(LAST_VIEWED_TIME_LOCALSTORAGE_KEY);
    }
  }, [hasConsent, visitorId, lastVisitTime]);

  useInterval(() => {
    if (!actionQueue.current.length || !hasConsent) return;

    const insertVisitorData = updateAt(
      ["payload"],
      updates({
        visitorId: visitorId.current,
        lastVisitTime: lastVisitTime.current,
      })
    );

    sendEvents({
      variables: { events: actionQueue.current.map(insertVisitorData) },
    });
    actionQueue.current = [];
  }, MAX_ANALYTICS_PERIOD_IN_MS);

  // const enqueueTrack = (name, payload = {}) => {
  //   actionQueue.current.push({
  //     client: "dashboard",
  //     at: new Date().toISOString(),
  //     payload: {
  //       ...payload,
  //       name,
  //     },
  //   });
  // };

  const track = noop;

  const trackClick = noop;

  const trackPageView = noop;

  const tracking = useMemo(
    () => ({
      track,
      trackClick,
      trackPageView,
      hasConsent,
      setConsentState,
    }),
    [track, trackClick, trackPageView, hasConsent, setConsentState]
  );

  return (
    <TrackingContext.Provider value={tracking}>
      {children}
    </TrackingContext.Provider>
  );
};
