import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";

import Toast from "NewComponents/Toast";

import styles from "./Settings.module.css";

import Box from "../../../Components/Box";
import Button from "Components/Button";
import { SelectedProjectContext } from "contexts/ProjectContext";

import usePlans, { Plans, PRO_MONTHLY_COST_GBP } from "hooks/usePlans";

const PlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const BillingPlan = styled.div`
  min-width: 350px;
  max-width: 500px;
  position: relative;
  margin: 25px 12px;
  border: 1px solid rgb(245, 245, 250);
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 21px;
  text-align: center;
  justify-content: center;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);

  h3 {
    font-size: 22px;
    font-family: "Eina";
    font-weight: 400;

    margin-top: 12px;
    margin-bottom: 12px;

    border-bottom: 1px solid rgb(245, 245, 250);

    padding-bottom: 16px;
  }

  ul {
    margin-bottom: 12px;
  }

  ul li {
    line-height: 3em;
  }

  ${(props) =>
    props.isCurrent &&
    css`
      :before {
        position: absolute;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 100%;
        left: 0;
        top: -25px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        background-color: rgba(46, 202, 136, 0.6);
        color: white;
        content: "Current Plan";
      }
    `}
`;

const BillingBody = styled.div`
  width: 100%;
  font-family: Inter;
`;

const Billing = ({ loading }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { selectedProject } = useContext(SelectedProjectContext);

  const {
    hasFreePro,
    currentPlan,
    updateProjectPlan,
    confirmUpdateProjectPlan,
    loading: isUpdatingPlan,
  } = usePlans({
    onSuccess: setSuccess,
    onError: setError,
  });

  const isCommunityPlan = currentPlan === Plans.community;
  const isProPlan = currentPlan === Plans.pro;

  return (
    <Box
      title={selectedProject.name}
      loading={loading}
      description={
        currentPlan ? (
          <>
            This project is currently on the <strong>{currentPlan.name}</strong>{" "}
            plan
          </>
        ) : (
          <>
            This project is currently on a custom plan. Please contact us to
            change your plan.
          </>
        )
      }
      className={styles.box}
      style={{ marginBottom: 32 }}
    >
      {error && (
        <Toast type="error" message={error} onHide={() => setError(null)} />
      )}
      {success && (
        <Toast
          type="success"
          message={
            <span>
              {selectedProject.name} is now on the{" "}
              <strong>{Plans[selectedProject.plan].name}</strong> plan.
            </span>
          }
          onHide={() => setSuccess(null)}
        />
      )}
      <BillingBody>
        <PlansContainer>
          <BillingPlan isCurrent={isCommunityPlan}>
            <h3>{Plans.community.name}</h3>
            <ul>
              <li>1,000 Monthly visitor consents</li>
              <li>Color and Copy customization</li>
              <li>Over 15 languages</li>
              <li>Scroll-to-consent</li>
              <li>Free</li>
            </ul>
            <Button
              disabled={isUpdatingPlan || isCommunityPlan}
              style={{ margin: "auto" }}
              size="large"
              onClick={() => updateProjectPlan(Plans.community)}
            >
              {isUpdatingPlan && isProPlan
                ? "Downgrading…"
                : `Downgrade to ${Plans.community.name}`}
            </Button>
          </BillingPlan>
          {/* <BillingPlan isCurrent={isProPlan}>
            <h3>{Plans.pro.name}</h3>
            <ul>
              <li>
                All of <strong>{Plans.community.name}</strong>
              </li>
              <li>&gt;100k unique visitors per month</li>
              {hasFreePro ? (
                <li>
                  <s>£{PRO_MONTHLY_COST_GBP} monthly</s> Free
                </li>
              ) : (
                <li>£{PRO_MONTHLY_COST_GBP} monthly</li>
              )}
            </ul>
            <Button
              disabled={isUpdatingPlan || isProPlan}
              style={{ margin: "auto" }}
              size="large"
              primary
              onClick={() => confirmUpdateProjectPlan(Plans.pro)}
            >
              {isUpdatingPlan && isCommunityPlan
                ? "Upgrading…"
                : `Upgrade to ${Plans.pro.name}`}
            </Button>
          </BillingPlan> */}
          <BillingPlan isCurrent={isProPlan}>
            <h3>Paid plans</h3>
            <ul>
              <li>
                All of <strong>{Plans.community.name}</strong>
              </li>
              <li>&gt;1k monthly visitor consents</li>
              <li>Additional features</li>
              {/* {hasFreePro ? (
                <li>
                  <s>£{PRO_MONTHLY_COST_GBP} monthly</s> Free
                </li>
              ) : (
                <li>£{PRO_MONTHLY_COST_GBP} monthly</li>
              )} */}
            </ul>
            <Button
              style={{ margin: "auto" }}
              size="large"
              primary
              onClick={() => window.location.href = "https://confirmic.com/plans"}
            >
              View plans
            </Button>
          </BillingPlan>
        </PlansContainer>
      </BillingBody>
    </Box>
  );
};

export default Billing;
