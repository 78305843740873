import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Flipper } from "react-flip-toolkit";
import { Route } from "react-router-dom";

import { HideButton } from "../../Components/ChatWithUs/ChatWithUs";
import useChat from "../../Components/ChatWithUs/useChat";
import Logo from "../../Components/Logo";
import Stepper from "../../NewComponents/Stepper";

import usePlans from "hooks/usePlans";
import useParams from "hooks/useParams";

import Login from "./Steps/Login";
import Signup from "./Steps/Signup";
import ForgotPassword from "./Steps/ForgotPassword";
import ResetPassword from "./Steps/ResetPassword";
import AccountSwitcher from "./Steps/AccountSwitcher";
import Survey from "./Steps/Survey";
import EmailSent from "./Steps/EmailSent";
import ScanStep from "./Steps/ScanStep";

import { Title, HR, Content, Box, Footer } from "../../NewComponents/Box";

const AccountContainer = styled.div`
  font-family: Inter;
  background: #fff;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledLogo = styled(Logo)`
  margin-top: 50px;
  margin-bottom: 50px;
  color: #58466d;
`;

const AccountBox = styled(Box)`
  ${(props) =>
    props.isLogin &&
    css`
      height: 405px;
    `}

  ${(props) =>
    props.isForgot &&
    css`
      height: 315px;
    `}

	${(props) =>
    props.isReset &&
    css`
      height: 405px;
    `}

	${(props) =>
    props.isScan &&
    css`
      height: 285px;
    `}

	${(props) =>
    props.isSurvey &&
    css`
      height: 700px;
    `}
`;

const AccountDetails = ({ location, setAccount, ...props }) => (
  <div {...props}>
    <Route
      path="/account/signup"
      render={() => <Title>Sign up to Confirmic</Title>}
    />
    <Route
      path="/account/login"
      render={() => <Title>Log in to Confirmic</Title>}
    />
    <Route
      path="/account/forgot"
      render={() => <Title>Forgot Password</Title>}
    />

    <HR />

    <Flipper flipKey={location.pathname}>
      <Route
        path="/account/signup"
        render={() => <Signup onSubmit={setAccount} />}
      />
      <Route path="/account/login" component={Login} />
      <Route path="/account/forgot" exact component={ForgotPassword} />
      <Route path="/account/forgot/reset" component={ResetPassword} />
    </Flipper>
  </div>
);

const Account = ({ location, history }) => {
  const { pathname } = location;
  const [account, setAccount] = useState({});
  const [paymentState, setPaymentState] = useState(null);
  const { grantPermission } = useChat();
  const params = useParams();
  const tier = params.get("tier");
  const domain = params.get("domain");

  const returnUrl = domain ? `/onboarding?domain=${domain}` : "/account/scan";
  // It's kinda gross the usePlan is here instead of inside ScanStep, but because
  // this component renders all the steps for animation purposes, we can't guarantee
  // that we're on the ScanStep when the url is reflecting as such.
  const { updateProjectPlan, loading: isUpdatingPlan } = usePlans({
    onSuccess: () => {
      setPaymentState("success");
      history.push(returnUrl);
    },
    onCancelled: () => setPaymentState("cancelled"),
    returnUrl: `${window.location.origin}${returnUrl}`,
  });

  let step = 1;
  if (pathname === "/account" || pathname === "/account/") {
    step = 0;
  }
  if (pathname === "/account/details") {
    step = 2;
  }
  if (pathname === "/account/forgot/sent") {
    step = 3;
  }
  if (pathname === "/account/scan") {
    step = 4;
  }

  const isLogin = pathname === "/account/login";
  const isForgot =
    pathname === "/account/forgot" || pathname === "/account/forgot/sent";
  const isReset = pathname === "/account/forgot/reset";
  const isScan = pathname === "/account/scan";
  const isSurvey = pathname === "/account/details";

  return (
    <AccountContainer>
      <StyledLogo />
      <Content>
        <AccountBox
          isSurvey={isSurvey}
          isLogin={isLogin}
          isForgot={isForgot}
          isReset={isReset}
          isScan={isScan}
        >
          <Stepper>
            <AccountSwitcher data-active={step === 0 || undefined} />
            <AccountDetails
              data-active={step === 1 || undefined}
              setAccount={setAccount}
              location={location}
            />
            <Survey
              data-active={step === 2 || undefined}
              emailAddress={account.emailAddress}
              name={account.name}
              tier={tier}
              domain={domain}
              isUpdatingPlan={isUpdatingPlan}
              updateProjectPlan={updateProjectPlan}
              params={params}
            />
            <EmailSent data-active={step === 3 || undefined} />
            <ScanStep
              data-active={step === 4 || undefined}
              tier={tier}
              domain={domain}
              paymentState={paymentState}
              setPaymentState={setPaymentState}
            />
          </Stepper>
        </AccountBox>
      </Content>
      <Footer>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://privacy.confirmic.com"
          style={{ textDecoration: "none" }}
        >
          Privacy Center
        </a> */}
        {/* <a href="#" onClick={grantPermission} style={{ cursor: "pointer" }}>
          Contact (uses cookies)
        </a> */}
        {/* <a>&copy; Confirmic 2021</a> */}
      </Footer>
      <HideButton />
    </AccountContainer>
  );
};

export default Account;
