import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { Redirect, withRouter } from "react-router-dom";
import { rgb, rgba, darken } from "polished";
import Flex from "styled-flex-component";
import LogoWhite from "../../Components/LogoWhite";
import ProgressIndicator from "../../NewComponents/ProgressIndicator";
import Stepper, { StepFooter } from "../../NewComponents/Stepper";
import Alert from "../../NewComponents/Alert";
import { Text, HR as StyledHR, Footer } from "../../NewComponents/Box";
import {
  Clipboard,
  Check,
  Magic,
  Code,
  Wordpress,
  LaptopCode,
} from "../../NewComponents/Icons";
import {
  InputGroup,
  Label,
  ButtonWithIcon,
  Select,
  TextButton,
} from "../../NewComponents/Form";
import { HideButton } from "../../Components/ChatWithUs/ChatWithUs";
import useChat from "../../Components/ChatWithUs/useChat";
import ColorList from "../../NewComponents/Form/ColorList";
import Spinner from "../../NewComponents/Spinner";
import CSMPreview from "../../Components/CSMPreview";
import { langs } from "../Dashboard/Consent/Appearance/Appearance";
import useValues from "../Account/hooks/useValues";
import useBrandScan from "./hooks/useBrandScan";
import Badge from "../../NewComponents/Badge";
import { Snippet, ReraiseSnippet } from "../../NewComponents/Snippets";
import Shimmer from "../../NewComponents/Shimmer";
import useAppearance from "./hooks/useAppearance";
import useTemplates from "./hooks/useTemplates";
import { SelectedProjectContext } from "contexts/ProjectContext";

import { sendBackToWp } from "../../utils/wordpress";

import { icons } from "../../Components/PolicyHeader/PolicyHeader";
import useCreatePolicies from "./hooks/useCreatePolicies";
import useOnboarding from "./hooks/useOnboarding";
import usePlans from "hooks/usePlans";

import { useAutoblockingEnabledMutation } from "../Dashboard/ThirdParties/useAutoblockingSettings";

const HR = styled(StyledHR)`
  margin-bottom: 13px;
`;

const StyledLogo = styled(LogoWhite)`
  margin-top: 50px;
  margin-bottom: 50px;
  color: white;
`;

const OnboardingContainer = styled.div`
  background: #f5f3f7;
  width: 100%;
  height: 100%;
  font-family: Inter;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  height: 222px;
  background: #58466d;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 69px;

  .ProgressIndicator {
    width: calc(65 * 8px);
  }
`;

const StepsContainer = styled(Stepper)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(88, 70, 109, 0.2);
  border-radius: 2px;

  transition: 0.35s height cubic-bezier(0.77, 0, 0.175, 1);

  width: 688px;
  height: 508px;
  margin-top: -69px;
`;

const StepContainer = styled.div`
  padding: 24px 60px;

  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;

  text-align: center;
  color: #58466d;
`;

const Description = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  ${(p) => p.center && "text-align: center;"}

  a {
    color: #eb4967;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
`;

const stepNames = ["Scan", "Categorise", "Block", "Install"];

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #58466d;
  margin-bottom: 16px;
`;

const Form = styled.form`
  margin-top: 21px;
  margin-bottom: 21px;

  ${InputGroup} {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  ${Label} {
    font-weight: 600;
    font-size: 14px;
  }

  input:not(input[type="checkbox"]) {
    width: 139px;
    height: 31px;
  }
`;

const BackButton = styled(TextButton).attrs({ type: "button" })`
  opacity: 0.6;
  margin-top: 8px;
  padding-bottom: 0;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  flex: 1;
`;

const BigSpinner = styled(Spinner)`
  width: 41px;
  height: 41px;
`;

const headerIcon = css`
  color: #58466d;
  height: 40px;
`;

const BigCheck = styled(Check)`
  ${headerIcon}
`;

const NextButton = styled(ButtonWithIcon)`
  width: auto;
`;

const BigLaptopCode = styled(LaptopCode)`
  ${headerIcon}
`;

const BigMagic = styled(Magic)`
  ${headerIcon}
`;

const BigCode = styled(Code)`
  ${headerIcon}
`;

const BigClipboard = styled(Clipboard)`
  ${headerIcon}
`;

const ScanningGate = ({ loading, children }) =>
  loading ? (
    <>
      <IconContainer>
        <BigSpinner />
      </IconContainer>
      <Heading>Scanning your domain</Heading>
      <HR />
      <Description>
        This shouldn’t take too long. In the mean time, you can customise your
        cookie widget. You can always change these later.
      </Description>
    </>
  ) : (
    children
  );

const BigButton = styled.button.attrs({
  type: "button",
})`
  color: #58466d;
  border-radius: 12px;
  border: 1px ${(p) => darken(0.2, p.color)} solid;
  margin: auto;
  width: 240px;
  height: 240px;
  padding: 32px 24px 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: opacity 0.2s ease-out, background-color 0.2s ease-out;

  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  }

  ${(p) =>
    p.isActive
      ? css`
          opacity: 1;
          background-color: ${p.color};
        `
      : css`
          opacity: 0.5;
          cursor: pointer;

          &:hover {
            background-color: ${rgba(p.color, 0.5)};
            opacity: 1;
          }
        `};

  position: relative;
  ${Badge} {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  svg {
    height: 40px;
    width: 40px;
    margin: 8px 0;
  }

  span {
    font-size: 17px;
    font-weight: 600;
    margin: 8px 0;
  }

  aside {
    font-size: 14px;
    line-height: 1.4;
    font-weight: normal;
  }
`;

const Strong = styled.span`
  font-weight: bold;
`;

const UsingWP = styled(Description)`
  text-align: center;
  margin-top: 16px;
`;

const BigWordpress = styled(Wordpress)`
  color: #58466d;
`;

const csmOnlyLangs = ["bg", "nor"]; // langs we don't have micropolicies for yet

const languageOptions = langs
  .filter(({ value }) => !csmOnlyLangs.includes(value))
  .map(({ text, value }) => ({ name: text, value }));

const translatePolicies = (policies, templates, language) => {
  return policies.map((policy) => {
    const { name, description, isTranslationOf, icon } =
      templates.find(
        (tpl) =>
          tpl.isTranslationOf === policy.id &&
          (tpl.lang === language || (language === "cmn" && tpl.lang === "cnm"))
      ) || policy;

    return {
      ...policy,
      name,
      description,
      icon,
      isTranslationOf,
    };
  });
};

const LabelLink = styled(Label).attrs({
  as: "a",
  target: "_blank",
  rel: "noopener nofollow",
})`
  text-decoration: none;
  margin-bottom: 0;
`;

const ProviderLink = ({ name, websiteUrl }) =>
  websiteUrl ? <LabelLink href={websiteUrl}>{name} ↗</LabelLink> : name;

const Onboarding = ({ location, history }) => {
  const params = new URLSearchParams(location.search);
  const domain = params.get("domain");
  const [brand] = useBrandScan(domain);
  const [colors, setColors] = useState([]);
  const {
    providers,
    providerAssignments,
    assignProvider,
    previewPolicies,
    scanLoading,
  } = useOnboarding(domain);
  // Handles the return from Stripe, or re-sends to stripe if needed
  usePlans({
    returnUrl: `${window.location.origin}/onboarding?domain=${domain}`,
  });
  const [saveAutoblockingEnabled] = useAutoblockingEnabledMutation();

  const isWordpress = window.localStorage.isWordpress;
  const defaultAppearance = {
    color: "#58466D",
    position: "bottom-left",
    language: "en",
  };

  const [translatedTemplates] = useTemplates();
  const { grantPermission } = useChat();
  const [stepNum, setStepNum] = useState(0);
  const [appearance, setAppearanceValue] = useValues(defaultAppearance);

  const templateOptions = translatedTemplates
    .filter(({ id }) => !id.includes("essential-cookie"))
    .filter(
      ({ lang }) =>
        lang === appearance.language ||
        (appearance.language === "cmn" && lang === "cnm")
    )
    .map(({ isTranslationOf, name, icon }) => ({
      id: isTranslationOf,
      name,
      value: isTranslationOf,
      icon: icons[icon] || icons.essential,
    }));

  const [isAutoblockingEnabled, setAutoblockingEnabled] = useState(true);
  const [createPolicies] = useCreatePolicies(true);
  const { setAppearance } = useAppearance();
  const { selectedProject } = useContext(SelectedProjectContext);
  const clientId = selectedProject && selectedProject.id;

  const isCookieFree = !scanLoading && providers.length === 0;
  const hasUncategorisedProviders =
    providers.length > 0 &&
    Object.keys(providerAssignments).length < Object.keys(providers).length;

  useEffect(() => {
    //eslint-disable-next-line no-var, no-unused-vars
    const [_, position] = appearance.position.split("-");
    if (window.Intercom) {
      window.Intercom("update", {
        alignment: position === "right" ? "left" : "right",
      });
    }
  }, [appearance.position]);

  useEffect(() => {
    if (isWordpress && stepNum === 3) {
      setTimeout(() => {
        sendBackToWp({ projectId: clientId });
      }, 3000);
    }
  }, [stepNum, clientId, isWordpress]);

  useEffect(() => {
    if (brand.colors) {
      const colors = brand.colors.map(({ r, g, b }) => rgb(r, g, b));
      setColors(colors);
      if (appearance.color === defaultAppearance.color && colors[0]) {
        setAppearanceValue("color", colors[0]);
      }
    }
  }, [brand]);

  if (!domain) {
    return <Redirect to="/account/scan" />;
  }

  let height = undefined;

  const providerCount = scanLoading ? 4.5 : providers.length;

  if (stepNum === 1) {
    height = 368 + providerCount * 55;
  }
  if (stepNum === 2 || stepNum === 3) {
    height = 740;
  }

  return (
    <OnboardingContainer>
      <Header>
        <StyledLogo />
        <ProgressIndicator
          className="ProgressIndicator"
          steps={stepNames}
          currentStep={stepNum + 1}
        />
      </Header>
      <Content>
        <StepsContainer style={{ height }}>
          <div data-active={stepNum === 0 || undefined}>
            <StepContainer>
              <ScanningGate loading={scanLoading}>
                <IconContainer>
                  <BigCheck />
                </IconContainer>
                <Heading>Your scan is complete</Heading>
                <HR />
                <Description>
                  Let's get you set up with your very own Cookie Widget.
                </Description>
              </ScanningGate>
              <Form>
                <InputGroup>
                  <Label>Color</Label>
                  <ColorList
                    options={colors} // last 5 colors
                    value={appearance.color}
                    maxNum={5}
                    onChange={(value) => setAppearanceValue("color", value)}
                  />
                </InputGroup>
                <InputGroup>
                  <Label>Position</Label>
                  <Select
                    style={{ minWidth: 157 }}
                    options={[
                      { name: "Bottom Left", value: "bottom-left" },
                      { name: "Bottom Right", value: "bottom-right" },
                    ]}
                    value={appearance.position}
                    onChange={(e) =>
                      setAppearanceValue("position", e.currentTarget.value)
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <Label>Language</Label>
                  <Select
                    options={languageOptions}
                    value={appearance.language}
                    onChange={(e) =>
                      setAppearanceValue("language", e.currentTarget.value)
                    }
                  />
                </InputGroup>
              </Form>
              <StepFooter>
                <ButtonContainer>
                  <NextButton
                    onClick={async (e, { animationPromise }) => {
                      await Promise.all([
                        animationPromise,
                        setAppearance({
                          ...appearance,
                          color: undefined,
                          // CSM uses colorPrimary not color
                          colorPrimary: appearance.color,
                        }),
                      ]);
                      setStepNum(1);
                    }}
                  >
                    Save{!scanLoading && " and see results"}
                  </NextButton>
                </ButtonContainer>
                <BackButton onClick={() => history.push("/account/scan")}>
                  {"<-"} Back
                </BackButton>
              </StepFooter>
            </StepContainer>
          </div>
          <div data-active={stepNum === 1 || undefined}>
            <StepContainer>
              <ScanningGate loading={scanLoading}>
                <IconContainer>
                  <BigCheck />
                </IconContainer>
                <Heading>Categorise scan results</Heading>
                <HR />
                <Description>
                  {isCookieFree ? (
                    <span>
                      Our scan didn’t find any cookies, so we've created a
                      simple Micropolicy for you.
                    </span>
                  ) : (
                    <span>
                      We’ve automatically categorised some of your scan results
                      into Micropolicies.
                    </span>
                  )}
                  <br />A <strong>Micropolicy</strong> is a granular use-case
                  for data, and is an agreement between you and your users about
                  how, where, and why you collect and process their data.
                  <br />
                </Description>
              </ScanningGate>
              <Form>
                {providers.map((provider) => (
                  <InputGroup key={provider.id}>
                    <Label>
                      <ProviderLink {...provider} />
                    </Label>
                    <Select
                      hasIcons
                      placeholderIcon={icons.unknown}
                      placeholder="Select"
                      showPlaceholder
                      variant="green-on-value"
                      options={templateOptions}
                      value={providerAssignments[provider.id]}
                      onChange={(e) =>
                        assignProvider(provider.id, e.currentTarget.value)
                      }
                    />
                  </InputGroup>
                ))}
                {scanLoading &&
                  [null, null, null, null, null].map((_, i) => (
                    <InputGroup key={i}>
                      <Label>
                        <Shimmer>really long company name</Shimmer>
                      </Label>
                      <Shimmer>
                        <Select
                          variant="green-on-value"
                          hasIcons
                          placeholderIcon={icons.unknown}
                          placeholder="Select"
                          showPlaceholder
                          options={[]}
                        />
                      </Shimmer>
                    </InputGroup>
                  ))}
              </Form>
              <StepFooter>
                <ButtonContainer>
                  <NextButton
                    disabled={
                      scanLoading ||
                      (!isCookieFree && hasUncategorisedProviders)
                    }
                    onClick={async (e, { animationPromise }) => {
                      await Promise.all([
                        createPolicies(
                          translatePolicies(
                            previewPolicies,
                            translatedTemplates,
                            appearance.language
                          )
                        ),
                        animationPromise,
                      ]);
                      setStepNum(2);
                    }}
                  >
                    {isCookieFree ? "I understand" : "Confirm"}
                  </NextButton>
                </ButtonContainer>
                <BackButton onClick={() => setStepNum(0)}>
                  {"<-"} Back
                </BackButton>
              </StepFooter>
            </StepContainer>
          </div>
          <div data-active={stepNum === 2 || undefined}>
            <StepContainer>
              <IconContainer>
                <BigCode />
              </IconContainer>
              <Heading>Block Third Party Scripts</Heading>
              <HR />
              <Description center>
                Prevent third party scripts from running until your users give
                their consent.
              </Description>
              <Flex full center style={{ flex: 2 }}>
                <BigButton
                  color="#F5F3F7"
                  isActive={!isAutoblockingEnabled}
                  onClick={() => setAutoblockingEnabled(false)}
                >
                  <BigLaptopCode />
                  <span>Manual Script Blocking</span>
                  <aside>
                    Make small changes to your code to block scripts.
                  </aside>
                </BigButton>
                <BigButton
                  color="#f3f7f3"
                  isActive={isAutoblockingEnabled}
                  onClick={() => setAutoblockingEnabled(true)}
                >
                  <BigMagic />
                  <span>Autoblocking</span>
                  <Badge type="positive">Recommended</Badge>
                  <aside>
                    Let Confirmic find and block scripts automatically.
                  </aside>
                </BigButton>
              </Flex>
              {isAutoblockingEnabled ? (
                <Alert type="info" style={{ marginTop: 10 }}>
                  Autoblocking is still in beta, and may affect the
                  functionality of your website. You can turn off autoblocking
                  for specific scripts later.
                </Alert>
              ) : (
                <Alert type="warn" style={{ marginTop: 10 }}>
                  In order to respect your users’ choices, you’ll need to
                  manually block any third party scripts. Visit{" "}
                  <a
                    href="https://docs.confirmic.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    our documentation
                  </a>
                  ↗ to learn how.
                </Alert>
              )}
              <StepFooter>
                <ButtonContainer>
                  <NextButton
                    onClick={async (e, { animationPromise }) => {
                      await Promise.all([
                        saveAutoblockingEnabled(isAutoblockingEnabled),
                        animationPromise,
                      ]);
                      setStepNum(3);
                    }}
                  >
                    Save
                  </NextButton>
                </ButtonContainer>
                <BackButton onClick={() => setStepNum(isCookieFree ? 0 : 1)}>
                  {"<-"} Back
                </BackButton>
              </StepFooter>
            </StepContainer>
          </div>
          <div data-active={stepNum === 3 || undefined}>
            <StepContainer>
              <IconContainer>
                {isWordpress ? <BigWordpress /> : <BigClipboard />}
              </IconContainer>
              <Heading>Install Cookie Widget</Heading>
              <HR />
              {isWordpress ? (
                <>
                  <Description center style={{ marginTop: 16 }}>
                    Sending you back to Wordpress to complete installation...
                  </Description>
                </>
              ) : (
                <>
                  <UsingWP>
                    Using Wordpress?{" "}
                    <a
                      href="https://wordpress.org/plugins/confirmic-for-cookies/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Install the plugin
                    </a>
                  </UsingWP>
                  <Description style={{ marginTop: 16 }}>
                    <Strong>1.</Strong> Copy and paste the following code into
                    the <Strong>first line of your {"<head>"} tag.</Strong>
                  </Description>

                  <Snippet clientId={clientId} />
                  {isAutoblockingEnabled && (
                    <Alert type="warn" style={{ margin: "12px 0" }}>
                      For Autoblocking to work correctly, this must be the first
                      script in the {"<head>"} tag of your page.
                    </Alert>
                  )}
                  <Description style={{ marginTop: 16 }}>
                    <Strong>2.</Strong> Paste the following code where you want
                    your users to manage their cookies.
                  </Description>
                  <ReraiseSnippet />

                  <StepFooter>
                    <ButtonContainer>
                      <NextButton
                        onClick={async (e, { animationPromise }) => {
                          await animationPromise;
                          history.push("/dashboard");
                        }}
                      >
                        I’ve done this
                      </NextButton>
                    </ButtonContainer>
                    <BackButton onClick={() => setStepNum(2)}>
                      {"<-"} Back
                    </BackButton>
                  </StepFooter>
                </>
              )}
            </StepContainer>
          </div>
        </StepsContainer>
      </Content>
      <Footer>
        <a
          target="_blank"
          href="https://confirmic.com/privacy"
          style={{ textDecoration: "none" }}
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        {/* <a href="#" onClick={grantPermission} style={{ cursor: "pointer" }}>
          Contact (uses cookies)
        </a>
        <a>&copy; Confirmic 2020</a> */}
      </Footer>
      <HideButton />
      <CSMPreview
        mode="onboarding"
        policies={
          stepNum > 0
            ? translatePolicies(
                previewPolicies,
                translatedTemplates,
                appearance.language
              )
            : []
        }
        pathname={stepNum > 0 ? "/policy-list" : "/prompt"}
        appearance={{
          position: appearance.position,
          colorPrimary: appearance.color,
          language: appearance.language,
          isV2: true,
        }}
      />
    </OnboardingContainer>
  );
};

export default withRouter(Onboarding);
