import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'

import itemsToProviders from './itemsToProviders'

const queryGetScanResults = gql`
	query getScanResults($domain: String!, $after: Date) {
			Scan {
				results(domain:$domain, after:$after)
			}
	}
`

const useProviderScan = ({ domain, after }) => {
	const [providers, setProviders] = useState([])
	const [isReady, setIsReady] = useState(false)
	const [getScanResults, { error, data, stopPolling }] = useLazyQuery(queryGetScanResults, { pollInterval: 1500, variables: { domain, after } })

	useEffect(() => {
		if (domain) getScanResults();
	}, [domain, getScanResults])

	useEffect(() => {
		if (data) {
			const { Scan: { results: { results: items, status } } } = data
			if (status !== 'LOADING'){
				stopPolling()
				setIsReady(true)
				let providers = itemsToProviders(items)
				providers = Object.values(providers).sort((a,b) => {
					if (a.templateId) {
						return 1
					}
					if (b.templateId) {
						return -1
					}
					return 0
				})
				setProviders(providers)
			}
		}
	}, [data, stopPolling])

	return {providers, loading: !isReady, error}
}

export default useProviderScan