import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import Auth from '../../utils/Auth'

const LoggedInGate = ({ component: Component, loading, me, ...rest }) => (
	<Route {...rest} render={props => {
    const isAuthed = Auth.isAuthenticated()
    return isAuthed
      ? <Component {...props} />
      : <Redirect to={{pathname: '/account', state: {from: props.location}}} />
  }}/>
)
export default LoggedInGate
