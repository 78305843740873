import React from "react";
import Immutable from "immutable";

import styles from "./Stats.module.css";
import SimpleAlert from "Components/SimpleAlert";

import NavBar from "../../../../Components/NavBar";
import Banner from "../../../../Components/Banner";

import Box from "../../../../Components/Box";

const StatBox = ({ color, count, name }) => (
  <div className={styles.statbox}>
    <span className={styles.count}>{count && count.toLocaleString()}</span>
    <span className={styles.name} style={{ color }}>
      {name}
    </span>
  </div>
);

const Stats = ({ stats, policies, email, loading }) => {
  const showFirstUse =
    !loading && stats.getIn(["summary", "totalConsentedAllTime"], null) === 0;

  return (
    <div className={styles.mainContainer}>
      <NavBar />
      {/* <SimpleAlert email={email} /> */}

      <div className={styles.container}>
        {showFirstUse && (
          <Banner type="warning">
            <p>
              You haven’t yet configured the Cookie Widget to work on your site.
              To begin collecting statistics on policy acceptance rates and to
              block tracking scripts, head over to our{" "}
              <a href="https://docs.confirmic.com">Documentation</a>.
            </p>
          </Banner>
        )}

        <div className={styles.statBoxes}>
          <StatBox
            color="#E89668"
            count={stats.getIn(["summary", "totalConsentedAllTime"], 0)}
            name="All time accepts"
          />
          <StatBox
            color="#5FCF91"
            count={stats.getIn(["summary", "totalConsented"], 0)}
            name="Opt-ins"
          />
          <StatBox
            color="#EB4967"
            count={stats.getIn(["summary", "totalDeclined"], 0)}
            name="Opt-outs"
          />
        </div>

        <Box
          title="Consent statistics"
          inputs={[
            { label: "Policy Name", key: "name" },
            { label: "Opt-ins", key: "optinsPc", rightAlign: true },
            { label: "Opt-outs", key: "optoutsPc", rightAlign: true },
            { label: "Total opted in", key: "total", rightAlign: true },
          ]}
          defaultValues={stats
            .get("entries", Immutable.List())
            .toJS()
            .filter(({ policyId }) => !policyId.includes("synth"))
            .map(
              ({ policyId, policyVersion, totalConsented, totalDeclined }) => {
                const policy =
                  policies && policies.find((policy) => policy.id === policyId);
                const policyName = policy ? policy.name : " ";

                return {
                  name: {
                    to: `/dashboard/policies/${policyId}`,
                    text: `${policyName} v${policyVersion}`,
                  },
                  optinsPc:
                    (
                      (totalConsented * 100) /
                      (totalConsented + totalDeclined)
                    ).toFixed(0) + "%",
                  optoutsPc:
                    (
                      (totalDeclined * 100) /
                      (totalConsented + totalDeclined)
                    ).toFixed(0) + "%",
                  total: totalConsented,
                };
              }
            )}
          table
          readOnly
          className={styles.box}
        />
      </div>
    </div>
  );
};

export default Stats;
