import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import useValues from '../hooks/useValues'
import useSurvey from '../hooks/useSurvey'

import { Title, HR } from '../../../NewComponents/Box'
import { InputGroup, Label } from '../../../NewComponents/Form'
import { ButtonWithIcon, TextButton } from '../../../NewComponents/Form/Button'

import Select from '../components/Select'
import { Plans } from 'hooks/usePlans'
import Input from 'NewComponents/Form/Input'

const TextButtonNoClick = styled(TextButton).attrs({ as: 'p' })`
	cursor: default;
`

const Flex = styled.div`
  display: flex;
`


const Survey = ({ name, onSubmit, history, emailAddress, tier, updateProjectPlan, isUpdatingPlan, domain, params, ...props }) => {
	const gclid = params.get('gclid');
	const utm_source = params.get('utm_source');
	const utm_medium = params.get('utm_medium');
	const utm_term = params.get('utm_term');
	const utm_campaign = params.get('utm_campaign');
	const utm_content = params.get('utm_content');
	const employees = params.get('company_size');
	const role = params.get('company_size');
	const [values, setValue] = useValues({
		...(employees && {employees}),
		...(role && { role: role.split(',')[0]}),
	})
	const setSurvey = useSurvey()

	const isFree = !tier || tier === Plans.community.key
	let [isEitherChecked, setIsEitherChecked] = useValues(false)

	return (
		<div {...props} >
			<Title>Hi{name ? `, ${name}` : ' There'}!</Title>
			<HR />
			<form style={{ flex: 1 }} onSubmit={e => {
				e.preventDefault()
			}}>
				<InputGroup>
					<Label>What’s your role?</Label>
					<Select
						variant="large"
						options={[
              'Agency',
							'Design',
							'Data Protection Office (DPO)',
							'Engineering',
							'Founder / CEO',
							'Legal / Compliance',
							'Marketing',
							'Operations',
							'Product',
							'Sales',
							'Security',
							'Other',
						]}
						onChange={e => setValue('role', e.currentTarget.value)}
						value={values.role}
					/>
				</InputGroup>

				<InputGroup>
					<Label>How many employees does your company have?</Label>
					<Select
						variant="large"
						options={[
							'1',
							'2 - 10',
							'11 - 50',
							'51 - 100',
							'101 - 250',
							'251 - 500',
							'501 - 1,000',
							'1,000+'
						]}
						onChange={e => setValue('employees', e.currentTarget.value)}
						value={values.employees}
					/>
				</InputGroup>

				<InputGroup>
					<Label>How did you find Confirmic?</Label>
					<Select
						variant="large"
						options={[
							'Ad',
							'BetaList',
							'Blog post',
							'Hacker news',
							'Indie Hackers',
							'Product Hunt',
							'Referral',
							'Saw on another website',
							'Search Engine',
							'Social networks',
							'Other',
						]}
						onChange={e => setValue('foundVia', e.currentTarget.value)}
						value={values.foundVia}
					/>
				</InputGroup>
        <InputGroup>
          <Label>Do you already have a cookie widget or banner?</Label>
          <Flex style={{marginTop: "16px"}}>
            <Flex style={{width: "20%", alignItems: 'center'}}>
              <input  id="yes-cookie-widget" type="radio" checked={isEitherChecked && values.hasCookieWidget} onChange={() => { setIsEitherChecked(true); setValue('hasCookieWidget', true) }} />
              <Label style={{margin: "0 8px"}} htmlFor="yes-cookie-widget">Yes</Label>
            </Flex>
            <Flex style={{width: "20%", alignItems: 'center'}}>
              <input id="no-cookie-widget" type="radio" checked={isEitherChecked && !values.hasCookieWidget} onChange={() => { setIsEitherChecked(true); setValue('hasCookieWidget', false) }} />
              <Label htmlFor="no-cookie-widget" style={{margin: "0 8px"}}>No</Label>
            </Flex>
          </Flex>
        </InputGroup>
        <InputGroup>
          <Label>What prompted you to look for {values.hasCookieWidget && 'another '}one?</Label>
					<Input disabled={!isEitherChecked} tabIndex={1}
						placeholder={isEitherChecked ? (values.hasCookieWidget  ? "Because my current one is…" : "Because I need to…"): "Please select Yes or No"}
						type="text" onChange={e => setValue('cookieReasonText', e.target.value)} />
        </InputGroup>
				<InputGroup style={{ marginTop: 37 }}>
					<ButtonWithIcon
						disabled={isUpdatingPlan}
						icon="->"
						onClick={async (e, { animationPromise }) => {
						await Promise.all([
							setSurvey({ responses:
								{
									...values,
									...(gclid && { gclid }),
									...(utm_source && { utm_source }),
									...(utm_medium && { utm_medium }),
									...(utm_term && { utm_term }),
									...(utm_campaign && { utm_campaign }),
									...(utm_content && { utm_content }),
								},
							}),
							animationPromise,
						])

						if (!isFree) {
							updateProjectPlan(Plans[tier])
						} else if (domain) {
							history.push(`/onboarding?domain=${domain}`)
						} else {
							history.push('/account/scan')
						}
					}}>
						{Object.keys(values).length === 0 ? 'Skip' : 'Continue'} to {isFree ? 'scan' : 'checkout'}
					</ButtonWithIcon>
				</InputGroup>
			</form>


			<InputGroup>
				<TextButtonNoClick>
					This info helps us make the product better.
				</TextButtonNoClick>
			</InputGroup>
		</div>
	)
}

export default withRouter(Survey)
