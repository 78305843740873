import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const surveyMutation = gql`
    mutation survey(
        $responses: JSON!,
    ) {
        Account {
            setSurveyResponses(responses:$responses)
        }
    }
`

const useSurvey = () => {
	const [mutate] = useMutation(surveyMutation)

	return variables => mutate({
		variables,
	})
}

export default useSurvey