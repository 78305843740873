import {Route} from 'react-router-dom'
import React, {useContext, useEffect } from 'react'
import TrackingContext from 'contexts/TrackingContext';

export default function TrackedRoute({ path, ...props }) {
  const { trackPageView } = useContext(TrackingContext);
  useEffect(() => {
    trackPageView(path)
  }, [trackPageView, path]);
  return <Route path={path} {...props} />
}