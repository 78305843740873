import React from 'react'
import styles from './NavBar.module.css'
import { matchPath } from 'react-router'
import { NavLink, Link } from 'react-router-dom'

import routes from '../../routes/routes'

const root = '/dashboard'

const routeIsCurrent = (route, match) => (
	matchPath(root + route.path, match) || (route.isActive && route.isActive(match.path))
)

const getCurrentRoute = match => {
	const currentRoute = routes.find(route => {
		return matchPath(route, match) || (route.children && route.children.find(route => routeIsCurrent(route, match)))
	})

	return currentRoute
}

const NavBar = ({ match, topRightComponent, subpage }) => {
	const currentRoute = getCurrentRoute(match)

	return (
		<div className={styles.container}>
			{currentRoute && currentRoute.children && currentRoute.children.map(link => (
				link.path.includes('http') ? (
					<a key={link.text} href={link.path} className={styles.text} target="_blank" rel="noopener noreferrer">{link.text} ↗</a>
				) : (
					<React.Fragment key={link.text}>
						<NavLink to={root + link.path} className={styles.text} exact activeClassName={styles.activeText}>{link.text}</NavLink>
						{subpage && <span className={styles.subpage}> / <Link to="." style={{ color: '#EB4967' }}>{subpage}</Link></span>}
					</React.Fragment>
				)
			))}

			{topRightComponent && (
				<div className={styles.topRightComponent}>
					{topRightComponent}
				</div>
			)}
		</div>
	)
}

export default NavBar
