import React, { useRef, useEffect } from "react"

export default ({children,onClickOutside}) => {
  const ref = useRef(null)
  
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside()
    }
  }
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  
  return <div ref={ref}>{children}</div>;
}
