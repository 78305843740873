import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { middleware as asynchMiddleware } from "redux-asynch-middleware";
import { connectRouter, routerMiddleware } from "connected-react-router";
// import {bindGooseToSagaMiddleware,loadModule,gooseMiddleware} from '@confirmic/goose-module'
import createSagaMiddleware from "redux-saga";
import { updateAt } from "fn-update";

import history from "./history";

const chainReducers = (rs) => (state, action) =>
  rs.reduce((st, r) => r(st, action), state);

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const routerReducer = connectRouter(history);

  // Can't use combineReducers because it gets in a hissy fit about other reducers in the tree. So we'll apply it ourselves
  const mountedRouterReducer = (state, action) =>
    updateAt("router", (router) => routerReducer(router, action))(state);

  // Apply all reducer enhancements / plugins. At time of writing, this is just connect-react-router
  const enhancedReducer = (reducer) =>
    chainReducers([mountedRouterReducer, reducer]);

  const store = createStore(
    enhancedReducer((x) => x), // although the dummy reducer gets replaced in the next tick, it's possible for a history action to occur synchronously. So - we have to at least provide the routerReducer here, hence the enhancement
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        asynchMiddleware,
        sagaMiddleware,
        // gooseMiddleware,
        routerMiddleware(history)
      )
    )
  );

  // Kick off the root module
  // bindGooseToSagaMiddleware(sagaMiddleware,(newReducer) => (
  //   store.replaceReducer(enhancedReducer(newReducer))
  // ))
  // store.dispatch(loadModule(RootModule))

  return store;
}

export default configureStore;
