import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'

const endpointIsVisibleQuery = gql`
	query endpointIsVisible($endpoint:String!) {
        Scan {
            endpointIsVisible(endpoint:$endpoint)
        }
    }
`

const useCheckUrlVisibility = () => {
    const client = useApolloClient()

    const endpointIsVisible = (endpoint) => (
        client.query({
            query: endpointIsVisibleQuery,
            variables: { endpoint },
        }).then(({ data }) => data.Scan.endpointIsVisible)
    )

    return [
        endpointIsVisible,
    ]
}

export default useCheckUrlVisibility