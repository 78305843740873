import React from "react";
import Alert from "NewComponents/Alert";
import styled, { keyframes } from "styled-components";
import Flex from "styled-flex-component";
import { Cross } from "Components/Icons/Icons.js";
import { SimpleContext } from "contexts/SimpleContext";

const bounce = keyframes`
  from {
    transform: translateY(-100%);
  }
  20%, 53%, 80%, to {
    transform: translateY(0px);
  }

  40%, 43% {
    transform: translateY( -50%);
  }

  70% {
    transform: translateY( -25%);
  }

  90% {
    transform: translateY(-10%);
  }
`;

const StyledAlert = styled(Alert).attrs({
  type: "warn",
  icon: (
    <span role="img" aria-label="Worried face">
      😟
    </span>
  ),
})`
  animation: ${bounce} 1s linear 1 both 0.5s;
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-size: 16px;
  box-shadow: 0px 4px 7px rgba(99, 88, 111, 0.12);

  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.7;
  }

  [role="img"] {
    height: 20px;
    width: 20px;
    margin: 16px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }

  button {
    margin-left: auto;
    border: 0;
    background: 0;
    cursor: pointer;
    padding: 12px;
    border-radius: 100%;
    opacity: 0.5;

    svg path {
      fill: black;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
  }
`;

const LOCAL_STORAGE_KEY = "simple-banner-count";
const MAX_SHOWS = 3;

const SHOWS_LEFT = parseInt(
  window.localStorage.getItem(LOCAL_STORAGE_KEY) ?? MAX_SHOWS,
  10
);

export default function SimpleAlert({ email }) {
  const [hasShownForSession, setHasShownForSession] = React.useContext(
    SimpleContext
  );
  const [hasShown, setHasShown] = React.useState(false);
  let numShowsRemaining = SHOWS_LEFT;
  const canShow = email && !hasShownForSession && numShowsRemaining > 0;

  React.useEffect(() => {
    return () => {
      if (!hasShownForSession) {
        setHasShownForSession(true);
      }
    };
  }, [hasShownForSession, setHasShownForSession]);

  React.useEffect(() => {
    if (!hasShown && canShow) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY,
        Math.max(0, numShowsRemaining - 1)
      );
      setHasShown(true);
    }
  }, [hasShown, canShow, numShowsRemaining]);

  const close = () => {
    setHasShownForSession(true);
    window.localStorage.setItem(LOCAL_STORAGE_KEY, 0);
  };

  if (!canShow) return null;
  return (
    <StyledAlert>
      <Flex column>
        <h4>Worried about all that data you‘re collecting?</h4>
        <span>
          <a
            href={`https://confirmiclabs.typeform.com/to/lVp6uI?utm_source=dashboard&email=${email}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={close}
          >
            Let us solve your privacy problems
          </a>{" "}
          so you can get back to building your business.
        </span>
      </Flex>
      <button onClick={close}>
        <Cross />
      </button>
    </StyledAlert>
  );
}
