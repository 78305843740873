import React, { useEffect } from "react";
import Button from "../Button";
import styles from "./ChatWithUs.module.css";
import { icons } from "../PolicyHeader";
import useChat from "./useChat";

export const HideChat = () => {
  useEffect(() => {
    document.body.classList.add("hide-chat");
    return function () {
      document.body.classList.remove("hide-chat");
    };
  }, []);
  return null;
};

export const HideButton = () => {
  useEffect(() => {
    document.body.classList.add("hide-chat-button");
    return function () {
      document.body.classList.remove("hide-chat-button");
    };
  }, []);
  return null;
};

export default () => {
  const { hasPermission, grantPermission, canChat } = useChat();

  return null;
  
  return !hasPermission && canChat ? (
    <div className={styles.chatWithUsContainer}>
      <Button className={styles.chatWithUs} onClick={grantPermission}>
        <div>
          <div className={styles.topline}>
            <icons.chat width={16} height={16} />
            <span>Chat with us</span>
          </div>
          <i>(uses cookies <span role="img" aria-label="Cookie emoji">🍪</span>)</i>
        </div>
      </Button>
    </div>
  ) : null;
};
