import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const brandQuery = gql`
    query brand($domain:String!) {
        Scan {
            brand(domain:$domain) {
                colors {
					r
					g
					b
				}
            }
        }
    }
`

const useBrand = () => {
	const [scanBrand, { loading, error, data }] = useLazyQuery(brandQuery)

	let brand
	if (data) {
		brand = data.Scan.brand
	}

	return [{loading, error, brand}, (domain) => scanBrand({ variables: { domain } })]
}

export default useBrand