import React from "react";

export const SimpleContext = React.createContext([]);


export default function SimpleContextProvider({ children }) {
  const simpleBannerState = React.useState(false);

  return (
    <SimpleContext.Provider value={simpleBannerState}>
      {children}
    </SimpleContext.Provider>
  );
}
