import {useCallback,useMemo } from "react"

import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { updates, getAt } from 'fn-update'

const sortProjects = (val) => val && val.sort(({name: nameA}, {name: nameB}) => nameA > nameB)

// Lens Path
const getDefaultProject = getAt(["Organisation", "get", "defaultProject"])
const getProjects = getAt(["Organisation", "get", "projects"])
const mapDataToProjects = (data) => ({
  projects: sortProjects(getProjects(data)),
  defaultProject: getDefaultProject(data),
})

export const queryGetProjectsList = gql`
	query getProjectList {
		Organisation {
      get {
        id
        defaultProject {
          id
          name
          primaryDomain
          plan
        }
        projects {
          id
          name
          primaryDomain
          plan 
        }
      }
		}
	}
`

const useProjectsList = () => {
  const { loading, error, data, refetch } = useQuery(queryGetProjectsList)
  const projectData = useMemo(() => mapDataToProjects(data), [data])
  const memoRefetch = useCallback((...args) =>
    refetch(...args).then(updates({
      data: mapDataToProjects,
    })
  ), [refetch])

	return {
    loading,
    error,
    refetch: memoRefetch,
    ...projectData,
  }
}

export default useProjectsList
