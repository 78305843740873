import React, { useState, useContext } from 'react'
import Immutable from 'immutable'
import { Switch, Redirect } from 'react-router-dom'
import {getAt,map,updateAt,updates, ops} from 'fn-update'
import Flex from 'styled-flex-component'

import TrackedRoute from 'NewComponents/TrackedRoute'

import styles from './Policy.module.css'
import logger from '../../../../utils/logger'

import Button from '../../../../Components/Button'
import Box from '../../../../Components/Box'
import History from '../../../../Components/History'
import CSMPreview from '../../../../Components/CSMPreview'

import NavBar from '../../../../Components/NavBar'
import { DraftBadge } from '../../../../Components/PolicyHeader'
import PolicyNav from './PolicyNav'
import DeletePolicy from 'routes/Dashboard/Policies/Policy/DeletePolicy'
import TrackingContext, {EventCategories} from 'contexts/TrackingContext';
import {SelectedProjectContext} from 'contexts/ProjectContext'

// FIXME: Hotfix. This shouldn't be here.
// Usages must contain datapoints otherwise preview breaks,
// Usages must not contain datapoints otherwise api breaks.
const removeDatapointsFromUsages = updates({
	usages: map(updateAt('datapoint'), ops.delete),
	providers: map(updateAt('__typename'), ops.delete)
})

const Policy = ({ policy, updatePolicy, datapoints, createDatapoint, publishPolicy, archivePolicy, providers = [], appearance, match: { path } }) => {
	const {selectedProject} = useContext(SelectedProjectContext)
	const [pendingChanges, setPendingChanges] = useState(Immutable.Map({}))
	const [isComplete, setIsComplete] = useState(false)
	const [draftIsComplete, setDraftIsComplete] = useState(false)
	const [isError, setIsError] = useState(false)
	const [draftIsError, setDraftIsError] = useState(false)
	const [isPending, setIsPending] = useState(false)
	const { trackClick, track } = useContext(TrackingContext)

	// Note: null !== undefined. Null means not found, undefined means not fetched.
	if (policy === null) return <Redirect to='/dashboard/policies' />;

	const fullPolicy = Immutable.Map({}).merge(policy || {}).merge(pendingChanges).toJS()

	if (fullPolicy?.usages) {
		fullPolicy.usages = fullPolicy.usages.map(u => {
			if (u.datapointId) {
				return {
					...u,
					datapoint: datapoints.find(d => d.id === u.datapointId),
				}
			}

			return u
		})
	}

	if (fullPolicy?.providers) {
		fullPolicy.providers = fullPolicy.providers.map(u => {
			if (u.providerId) {
				return {
					...u,
					provider: providers.find(p => p.id === u.providerId),
				}
			}

			return u
		})
	}

	const hasChanges = !pendingChanges.isEmpty()
	const isPublished = policy?.version?.includes('0.0') ?? true // zero Minor

	const btn = (
		<Flex>
				<Button onClick={() => {
					setDraftIsComplete(false)
					setIsPending(true)
					setDraftIsError(false)
						updatePolicy({
							id: policy.id,
							...removeDatapointsFromUsages(pendingChanges.toJS()),
						}).then(() => {
							setDraftIsComplete(true)
							setIsPending(false)
							setTimeout(() => {
								setPendingChanges(Immutable.Map({}))
								setDraftIsComplete(false)
							}, 500)
						}).catch(e => {
							setIsPending(false)
							setDraftIsError(e)
							logger.error(e)
						})
						trackClick('Save changes', path, { eventName: fullPolicy.slug })
				}}
				type="dark"
				size="large"
				complete={draftIsComplete}
				error={draftIsError}
				disabled={isPending || !hasChanges}
				style={{ maxWidth: 180, minWidth: 180, height: 50 }}>Save Draft</Button>

				<Button
					onClick={() => {
						setIsPending(true)
						setIsComplete(false)
						setIsError(false)
						updatePolicy({
							id: policy.id,
							...removeDatapointsFromUsages(pendingChanges.toJS()),
						}).then(() => publishPolicy(policy.id, selectedProject.id))
							.then(() => {
								setIsComplete(true)
								setIsPending(false)
								setTimeout(() => {
									setPendingChanges(Immutable.Map({}))
									setIsComplete(false)
								}, 500)
							}).catch(e => {
								setIsPending(false)
								setIsError(e)
								logger.error(e)
							})
						trackClick('Publish changes', path, { eventName: fullPolicy.slug })
					}}
					size="large"
					type="primary"
					verb="publish"
					verbPastTense="published"
					complete={isComplete} error={isError}
					disabled={isPending || (!hasChanges && isPublished)}
					style={{ maxWidth: 180, minWidth: 180, height: 50}}
				>Publish</Button>
		</Flex>
	)

	return (
		<div className={styles.mainContainer}>
			<NavBar
				subpage={<>{policy?.name} {!isPublished && <DraftBadge id={policy?.id} />}</>}
				topRightComponent={btn}
			/>
			<div className={styles.container}>

				<div className={styles.gridContainer}>

					<div className={styles.left}>
            <PolicyNav policyId={policy?.id} />
							<Switch>
							<TrackedRoute path="/dashboard/policies/:id" exact render={() => (
								<>
									<Box
										onChange={(changes) => setPendingChanges(Immutable.merge(pendingChanges, changes))}
										inlineInputLabels
										title="Policy Details"
										inputs={[
											{ label: 'Policy Icon', key: 'icon', type: 'icon-picker' },
											{ label: 'Policy Name', placeholder: 'Statistics', key: 'name', description: 'The features or categories of features powered by this policy' },
											{ label: 'Policy Description', placeholder: 'We use statistics to inform us of how you use our site. This lets us make changes and track when things are broken.', description: 'Keep this concise, using plain and simple language. Sell the user on the value they will get by opting in.', key:'description', type: 'textarea' },
										]}
										className={styles.box}
										defaultValues={fullPolicy}
									/>
									<Box
										onChange={(changes) => {
											setPendingChanges(Immutable.merge(pendingChanges, changes))
											// We can assume that only one field was changed
											const eventAction = Object.keys(changes)[0];
											track(EventCategories.SWITCH_TOGGLED, path, { eventAction, eventName: fullPolicy.slug, eventValue: Number(changes[eventAction]) })
										}}
										inlineInputLabels
										title="Policy Settings"
										inputs={[
											{ label: 'slug', key: 'slug', type: 'copyable', description: 'The human-readable id for this policy' },
											{ label: 'Uses cookies', type: 'switch', key: 'usesCookies', description: 'Make sure you enable this if you’re using cookies.' },
											{ label: 'This is an "essential" policy', type: 'switch', key: 'isMandatory', description: 'This will remove the ability for your users to opt-out. Only use this for your essential policy.' },
											{ label: 'Include in Cookie Widget', type: 'switch', key: 'isInitial', description: 'Disable this to remove this policy from the Cookie Widget.' },
										]}
										className={styles.box}
										defaultValues={fullPolicy}
									/>
								</>
							)} />

							<TrackedRoute path="/dashboard/policies/:id/data" exact render={() => (
								<Box
									key="usages"
									title="Data usage"
									description="Define the uses of the data you collect in this policy - your users will see this in the Cookie Widget"
									onChange={(items) => {
										setIsPending(true)
										Promise.all(items.toJS().map(({ datapointName }) => datapointName?.trim()).filter(Boolean).map(datapointName => {
											const dp = datapoints.find(d => d && d.name === datapointName)
											if (dp) return dp

											/** Hotfix bug: createDatapoint does not return the same shape as datapoints.find */
											/** FIXME: Do this properly? I don't think this is the right place for this. */
											return createDatapoint({ name: datapointName })
												.then(getAt(['data','Datapoint','create']))
										})).then((datapoints) => {
											return items.toJS().map(item => {
												const dp = datapoints.find(d => d && d.name === item.datapointName)
												return ({
													id: item.id,
													datapointId: dp && dp.id,
													purpose: item.purpose,
													// FIXME: This shouldn't be necessary here. It seems the different components expect these in different shapes?
													datapoint: dp
												})
											})
										}).then(usages => {
											setIsPending(false)
											setPendingChanges(pendingChanges.set('usages', usages.filter(getAt('datapoint'))))
										})
									}}
									inputs={[
										{ label: 'Datapoint', placeholder: 'Data type', key: 'datapointName', type: 'autocomplete', searchField: 'name', data: datapoints },
										{ label: 'Purpose', placeholder: 'So that we can...', key:'purpose' },
									]}
									table
									noun="datapoint"
									defaultValues={fullPolicy?.usages?.map(u => ({ id: u.id, purpose: u.purpose, datapointName: u.datapoint.name }))} className={styles.box}
								/>
							)} />

							<TrackedRoute path="/dashboard/policies/:id/third-parties" exact render={() => (
								<Box
									title="Third-parties"
									key="third-parties"
									description="Let your users know where their data goes"
									onChange={(items) => {
										const thirdParties = items.toJS().filter(({ name }) => !!name)
										setPendingChanges(pendingChanges.set('providers', thirdParties))
									}}
									inputs={[
										{ label: 'Third party name', placeholder: 'Company', key: 'name', type: 'autocomplete', searchField: 'name', data: providers,
											changesRow: (row) => {
												const provider = providers.find(p => p.name === row.get('name'))
												if (provider) {
													return row.merge({
														privacyUrl: provider?.privacyUrl,
														referenceId: provider?.id,
													})
												}
												return row.set('referenceId',  null)
											}
										},
										{ label: 'Privacy Policy URL', placeholder: 'https://company.com/privacy', key:'privacyUrl' },
									]}
									table
									noun="third-party"
									defaultValues={fullPolicy?.providers}
									className={styles.box}
								/>
							)}
							/>

							<TrackedRoute path="/dashboard/policies/:id/history" exact render={() => (
								<History className={styles.box} history={policy?.history || []} providers={providers} datapoints={datapoints} />
							)} />

							<TrackedRoute path="/dashboard/policies/:id/delete" exact render={() => (
								<DeletePolicy policyId={policy?.id} archivePolicy={archivePolicy}/>
							)} />
						</Switch>
					</div>

					<div className={styles.right}>
            {/* Will come back to fix this one. It's late. */}
						<div className={styles.policyPreviewContainer}>
							<CSMPreview
								className={styles.policyPreview}
								mode="policy"
								policy={fullPolicy}
								appearance={appearance}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Policy
