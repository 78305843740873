import React from 'react'
import styled, { css } from 'styled-components'
import { Check, ExclamationCircle, Exclamation, Cross } from './Icons'

const iconStyle = css`
	height: 14px;
	width: 14px;
	path {
		fill: currentColor;
	}
`

const icons = {
	info: styled(ExclamationCircle)`${iconStyle}`,
	warn: styled(Exclamation)`${iconStyle}`,
	success: styled(Check)`${iconStyle}`,
	error: styled(Cross)`${iconStyle}`,
}

const Wrapper = styled.div`
	font-weight: bold;
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5;
  border-left: 4px #2b6cb0 solid;
	padding: 8px 16px 8px 32px;
	border-radius: 0 4px 4px 0;

	position: relative;
	${Object.values(icons).join(',')} {
		position: absolute;
		left: 12px;
		top: 12px;
	}

	a {
		color: inherit;
	}

	${p => {
		switch(p.type) {
			case 'success':
				return css`
					color: #08AC7A;
					border-color: #08AC7A;
					background: rgba(0, 255,0,0.1);
				`
			case 'warn':
				return css`
					color: #ab6539;
					border-color: #ab6539;
					background: rgba(255, 140, 0, 0.1);
				`
			case 'error':
				return css`
					color: #9a4d4d;
					border-color: #9a4d4d;
					background: rgba(255,0,0,0.1);
				`
			default:
				return css`
					color: #58466D;
					border-color: #58466D;
					background: #F5F3F7;
				`
		}
	}};
`

const Alert = ({ type, children, icon, ...rest}) => {
	const Icon = icons[type]
	const actualIcon = icon || (Icon && <Icon />)
	return (
		<Wrapper type={type} {...rest}>
			{actualIcon}
			{children}
		</Wrapper>
	)
}

export default Alert