import React from "react";
import styled from "styled-components";
import { noop } from "lodash";

/** See comment inside ./ConfirmicConsentManagerShim for explanation */
import "./ConfirmicConsentManagerShim.js";

import {
  ConfirmicProvider,
  ConsentManager,
  Ball,
  Orb,
  CustomizeView,
} from "@confirmic/consent-manager";
import ErrorBoundary from "../ErrorBoundary";
const PolicyPreviewContainer = styled.div`
  position: relative;
  height: 562px;

  /* background: radial-gradient(at bottom left, rgba(49, 49, 49, 0.23) 0%, rgba(196, 196, 196, 0.1) 69%) !important; */
`;

const OnboardingPreviewContainer = styled.div`
  > div {
    /* position: relative !important; */
    /* background: linear-gradient(at bottom, rgba(49, 49, 49, 0.23) 0%, rgba(196, 196, 196, 0) 69%) !important; */
    /* width: calc(100%) !important; */
  }
`;

const defaultTheme = {
  name: "green",

  fontStack:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',

  primaryColor: "#5FCF91",
  secondaryTextColor: "#979797",

  switchDisabledColor: "#CACACA",
  switchHandleColor: "#ffffff",

  pillBackgroundColor: "#f2f2f2",
  pillTextColor: "#5e646e",

  textColor: "#030303",
  titleColor: "#686868",
};

const convertConfigToCSM = ({ initialPolicies, appearance }) => {
  const policies = initialPolicies
    ? initialPolicies
        .filter((p) => !!p)
        .map((policy) => {
          return {
            ...policy,
            isMandatory: policy.type === "INFO",
            requiresCookies: policy.usesCookies,
            dataShared: policy.usages
              ? policy.usages.map((usage) => ({
                  id: usage.id,
                  key: usage.id,
                  name: usage.datapoint.name,
                  purpose: usage.purpose,
                }))
              : [],
            thirdParties: policy.providers
              ? policy.providers.map((provider) => ({
                  id: provider.id,
                  key: provider.id,
                  name: provider.name,
                  url: provider.privacyUrl,
                }))
              : [],
          };
        })
    : [];

  const appr = appearance || { position: "bottom-left" };

  const [positionY, positionX] = (appr.position || "bottom-left").split("-");
  const { colorPrimary: primaryColor, customHtml, customCopy } = appr;

  const language = (appr && appr.language) || "en";
  const isV2Enabled = (appr && appr.isV2) || false;

  return {
    policies,
    appearance: appr,
    language,
    customCopy,
    cookiePromptHtml: customHtml,
    theme: {
      positionX,
      positionY,
      primaryColor: primaryColor || "#5FCF91",
      removeBrandingTopLevel: appr.removeBrandingTopLevel,
      outlineDeclineButton: appr?.outlineDeclineButton,
    },
    scrollToConsent: !!appr.consentOnScroll,
    isV2Enabled,
  };
};

const CSMPreview = ({ appearance, policies, policy, mode, pathname }) => {
  const {
    theme,
    language,
    scrollToConsent,
    policies: csmPolicies,
    cookiePromptHtml,
    customCopy,
    isV2Enabled,
  } = convertConfigToCSM({
    initialPolicies: policies ? policies : policy ? [policy] : [],
    appearance,
  });

  if (mode === "policy") {
    return (
      <PolicyPreviewContainer>
        <ConfirmicProvider
          theme={{
            ...defaultTheme,
            ...theme,
          }}
          language={language}
          customCopy={customCopy}
        >
          <CustomizeView
            policies={csmPolicies}
            enabledPolicyIds={[]}
            embeddedPolicies={[]}
            newInitialPolicies={[]}
            updatedInitialPolicies={[]}
            pathname={csmPolicies[0] ? "/policy-details" : "/"}
            policy={csmPolicies[0]}
            isV2Enabled={isV2Enabled}
            track={noop}
            trackClick={noop}
            trackPageView={noop}
          />
          {isV2Enabled ? <Orb /> : <Ball heavyShadow />}
        </ConfirmicProvider>
      </PolicyPreviewContainer>
    );
  }

  if (mode === "onboarding") {
    return (
      <OnboardingPreviewContainer>
        <ConfirmicProvider
          theme={{
            ...defaultTheme,
            ...theme,
          }}
          language={language}
          customCopy={customCopy}
        >
          <ConsentManager
            policies={csmPolicies}
            scrollToConsent={scrollToConsent}
            pathname={pathname}
            embeddedPolicies={[]}
            newInitialPolicies={[]}
            updatedInitialPolicies={[]}
            changedInitialPolicies={[]}
            previewMode
            isV2Enabled={isV2Enabled}
            onRequestClose={noop}
            setPolicyStates={noop}
            track={noop}
            trackClick={noop}
            trackPageView={noop}
          />
        </ConfirmicProvider>
      </OnboardingPreviewContainer>
    );
  }

  return (
    <ConfirmicProvider
      theme={{
        ...defaultTheme,
        ...theme,
      }}
      language={language}
      customCopy={customCopy}
    >
      <ConsentManager
        policies={csmPolicies}
        scrollToConsent={scrollToConsent}
        embeddedPolicies={[]}
        newInitialPolicies={[]}
        updatedInitialPolicies={[]}
        changedInitialPolicies={[]}
        cookiePromptHtml={cookiePromptHtml}
        pathname="/prompt"
        previewMode
        onRequestClose={noop}
        setPolicyStates={noop}
        track={noop}
        trackClick={noop}
        trackPageView={noop}
        isV2Enabled={isV2Enabled}
      />
    </ConfirmicProvider>
  );
};

const ErrorBoundaryWrapppedCSMPreview = (props) => (
  <ErrorBoundary>
    <CSMPreview {...props} />
  </ErrorBoundary>
);

export default ErrorBoundaryWrapppedCSMPreview;
