import React from 'react'
import Code from '../Components/Code'

const getSnippet = ({ clientId = 'your-client-id' }) => {
    return `
<script src="https://config.confirmic.com/config.js?id=${clientId}" crossorigin charset="utf-8"></script>
<script src="https://consent-manager.confirmic.com/embed.js" crossorigin charset="utf-8"></script>
`
}

export const Snippet = ({ clientId, onCopy }) => (
	<Code
		copyable
		codeString={getSnippet({ clientId })}
		language="html"
		lightMode
		onCopy={onCopy}
	/>
)

export const ReraiseSnippet = ({ onCopy }) => (
	<Code
		copyable
		codeString={`<a href="javascript:window.Confirmic('ConsentManager:show')">manage cookies</a>`}
		language="html"
		lightMode
		onCopy={onCopy}
	/>
)