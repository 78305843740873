import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import Auth from '../../utils/Auth'

const Loading = () => <p>Loading...</p>

const Connect = ({ projectId }) => {
	const isAuthed = Auth.isAuthenticated()

	useEffect(() => {
		if (window.opener && projectId) {
			window.opener.postMessage(JSON.stringify({ projectId }), '*')
			window.localStorage.removeItem('isWordpress')
			window.close()
		}
	}, [projectId])

	let context
	try {
		context = (new URL(window.location.href)).searchParams.get('context')
	} catch (e) {
		//
	}

	if (context === 'create') {
		window.localStorage.isWordpress = true
		return <Redirect to="/account/signup?ref=wp" />
	}

	if (!isAuthed) {
		// show login
		return <Redirect to="/account/login?callback=/connect" />
	}

	return <Loading />
}

export default Connect
