import { useState, useCallback } from 'react'

const useProviderAssignments = (initial = {}) => {
	const [providerAssignments, assignProviders] = useState(initial)

	const assignProvider = useCallback((providerId, templateId) => (
		assignProviders({
			...providerAssignments,
			[providerId]: templateId,
		})
	 ), [assignProviders, providerAssignments])

	return { providerAssignments, assignProvider, assignProviders }
}

export default useProviderAssignments