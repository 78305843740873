import React from "react"
import Box from "Components/Box"
import styles from "../../routes/Dashboard/Policies/NewPolicy/NewPolicy.module.css"
import Modal from "../Modal"
import styled from "styled-components"

const Container  = styled.div`
    transition: all 0.3s;
    overflow: hidden;
    position: relative;
`

const NewProject = ({ visible, close, input, setField, onSubmit }) => {
  return (
    <Modal isVisible={visible} setIsVisible={close}>
      <Container>
        <div>
          <Box
            title="Create Project"
            inputs={[
              {
                label: "Name",
                required: true,
                placeholder: "Project name",
                key: "name",
                description: "Give the project a name"
              },
              {
                label: "Domain",
                required: true,
                placeholder: "google.com",
                description: "What domain would you like to protect?",
                key: "domain"
              }
            ]}
            buttonsBottom
            inputsClassName={styles.inputs}
            defaultValues={input}
            onChange={setField}
            onSave={onSubmit}
            buttonSize="medium-large"
            saveBtnText="Next"
            centered
            notificationsDisabled
            icon={null}
            inlineInputLabels={false}
          />
        </div>
      </Container>
    </Modal>
  )
}

export default NewProject
