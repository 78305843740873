import React, { useContext } from 'react'
import {Switch} from 'react-router-dom'

import Box from '../../../Components/Box'
import styles from './Developers.module.css'
import NavBar from '../../../Components/NavBar'
import { Snippet, ReraiseSnippet } from '../../../NewComponents/Snippets'
import TrackedRoute from 'NewComponents/TrackedRoute'
import Alert from 'NewComponents/Alert'

import TrackingContext from 'contexts/TrackingContext';

import Integrate from './Integrate'

const SNIPPET_COPIED = 'Snippet copied';

const Developers = ({ clientId, match, loading, autoblockingEnabled, isSnippetValid }) => {
	const { trackClick } = useContext(TrackingContext)

	return (
		<div className={styles.mainContainer}>
			<NavBar />
			<Switch>
				<TrackedRoute path="/dashboard/developers/" exact render={() => (
					<div className={styles.container}>
						<Box inlineInputLabels title="Credentials" inputs={[
							{ type: 'copyable', label: 'Client ID', value: clientId, key: 'clientId', description: 'Your client ID should be used in your Cookie Widget script snippet.' },
						]} />
						<Box title="Cookie Widget Snippet">
							{!loading && autoblockingEnabled && !isSnippetValid &&
								<Alert type="error" style={{margin: '12px 0' }}>
									<p>The Cookie Widget Snippet currently running on your website does not support Autoblocking.</p>
									<p>Paste this snippet to the top of the <strong>{'<head>'}</strong> to enable it.</p>
								</Alert>
							}
							<Snippet clientId={clientId} onCopy={() => {
								trackClick(SNIPPET_COPIED, match.path, { eventName: 'embed' })
							}} />

							<p className={styles.text}>Then add this in your footer to let people change their preferences</p>
							<ReraiseSnippet onCopy={() => {
								trackClick(SNIPPET_COPIED, match.path, { eventName: 'reraise' })
							}} />
						</Box>
					</div>
				)} />
				<TrackedRoute path="/dashboard/developers/integrate" component={Integrate} />
			</Switch>
		</div>
	)
}
export default Developers