import React, { useState, useRef } from 'react'
import Switch from 'react-switch'
import copy from 'copy-to-clipboard'
import { SketchPicker } from 'react-color'
import classnames from 'classnames'
import styled from 'styled-components'

import colors from '../../styles/colors.module.css'

import styles from './Input.module.css'

import Modal from '../Modal'
import Button from '../Button'

import { PolicyIconPicker } from '../../routes/Dashboard/Policies/NewPolicy/NewPolicy'
import { PolicyIcon } from '../PolicyHeader/PolicyHeader'
import OutsideClickHandler from "../Modal/OutsideClickHandler";

/** Helper function to turn non-styled input to Styled input
 *  Note: the Component _must_ pass className down to its child for this to work
*/
const asStyled = (Component) => styled(Component)``

export const SwitchInput = asStyled(({ value, valueMapper, onChange, style, className }) => {
	let v = value

	if (valueMapper) {
		v = valueMapper(true) === value
	}

	return (
		<div className={className}>
			<Switch
				onChange={(value) => {
					if (valueMapper) {
						onChange({ target: { value: valueMapper(value) } })
					} else {
						onChange({ target: { value } })
					}
				}}
				checked={!!v}
				uncheckedIcon={false}
				checkedIcon={false}
				onColor={colors.PartyPink}
				width={38}
				height={22}
				handleDiameter={16}
				boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
				borderColor="rgba(0, 0, 0, 0.1)"
				offColor="#C4C4C4"
			/>
		</div>
	)
})

const CopyableInput = ({ transparent, value }) => {
	const [showCopied, setShowCopied] = useState(false)

	return (
		<div className={showCopied ? styles.copyableInputContainerShowText : styles.copyableInputContainer} onClick={e => {
			const target = e.target.children[0] || e.target
			target.select()
			copy(target.value, { message: 'Press #{key} to copy' })
			setShowCopied(true)
			setTimeout(() => {
				setShowCopied(false)
			},1000)
		}}>
			<input
				value={value || ''}
				readOnly
				className={styles.copyableInput}
				style={transparent && { borderColor: 'transparent', boxShadow: 'none', paddingLeft: 0 }}
			/>
		</div>
	)
}

const ApiSecret = ({ label, description, disabled, defaultValue }) => {
	const [isVisible, setIsVisible] = useState(false)
	const inputType = isVisible ? 'text' : 'password'

	return (
		<div className={disabled ? styles.inputContainerDisabled : styles.inputContainer}>
			<label className={styles.inputLabel}>{label}</label>
			<div className={styles.inputWithButtons}>
				{isVisible ? (
					<CopyableInput value={defaultValue} />
				) : <input readOnly className={styles.passwordInput} value={defaultValue} type={inputType} />}
				<div className={styles.button} onClick={() => setIsVisible(!isVisible)}>
					{isVisible ? (
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M11.0956 17.9609C11.3879 17.9867 11.6893 18.0002 11.9999 18.0002C16.909 18.0002 20.9999 12.0002 20.9999 12.0002C20.9999 12.0002 20.3303 11.0181 19.2079 9.84863L11.0956 17.9609Z" fill="#42486D"/>
							<path fillRule="evenodd" clipRule="evenodd" d="M14.505 6.49481C13.7075 6.18694 12.8665 6 12 6C5.45455 6 3 12 3 12C3 12 3.75004 13.8334 5.52654 15.4733L9 11.9999C9.00008 10.3431 10.3431 9.00008 11.9999 9L14.505 6.49481Z" fill="#42486D"/>
							<rect opacity="0.3" x="5.09998" y="18.4351" width="19" height="2" transform="rotate(-45 5.09998 18.4351)" fill="#42486D"/>
						</svg>
					) : (
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M12 6C5.45455 6 3 12 3 12C3 12 5.45455 18 12 18C16.9091 18 21 12 21 12C21 12 16.9091 6 12 6ZM9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12Z" fill="#42486D"/>
						</svg>
					)}
				</div>
				<div className={styles.button}>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.refreshButton}>
						<path fillRule="evenodd" clipRule="evenodd" d="M6 12H4C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C10.024 4 8.16544 4.72183 6.72908 5.98126L5.9227 5.41663C5.58613 5.18096 5.12474 5.42931 5.1361 5.84002L5.23446 9.39669C5.24371 9.73135 5.57284 9.96254 5.8908 9.85773L9.27756 8.74138C9.66844 8.61254 9.74496 8.093 9.40782 7.85694L8.43294 7.17431C9.44765 6.42283 10.6885 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12Z" fill="#EB4967"/>
					</svg>
				</div>
			</div>
			{description && <p className={styles.description}>{description}</p>}
		</div>
	)
}

const IconPicker = ({ value, onChange }) => {
	const [isVisible, setIsVisible] = useState(false)

	return (
		<>
			<div className={styles.iconPicker}>
				{value && <PolicyIcon type={value} width={32} height={32} />}
				<Button type="white" size="x-small" onClick={() => setIsVisible(true)} style={{ marginLeft: 42 }}>Change Icon</Button>
			</div>
			<Modal setIsVisible={setIsVisible} isVisible={isVisible}>
				<PolicyIconPicker value={value} onSubmit={v => {
					onChange(v)
					setIsVisible(false)
				}} />
			</Modal>
		</>
	)
}

function Autocomplete({ searchField, className, data = [], placeholder, transparent, value = '', onChange }) {
	const [showList, setShowList] = useState(false);
	const listRef = useRef();
	const inputRef = useRef();

	const results = value.length > 1
		? data.filter(d => d[searchField].toLowerCase().includes(value.toLowerCase()))
		: []
	const numResults = results.length;

	return (
		<div
			className={styles.autocompleteContainer}
			onKeyDown={e => {
				if (e.key === 'Escape') {
					inputRef.current.focus();
					setShowList(false)
				}
			}}
			onBlur={e => {
				const { relatedTarget } = e
				if (listRef.current?.contains(relatedTarget) || relatedTarget === inputRef.current) return
				onChange(inputRef.current.value)
				setShowList(false)
			}}
		>
			<input
			 	ref={inputRef}
				autocomplete="off"
				className={className}
				placeholder={placeholder}
				value={value}
				onFocus={() => setShowList(true)}
				onChange={(e) => onChange(e.target.value)}
				style={transparent && { borderColor: 'transparent', boxShadow: 'none', paddingLeft: 0 }}
				onKeyDown={e => {
					if (e.key === 'ArrowDown') {
						e.preventDefault();
						/* eslint-disable-next-line no-unused-expressions */
						listRef.current?.querySelector('[role="button"]')?.focus()
					}
				}}
			/>

			{numResults > 0 && showList && (
				<ul className={transparent ? styles.resultsContainerTransparent : styles.resultsContainer} ref={listRef}>
					{results.map((result, i) => (
						<li>
							<div
								// <button>s don't automatically gain focus when clicked
								// So this combination of role and tabIndex gives us event.relatedTarget
								// See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Clicking_and_focus
								role="button"
								tabIndex="0"
								key={result.id}
								className={styles.result}
								onClick={() => {
									onChange(result[searchField])
									setShowList(false)
								}}
								onKeyDown={(e) => {
									switch(e.key) {
										case 'Enter':
											onChange(result[searchField])
											setShowList(false)
											break;
										case 'ArrowDown':
											e.preventDefault();
											const nextResultIdx = (i + 1) % numResults;
											const nextButton = listRef.current?.querySelectorAll('[role="button"]')[nextResultIdx]
											/* eslint-disable no-unused-expressions */
											nextButton?.focus()
											nextButton?.scrollIntoView(false)
											/* eslint-enable no-unused-expressions */
											break;
										case 'ArrowUp':
											e.preventDefault();
											const prevResultIdx = (i - 1 + numResults) % numResults;
											const prevButton = listRef.current?.querySelectorAll('[role="button"]')[prevResultIdx]
											/* eslint-disable no-unused-expressions */
											prevButton?.focus()
											prevButton?.scrollIntoView(true)
											/* eslint-enable no-unused-expressions */
											break;
										default:
											break;
									}
								}}
							>
								{result[searchField]}
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	)
}

export const Checkbox = asStyled(({ value, onChange, color, className }) => (
	<div className={classnames(styles.checkbox, className)} onClick={() => onChange({ target: { value: !value } })}>
		{value && (
			<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M19.4141 0.9375L7.08594 13.2656L2.58594 8.71875C2.35156 8.53125 1.97656 8.53125 1.78906 8.71875L0.429688 10.0781C0.242188 10.2656 0.242188 10.6406 0.429688 10.875L6.71094 17.1094C6.94531 17.3438 7.27344 17.3438 7.50781 17.1094L21.5703 3.04688C21.7578 2.85938 21.7578 2.48438 21.5703 2.25L20.2109 0.9375C20.0234 0.703125 19.6484 0.703125 19.4141 0.9375Z" fill={color || "#5FCF91"}/>
			</svg>
		)}
	</div>
))

export const ColorPicker = asStyled(({ value, onChange, className }) => {
	const [showPicker, setShowPicker] = useState(false)

	return (
		<>
			<div className={classnames(styles.colorPickerContainer,className)} style={{ borderColor: value }}>
				<input readOnly className={styles.colorPickerInput} value={value} onClick={() => setShowPicker(!showPicker)} />
				<div className={styles.colorPickerBtn} style={{ background: value }}/>
			</div>
			{showPicker && (
			  <OutsideClickHandler onClickOutside={() => setShowPicker(false)}>
          <div className={styles.colorPicker}
               onClick={() => {
                 setShowPicker(true)
               }}>
            <SketchPicker
              color={value}
              onChangeComplete={color => onChange({ target: { value: color.hex } })}
            />
          </div>
        </OutsideClickHandler>
			)}
		</>
	)
})

const FakeRadioButton = ({ checked, onClick }) => (
	<div onClick={onClick} className={styles.radioButton}>
		{checked && <div className={styles.radioButtonCheck} />}
	</div>
)

export const RadioGroup = asStyled(({ options, value, onChange, className }) => (
	<div className={classnames(styles.radioButtons, className)}>
		{options.map(({ text, value: optionValue }, i) => (
			<div key={optionValue} className={styles.radioOption}>
				<FakeRadioButton checked={optionValue === value || (!value && i === 0)} onClick={() => onChange(optionValue)} />
				<label className={styles.radioLabel}>{text}</label>
			</div>
		))}
	</div>
))

export const Select = asStyled(({value,options,onChange,className}) => (
	<select className={classnames(styles.select,className)} value={value||''} onChange={onChange}>
		<option disabled value={''}> Select </option>
		{options.map(({ text, value }) => <option key={value} value={value}>{text}</option>)}
	</select>
))

const Input = (p) => {
	if (p.type === 'api-secret') {
		return <ApiSecret {...p} />
	}

	const { label, inputType, className, inputLabelClassName, size, value, inlineLabel, placeholder, icon, options, description, onChange, type, disabled, transparent, inputClassName: icn, darkMode, ...props } = p

	let labelClassName = styles.inputLabel
	let inputClassName = styles.input

	if (size === 'large') {
		labelClassName = styles.largeInputLabel
		inputClassName = styles.largeInput
	}

	if (inputLabelClassName) {
		labelClassName = classnames(labelClassName, inputLabelClassName)
	}

	let inputContainerClassNames = [styles.inputContainer]

	if (inlineLabel) {
		inputContainerClassNames.push(styles.inlineLabel)
		if (type === 'icon-picker') {
			inputContainerClassNames.push(styles.inlineLabelIconPicker)
		}
	}

	if (disabled) {
		inputContainerClassNames.push(styles.inputContainerDisabled)
	}

	if (icn) {
		inputClassName = classnames(inputClassName, icn)
	}

	const input = (
		<>
			{!options && !type && <input className={inputClassName} placeholder={placeholder} value={value || ''} onChange={onChange} type={inputType} style={transparent && { borderColor: 'transparent', boxShadow: 'none', paddingLeft: 0, paddingRight: 0 }} {...props} />}
			{type === 'textarea' && <textarea className={styles.textarea} placeholder={placeholder} value={value} onChange={onChange} {...props} />}
			{type === 'copyable' && <CopyableInput transparent={transparent} value={p.value} />}
			{type === 'icon-picker' && <IconPicker value={value} onChange={onChange} />}
			{type === 'autocomplete' && <Autocomplete data={p.data} value={p.value} onChange={onChange} placeholder={placeholder} className={inputClassName} transparent={transparent} searchField={p.searchField}  />}
			{options && type === 'options' && <Select value={value} options={options} onChange={onChange} />}
			{options && type === 'radio' && (
				<RadioGroup options={options} value={value} onChange={onChange} />
			)}
			{type === 'color' && <ColorPicker onChange={onChange} value={value || p.defaultValue} {...props} />}
			{type === 'checkbox' && <Checkbox className={inputClassName} value={!!value} onChange={onChange} {...props} />}
			{type === 'switch' && <SwitchInput value={!!value} onChange={onChange} {...props} />}
		</>
	)

	return (
		<div className={classnames(...inputContainerClassNames, className, {
			[styles.inputContainerWithDescription]: description,
		})}>
			{label && <label className={labelClassName} style={type === 'switch' ? { lineHeight: '24px' } : {}}>{label}</label>}

			{inlineLabel ? (
				<div className={styles.inlineLabelInputContainer}>
					{input}
					{description && <p className={styles.description}>{description}</p>}
				</div>
			) : (
				<>
					{input}
					{description && <p className={styles.description}>{description}</p>}
				</>
			)}
		</div>
	)
}

export default Input
