import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useContext} from 'react'
import {SelectedProjectContext} from 'contexts/ProjectContext'

const getProjectPreferences = gql`
  query getProjectPreferences($id: ID!) {
		Project {
      get(id: $id) {
				id
				preferences
			}
		}
	}
`

const updateProjectPreferences = gql`
  mutation updateDefaultProjectPreferences(
		$preferences: Preferences!,
    $projectId: ID!
	) {
		Project {
			updatePreferences(id: $projectId, preferences: $preferences)
		}
	}
`

const useAppearance = () => {
  const {selectedProject = {}} = useContext(SelectedProjectContext)
  const { loading, data, refetch } = useQuery(getProjectPreferences, {variables: {id: selectedProject && selectedProject.id}})
  const [mutate] = useMutation(updateProjectPreferences, {variables: {projectId: selectedProject && selectedProject.id}})

	const appearance = data && data.Project.get.preferences

	const setAppearance = (appearance) => {
		return mutate({ variables: { preferences: appearance } }).then(refetch)
	}


	return {appearance, setAppearance, loading}
}

export default useAppearance
