import styled, { css } from 'styled-components'

const Badge = styled.div`
	border-radius: 52px;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: uppercase;

	display: inline-block;
	padding: 4px 10px;

	${p => {
		switch(p.type) {
			case 'positive':
				return css`
					color: #08AC7A;
					background: rgba(8, 172, 122, 0.1);
				`;
			case 'negative':
				return css`
					color: #9a4d4d;
					background: rgba(172, 8, 8, 0.1);
				`;
			case 'warning':
				return css`
					color: #DA8656;
					background: rgba(228, 160, 30, 0.1);
				`;
			default:
				return css`
					color: #616161;
					background: rgba(179, 179, 179, 0.25);
				`;
		}
	}}
`

export default Badge