import React from 'react'

import ReactModal from 'react-modal'
import styles from './Modal.module.css'

const Modal = ({ isVisible, setIsVisible, children }) => (
    <ReactModal
        isOpen={isVisible}
        className={styles.modal}
        overlayClassName={styles.backdrop}
        closeTimeoutMS={350}
        onRequestClose={() => setIsVisible(false)}
    >
        {children}
    </ReactModal>
)

export default Modal