import React from 'react'
import styles from '../../../../../Components/NavBar/NavBar.module.css'
import { NavLink } from 'react-router-dom'

const links = [
	{ link: '/', name: 'Details' },
	{ link: '/data', name: 'Data' },
	{ link: '/third-parties', name: 'Third-Parties' },
	{ link: '/history', name: 'History' },
	{ link: '/delete', name: 'Delete' },
]

const PolicyNav = ({ policyId }) => {

	return (
		<div className={styles.subcontainer}>
			{links.map(({ link, name }) => (
				<NavLink key={link} to={`/dashboard/policies/${policyId}${link}`} className={styles.text} exact activeClassName={styles.activeText}>{name}</NavLink>
			))}
		</div>
	)
}

export default PolicyNav
