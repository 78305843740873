import React, { useState } from "react";
import { Link } from "react-router-dom";
import hex2rgb from "hex2rgb";
import classnames from "classnames";

import styles from "./PolicyHeader.module.css";
import { sortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import Badge from "NewComponents/Badge";

export const icons = {
  unknown: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.57031 10.25C5.66797 10.2695 5.74609 10.2305 5.76562 10.1523L8.51953 0.679688C8.53906 0.601562 8.5 0.503906 8.42188 0.484375L7.66016 0.269531C7.58203 0.25 7.48438 0.289062 7.46484 0.367188L4.71094 9.83984C4.69141 9.91797 4.73047 10.0156 4.82812 10.0352L5.57031 10.25ZM3.94922 7.88672L4.47656 7.32031C4.53516 7.26172 4.53516 7.16406 4.47656 7.10547L2.40625 5.25L4.47656 3.41406C4.53516 3.35547 4.53516 3.25781 4.47656 3.19922L3.94922 2.63281C3.89062 2.57422 3.79297 2.55469 3.73438 2.61328L1.03906 5.15234C0.980469 5.21094 0.980469 5.30859 1.03906 5.36719L3.73438 7.88672C3.79297 7.94531 3.89062 7.94531 3.94922 7.88672ZM9.49609 7.88672L12.1914 5.36719C12.25 5.30859 12.25 5.21094 12.1914 5.15234L9.49609 2.61328C9.4375 2.55469 9.33984 2.57422 9.28125 2.63281L8.75391 3.19922C8.69531 3.25781 8.69531 3.35547 8.75391 3.41406L10.8242 5.25L8.75391 7.10547C8.69531 7.16406 8.69531 7.26172 8.75391 7.32031L9.28125 7.88672C9.33984 7.94531 9.4375 7.94531 9.49609 7.88672Z"
        fill={color}
      />
    </svg>
  ),
  essential: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.87264 16.2427C8.87264 16.9882 9.84237 17.2771 10.2504 16.6532L15.6257 8.43252C15.9519 7.93369 15.594 7.27207 14.998 7.27207H10.7506V2.13078C10.7506 1.38527 9.78088 1.09637 9.37289 1.72033L3.99757 9.94097C3.67139 10.4398 4.02928 11.1014 4.62528 11.1014H8.87264V16.2427Z"
        fill={color}
      />
    </svg>
  ),
  email: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66675 5.5C4.56218 5.5 3.66675 6.39543 3.66675 7.5V14.5C3.66675 15.6046 4.56218 16.5 5.66675 16.5H16.3334C17.438 16.5 18.3334 15.6046 18.3334 14.5V7.5C18.3334 6.39543 17.438 5.5 16.3334 5.5H5.66675ZM16.432 7.62268C16.283 7.35524 15.938 7.25517 15.6613 7.39918L11.0001 9.82537L6.33891 7.39918C6.06224 7.25517 5.7172 7.35524 5.56822 7.62268C5.41925 7.89012 5.52276 8.22366 5.79943 8.36767L10.7304 10.9343C10.8988 11.0219 11.1014 11.0219 11.2698 10.9343L16.2008 8.36767C16.4774 8.22366 16.581 7.89012 16.432 7.62268Z"
        fill={color}
      />
    </svg>
  ),
  chat: ({ color = "currentColor", width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1203 17.8534C19.1219 18.2997 18.5823 18.5243 18.2667 18.2087L16.0369 15.9789H5.98254C4.70356 15.9789 3.66675 14.942 3.66675 13.6631V6.71569C3.66675 5.43672 4.70356 4.3999 5.98254 4.3999H16.7896C18.0685 4.3999 19.1053 5.43672 19.1053 6.71569V13.6631L19.1203 17.8534Z"
      />
    </svg>
  ),
  location: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.494 18.4247C8.60148 16.7358 4.58325 12.7786 4.58325 9.625C4.58325 5.5 7.33325 2.75 11.4583 2.75C15.5833 2.75 18.3333 6.1875 18.3333 9.625C18.3333 12.2725 14.2552 16.5511 12.3808 18.378C11.8549 18.8905 11.0419 18.9137 10.494 18.4247ZM13.7499 8.70833C13.7499 9.97399 12.7239 11 11.4583 11C10.1926 11 9.16661 9.97399 9.16661 8.70833C9.16661 7.44268 10.1926 6.41667 11.4583 6.41667C12.7239 6.41667 13.7499 7.44268 13.7499 8.70833Z"
        fill={color}
      />
    </svg>
  ),
  payments: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83325 4.5835C2.72868 4.5835 1.83325 5.47893 1.83325 6.58349V7.3335H20.1666V6.5835C20.1666 5.47893 19.2712 4.5835 18.1666 4.5835H3.83325ZM20.1666 10.0835H1.83325V15.4168C1.83325 16.5214 2.72869 17.4168 3.83325 17.4168H18.1666C19.2712 17.4168 20.1666 16.5214 20.1666 15.4168V10.0835Z"
        fill={color}
      />
    </svg>
  ),
  marketing: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62472 10.1617L2.4427 13.3437C2.05218 13.7342 2.05218 14.3674 2.4427 14.7579L3.71257 16.0278C4.60236 15.4383 5.813 15.5355 6.59696 16.3194C7.38091 17.1034 7.47814 18.314 6.88864 19.2038L8.15848 20.4737C8.54901 20.8642 9.18217 20.8642 9.57269 20.4737L12.7546 17.2917L11.4583 15.9954C11.1003 15.6374 11.1003 15.057 11.4583 14.699C11.8163 14.3411 12.3967 14.3411 12.7547 14.699L14.051 15.9954L20.4739 9.57244C20.8644 9.18191 20.8644 8.54875 20.4739 8.15823L19.2041 6.88842C18.3143 7.47794 17.1037 7.38072 16.3197 6.59675C15.5357 5.81279 15.4385 4.60213 16.028 3.71234L14.7581 2.44244C14.3676 2.05192 13.7345 2.05192 13.3439 2.44244L6.92108 8.8653L8.21743 10.1616C8.57541 10.5196 8.57541 11.1 8.21743 11.458C7.85945 11.816 7.27905 11.816 6.92107 11.458L5.62472 10.1617ZM8.86556 13.4025C8.50758 13.0445 8.50758 12.4641 8.86556 12.1062C9.22354 11.7482 9.80394 11.7482 10.1619 12.1062L10.8101 12.7543C11.1681 13.1123 11.1681 13.6927 10.8101 14.0507C10.4521 14.4087 9.87172 14.4087 9.51374 14.0507L8.86556 13.4025Z"
        fill={color}
      />
    </svg>
  ),
  phone: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6316 12.9644L13.652 10.944C14.21 10.386 14.3483 9.53359 13.9954 8.82778L13.8809 8.59883C13.528 7.89302 13.6664 7.04057 14.2243 6.48258L16.7294 3.9775C16.9084 3.79851 17.1986 3.79851 17.3776 3.9775C17.4124 4.01234 17.4414 4.05256 17.4635 4.09662L18.4754 6.12058C19.2496 7.66893 18.9461 9.53895 17.7221 10.763L12.2231 16.262C10.8907 17.5944 8.91988 18.0596 7.13226 17.4637L4.91899 16.726C4.67885 16.6459 4.54907 16.3864 4.62911 16.1462C4.65161 16.0787 4.68952 16.0174 4.73984 15.9671L7.17017 13.5368C7.72816 12.9788 8.58061 12.8404 9.28642 13.1933L9.51537 13.3078C10.2212 13.6607 11.0736 13.5224 11.6316 12.9644V12.9644Z"
        fill={color}
      />
    </svg>
  ),
  statistics: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5833 5.4082C15.5833 4.64881 16.1989 4.0332 16.9583 4.0332C17.7176 4.0332 18.3333 4.64881 18.3333 5.4082V15.4915C18.3333 16.2509 17.7176 16.8665 16.9583 16.8665C16.1989 16.8665 15.5833 16.2509 15.5833 15.4915V5.4082ZM10.0833 9.99154C10.0833 9.23214 10.6989 8.61654 11.4583 8.61654C12.2176 8.61654 12.8333 9.23214 12.8333 9.99154V15.4915C12.8333 16.2509 12.2176 16.8665 11.4583 16.8665C10.6989 16.8665 10.0833 16.2509 10.0833 15.4915V9.99154ZM5.95825 12.2832C5.19886 12.2832 4.58325 12.8988 4.58325 13.6582V15.4915C4.58325 16.2509 5.19886 16.8665 5.95825 16.8665C6.71764 16.8665 7.33325 16.2509 7.33325 15.4915V13.6582C7.33325 12.8988 6.71764 12.2832 5.95825 12.2832Z"
        fill={color}
      />
    </svg>
  ),
  recommendations: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8347 4.8584C14.3593 4.8584 12.6339 6.53098 11.7097 7.6084C10.7855 6.53098 9.06009 4.8584 7.58472 4.8584C4.97313 4.8584 3.45972 6.8954 3.45972 9.48794C3.45972 13.7959 11.7097 18.6084 11.7097 18.6084C11.7097 18.6084 19.9597 13.7959 19.9597 9.6709C19.9597 7.07836 18.4463 4.8584 15.8347 4.8584Z"
        fill={color}
      />
    </svg>
  ),
  media: ({ color, width, height }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8C8 6.89543 8.89543 6 10 6H38C39.1046 6 40 6.89543 40 8V40C40 41.1046 39.1046 42 38 42H10C8.89543 42 8 41.1046 8 40V8ZM10 10.5C10 10.2239 10.2239 10 10.5 10H13.5C13.7761 10 14 10.2239 14 10.5V13.5C14 13.7761 13.7761 14 13.5 14H10.5C10.2239 14 10 13.7761 10 13.5V10.5ZM34.5 10C34.2239 10 34 10.2239 34 10.5V13.5C34 13.7761 34.2239 14 34.5 14H37.5C37.7761 14 38 13.7761 38 13.5V10.5C38 10.2239 37.7761 10 37.5 10H34.5ZM10 18.5C10 18.2239 10.2239 18 10.5 18H13.5C13.7761 18 14 18.2239 14 18.5V21.5C14 21.7761 13.7761 22 13.5 22H10.5C10.2239 22 10 21.7761 10 21.5V18.5ZM34.5 18C34.2239 18 34 18.2239 34 18.5V21.5C34 21.7761 34.2239 22 34.5 22H37.5C37.7761 22 38 21.7761 38 21.5V18.5C38 18.2239 37.7761 18 37.5 18H34.5ZM10 26.5C10 26.2239 10.2239 26 10.5 26H13.5C13.7761 26 14 26.2239 14 26.5V29.5C14 29.7761 13.7761 30 13.5 30H10.5C10.2239 30 10 29.7761 10 29.5V26.5ZM34.5 26C34.2239 26 34 26.2239 34 26.5V29.5C34 29.7761 34.2239 30 34.5 30H37.5C37.7761 30 38 29.7761 38 29.5V26.5C38 26.2239 37.7761 26 37.5 26H34.5ZM34 34.5C34 34.2239 34.2239 34 34.5 34H37.5C37.7761 34 38 34.2239 38 34.5V37.5C38 37.7761 37.7761 38 37.5 38H34.5C34.2239 38 34 37.7761 34 37.5V34.5ZM10.5 34C10.2239 34 10 34.2239 10 34.5V37.5C10 37.7761 10.2239 38 10.5 38H13.5C13.7761 38 14 37.7761 14 37.5V34.5C14 34.2239 13.7761 34 13.5 34H10.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1399 29.2664C21.1399 29.6684 21.5902 29.906 21.9221 29.6792L28.5156 25.1721C28.8055 24.9739 28.8061 24.5464 28.5168 24.3474L21.9233 19.812C21.5915 19.5838 21.1399 19.8213 21.1399 20.2239V29.2664Z"
        fill="white"
      />
    </svg>
  ),
};

export const typeColors = {
  default: "#BCC077",
  email: "#BCC077",
  chat: "#66A7B1",
  location: "#B1445C",
  payments: "#F5BB5F",
  marketing: "#A1CDDA",
  phone: "#466993",
  statistics: "#8AB4F8",
  recommendations: "#9B88C0",
  essential: "#696969",
};

export const hexToRgba = (hex, opacity) =>
  `rgba(${hex2rgb(hex).rgb.join(",")},${opacity})`;

export const PolicyIcon = ({
  type,
  width = 42,
  height = 42,
  isInteractive,
  isActive,
  className,
}) => {
  // const color = typeColors[type] || typeColors.default
  const color = "#EB4967";

  return (
    <div
      className={classnames(styles.iconCircle, className)}
      style={{
        backgroundColor: "transparent",
        minWidth: width,
        minHeight: height,
        maxWidth: width,
        maxHeight: height,
        border: isInteractive
          ? `2px solid ${isActive ? color : "transparent"}`
          : "none",
      }}
    >
      {icons[type] &&
        icons[type]({ color, width: width / 1.5, height: height / 1.5 })}
    </div>
  );
};

export const DraftBadge = styled(Link).attrs((p) => ({
  children: <Badge>Draft</Badge>,
  to: `/dashboard/policies/${p.id}/history`,
  title: "View changes",
}))`
  margin-left: 8px;
  line-height: 0;

  ${Badge} {
    font-size: 11px;
    height: 14px;
    line-height: 14px;
    padding: 2px 8px;

    transition: background-color 0.1s ease-out, transform 0.1s ease-out;
    &:hover {
      background: rgba(179, 179, 179, 0.35);
      transform: scale(1.05);
    }
  }
`;

const StyledHandle = styled.span`
  :after {
    content: "::";
    display: block;
    font-weight: bold;
    color: #bdbdbd99;
    font-size: 22px;
    cursor: grab;
    transition: 0.1s;
    width: 24px;
    height: 24px;
    padding: 12px;
  }
  :hover:after {
    color: #999;
    transform: scale(1.2);
  }
  text-align: center;
  left: 0;
  opacity: 0;
`;

const DragHandle = sortableHandle(StyledHandle);

const showDragHandleClassname = "withDragHandle";
export const draggingClass = showDragHandleClassname;

const HeaderContainer = styled(Link)`
  position: relative;
  ${StyledHandle} {
    transition: 0.3s;
    transform: translateX(-12px);
    position: absolute;
  }
  &.${showDragHandleClassname}, :hover {
    ${StyledHandle} {
      opacity: 1;
      transform: scale(0.95) translateX(2px);
    }
    .${styles.iconCircle} {
      transform: translateX(8px) scale(0.7);
    }
  }
`;

const PolicyHeader = ({
  id,
  icon,
  className,
  version,
  slug,
  name,
  description,
  isDragging,
}) => {
  const [hovered, setHover] = useState(false);
  const startHover = () => setHover(true);
  const endHover = () => setHover(false);
  const isDraft = !version?.includes("0.0"); // has non-zero Minor

  return (
    <HeaderContainer
      /** prevent the browser from rendering a "dragged link" (ugly grey box with link text) */
      onDragStart={(e) => e.preventDefault()}
      onMouseEnter={startHover}
      onMouseLeave={endHover}
      to={`/dashboard/policies/${id}`}
      className={classnames(
        styles.container,
        { [showDragHandleClassname]: isDragging || hovered },
        className
      )}
    >
      <DragHandle />
      <PolicyIcon type={icon} />
      <div className={styles.body}>
        <h3 className={styles.title}>
          {name} {isDraft && <DraftBadge id={id} />}
        </h3>
        <p className={styles.text}>{description}</p>
      </div>
      <span className={styles.slug}>{slug}</span>
    </HeaderContainer>
  );
};

export default PolicyHeader;
