import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { compose } from 'redux'

import styles from './Settings.module.css'

import Box from '../../../Components/Box'
import Button from '../../../Components/Button'

import { logout } from '../../Logout/Logout'

import TrackingContext from 'contexts/TrackingContext';

const deleteDataMutation = gql`
	mutation deleteData {
		Account {
			delete
		}
	}
`

const extractDataMutation = gql`
	mutation extractData {
		Account {
			extract
		}
	}
`

function download(filename, text) {
	var element = document.createElement('a');
	element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
	element.setAttribute('download', filename);

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

const DataControls = ({ extractData, deleteData }) => {
	const { trackClick } = useContext(TrackingContext)

	return (
		<Box
			title="Data Controls"
			className={styles.dataBox}
		>
			<h5>Download your data in JSON format</h5>
			<Button onClick={() => {
				extractData()
					.then(data => {
						download('confirmic-data.json', JSON.stringify(data))
					})
				trackClick('Download data')
			}}>Download my Data</Button>
			<h5>Delete your account</h5>
			<p>This will automatically log you out and remove any widgets you have on your site which are provided by Confirmic.</p>
			<p>We will also remove your payment details and cancel any subscriptions that you have.</p>
			<Button type="secondary" onClick={() => {
				if (window.confirm('This will cause any Confirmic widgets you have deployed to break, is that ok?')) {
					deleteData()
						.then(() => {
							localStorage.removeItem('mtmAccounts')
							logout()
						})
					trackClick('Delete data')
				}
			}}>Delete my Data</Button>
		</Box>
	)
}

export default compose(
	graphql(extractDataMutation, {
		props: ({ mutate,loading,error}) => ({
			extractData: mutate,
			error,
			loading,
		})
	}),
	graphql(deleteDataMutation, {
		props: ({ mutate,loading,error}) => ({
			deleteData: mutate,
			error,
			loading,
		})
	}),
)(DataControls)