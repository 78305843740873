import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import styles from "./App.module.css";

import SelectedProjectProvider from "./contexts/ProjectContext";

import CompletedSignupGate from "./Components/CompletedSignupGate";
import LoggedInGate from "./Components/LoggedInGate";
import { ToastBase } from "./Components/Toast";
import ChatWithUs from "./Components/ChatWithUs";

import Dashboard from "./routes/Dashboard";
import Onboarding from "./routes/Onboarding";
import Account from "./routes/Account";
import Logout from "./routes/Logout";
import Connect from "./routes/Connect";

import RedirectWithParams from "./NewComponents/RedirectWithParams";

const DashboardOrLogin = () => <Redirect to="/dashboard" />;
const ForgotPassword = () => <Redirect to="/account/forgot" />;
const ResetPassword = () => <RedirectWithParams to="/account/forgot/reset" />;

const LoginRedirect = () => <RedirectWithParams to="/account/login" />;
const SignupRedirect = () => <RedirectWithParams to="/account/signup" />;

const App = () => (
  <div className={styles.app}>
    {/* {!isProd && <div className={styles.devmode}>dev</div>} */}
    <SelectedProjectProvider>
      <div className={styles.container}>
        <ChatWithUs />
        <ToastBase />
        <Switch>
          <Route path="/" exact component={DashboardOrLogin} />
          <Route path="/signup" component={SignupRedirect} />
          <Route path="/login" component={LoginRedirect} />
          <Route path="/logout" component={Logout} />

          <Route path="/account" component={Account} />

          {/* Deprecated route (typo) */}
          <Route path="/forget-password" component={ForgotPassword} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />

          <Route path="/connect" component={Connect} />

          <LoggedInGate path="/onboarding" component={Onboarding} />
          <CompletedSignupGate path="/dashboard" component={Dashboard} />
        </Switch>
      </div>
    </SelectedProjectProvider>
  </div>
);

export default App;
