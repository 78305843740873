import React, { useState, useRef } from 'react'
import Immutable from 'immutable'
import moment from 'moment'

import Box from '../Box'
import styles from './IntegrationHelper.module.css'
import Code from '../Code'

const icons = {
    folder: () => (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.33325 12.5001C1.33325 13.3285 2.00482 14.0001 2.83325 14.0001H13.1666C13.995 14.0001 14.6666 13.3285 14.6666 12.5001V6.16675C14.6666 5.33832 13.995 4.66675 13.1666 4.66675H6.66659L5.10592 3.10609C4.82462 2.82478 4.44309 2.66675 4.04526 2.66675H2.83325C2.00482 2.66675 1.33325 3.33832 1.33325 4.16675V12.5001Z" fill="#E89668"/>
        </svg>
    ),
    script: () => (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.33333 3.33325C5.33333 2.22868 6.22876 1.33325 7.33333 1.33325C8.4379 1.33325 9.33333 2.22868 9.33333 3.33325V3.99992H10.6667C11.7712 3.99992 12.6667 4.89535 12.6667 5.99992V7.33325H13.3333C14.4379 7.33325 15.3333 8.22868 15.3333 9.33325C15.3333 10.4378 14.4379 11.3333 13.3333 11.3333H12.6667V12.6666C12.6667 13.7712 11.7712 14.6666 10.6667 14.6666H4C2.89543 14.6666 2 13.7712 2 12.6666V11.3333H3.33333C4.4379 11.3333 5.33333 10.4378 5.33333 9.33325C5.33333 8.22868 4.4379 7.33325 3.33333 7.33325H2V5.99992C2 4.89535 2.89543 3.99992 4 3.99992H5.33333V3.33325Z" fill="#5FCF91"/>
        </svg>
    ),
    cookie: () => (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.30957 13.757L7.99996 13.7733L9.69036 13.757L11.1213 12.8568L12.5345 11.9292L13.2516 10.3983L13.9389 8.85389L13.7145 7.17837L13.4578 5.50751L12.3632 4.21928L11.2438 2.95248L9.6265 2.46053L7.99996 2L6.37343 2.46053L4.75612 2.95248L3.63678 4.21928L2.54217 5.50751L2.2854 7.17837L2.06104 8.85389L2.74836 10.3983L3.46547 11.9292L4.87867 12.8568L6.30957 13.757ZM6.66671 9C6.66671 9.55228 6.219 10 5.66671 10C5.11443 10 4.66671 9.55228 4.66671 9C4.66671 8.44772 5.11443 8 5.66671 8C6.219 8 6.66671 8.44772 6.66671 9ZM8.99996 6C9.55225 6 9.99996 5.55228 9.99996 5C9.99996 4.44772 9.55225 4 8.99996 4C8.44768 4 7.99996 4.44772 7.99996 5C7.99996 5.55228 8.44768 6 8.99996 6ZM10.6667 10.3333C10.6667 10.8856 10.219 11.3333 9.66671 11.3333C9.11443 11.3333 8.66671 10.8856 8.66671 10.3333C8.66671 9.78104 9.11443 9.33333 9.66671 9.33333C10.219 9.33333 10.6667 9.78104 10.6667 10.3333Z" fill="#EB4967"/>
        </svg>
    ),
}

const Item = ({ name, onClick, isActive, isCurrent, type }) => (
    <div className={isActive ? (isCurrent ? styles.itemCurrent : styles.itemActive) : styles.item} onClick={onClick}>
        {icons[type] ? icons[type]() : icons.folder()}
        <span className={styles.itemName}>{name}</span>
        {type !== 'cookie' && <svg style={{ marginLeft: 5 }} width="18" height="18" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>}
    </div>
)

const Column = ({ items, level, onItemClick }) => (
    <div className={styles.column}>
        {items.sortBy(a => a.get('type')).reverse().map(item => <Item isActive={item.get('isActive')} key={item.get('id')} onClick={() => onItemClick(item, level)} {...item.set('loc', item.get('ref')).delete('ref').toJS()} />)}
    </div>
)

const getDomain = url => {
    let domain = url
    try {
        domain = (new URL(url)).hostname
    } catch (e) {
        
    }

    return domain
}

const IntegrationHelper = ({ error, complete, items, meta }) => {
    const [selectedItems, setSelectedItems] = useState(Immutable.List([]))
    const scrollRef = useRef(null)
    let location
    let loc
    if (selectedItems.getIn([selectedItems.size - 1, 'type'], '') === 'cookie') {
        location = selectedItems.getIn([selectedItems.size - 2, 'ref'], '')
        loc = selectedItems.getIn([selectedItems.size - 2, 'id'], '')
    } else {
        location = selectedItems.getIn([selectedItems.size - 1, 'ref'], '')
        loc = selectedItems.getIn([selectedItems.size - 1, 'id'], '')
    }

    const snippet = selectedItems.getIn([selectedItems.size - 1, 'type']) === 'cookie' ? selectedItems.getIn([selectedItems.size - 1, 'fullCookie', 'value']) : selectedItems.getIn([selectedItems.size - 1, 'snippet'])
    const time = meta && moment(meta.initiatedAt).format('D MMM [at] h:mm a')

    const selectedItemIds = selectedItems.map(i => i.get('id'))

    return (
        <Box title={meta && `${meta.domain} scanned ${time}`} className={styles.box} boxBodyStyles={{ flexDirection: 'column', padding: 0  }}>
            {error && (
                <div className={styles.errorBoxBody}>
                    <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M57.3268 92.7972C58.8375 92.9302 60.3949 93.0001 62 93.0001C87.3636 93.0001 108.5 62.0001 108.5 62.0001C108.5 62.0001 105.04 56.9259 99.2407 50.8833L57.3268 92.7972Z" fill="#2E2E2E"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M74.9432 33.5567C70.8225 31.9659 66.4771 31 62 31C28.1818 31 15.5 62 15.5 62C15.5 62 19.3753 71.473 28.5541 79.9458L46.5 62C46.5 53.4396 53.4396 46.5 62 46.5L74.9432 33.5567Z" fill="#2E2E2E"/>
                        <rect opacity="0.3" x="26.35" y="95.2476" width="98.1667" height="10.3333" transform="rotate(-45 26.35 95.2476)" fill="#2E2E2E"/>
                    </svg>
                    <h2 className={styles.errorBoxTitle}>We can’t see your domain</h2>
                    <h4 className={styles.errorBoxDescription}>If you’re using a staging environment or localhost you need to</h4>
                    <h4 className={styles.errorBoxDescription}>whitelist our servers.</h4>
                </div>
            )}

            {!items && !error && complete && (
                <div className={styles.errorBoxBody}>
                    <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M46.5 51.6666V98.1666L60.1526 102.717C61.3762 103.125 62.6575 103.333 63.9473 103.333H100.874C102.304 103.333 103.535 102.324 103.816 100.922L111.105 64.4744C112.396 58.0181 107.204 52.0974 100.634 52.5354L74.9167 54.2499L76.575 36.0085C77.0717 30.5444 72.7694 25.8333 67.2828 25.8333C64.0054 25.8333 60.9682 27.5528 59.282 30.3632L46.5 51.6666Z" fill="#2E2E2E"/>
                        <rect opacity="0.3" x="10.3334" y="46.5" width="25.8333" height="56.8333" rx="1" fill="#2E2E2E"/>
                    </svg>

                    <h2 className={styles.errorBoxTitle}>We didn’t find any cookies on your site</h2>
                    <h4 className={styles.errorBoxDescription}>If you feel this isn’t correct, get in touch.</h4>
                </div>
            )}
 
            {
                items && (
                    <div className={styles.boxBody}>
                        <div className={styles.columnsContainer} ref={scrollRef}>
                            <Column
                                items={items.map(item => item.set('isActive', selectedItemIds.includes(item.get('id'))).set('isCurrent', selectedItemIds.last() === item.get('id')))}
                                level={0}
                                onItemClick={(item, level) => {
                                    const newSelectedItems = selectedItems.slice(0, level).push(item)
                                    setSelectedItems(newSelectedItems)
                                    setTimeout(() => {
                                    	if (scrollRef.current){
																				scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
																			}
                                    }, 200)
                                }}
                            />
                            {selectedItems.map((item, i) => item.get('children') && (
                                <Column
                                    key={i}
                                    items={item.get('children').map(item => item.set('isActive', selectedItemIds.includes(item.get('id'))).set('isCurrent', selectedItemIds.last() === item.get('id')))}
                                    level={i + 1}
                                    onItemClick={(item, level) => {
                                        const newSelectedItems = selectedItems.slice(0, level).push(item)
                                        setSelectedItems(newSelectedItems)
                                        setTimeout(() => {
                                        	if (scrollRef.current){
																						scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
																					}
                                        }, 200)
                                    }}
                                />
                            ))}
                            {selectedItems.getIn([selectedItems.size - 1, 'type']) === 'cookie' && (
                                <div className={styles.snippetColumn}>
                                    <p className={styles.snippetTitle}>selected cookie</p>
                                    <br />
                                    <p>name <p>{selectedItems.last().get('name')}</p></p>
                                    <br />
                                    <p>
                                        location
                                        <Code className={styles.code} lightMode codeString={loc + (location ? ':'+location : '') } />
                                    </p>
                                    <br />
                                    <p>value <Code className={styles.code} lightMode codeString={snippet} /></p>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }

            <div className={styles.boxFooter}>
                <div className={styles.breadcrumbs}>{selectedItems.toJS().map(item => <span className={styles.breadcrumb} title={item.name}>{getDomain(item.name)}</span>)}</div>
                <div className={styles.location}>
                    {loc}
                    <span className={styles.locationLine}>{location && ':'+location}</span>
                </div>
            </div>
        </Box>
    )
}

export default IntegrationHelper