import { useContext, useEffect, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";

import { SelectedProjectContext } from "contexts/ProjectContext";
import useBilling from './useBilling'
import useProjectsList from "./useProjectsList"

const stripeKey = {
  live: "pk_live_0tq8PyxlisDxYwAtvtMmK7Is0040Jz2tzT",
  test: "pk_test_WWEg2LWUuFb4DbT6ll6vI9wj00kK8JZBIS",
}[process.env.REACT_APP_SERVICE_ENV === 'live' ? 'live' : 'test']

export const Plans = {
  community: {
    name: "Community",
    key: "community",
  },
  pro: {
    name: "Pro",
    key: "pro",
  },
};

export const FREE_PRO_COUPON = 'FREEPRO'

const MAX_RETRIES_FOR_STRIPE = 3;
export const MAX_PAID_PRO = 10;
export const PRO_MONTHLY_COST_GBP = 10

const setupSubscriptionMutation = gql`
  mutation setupSubscription($successUrl: String!, $cancelUrl: String!) {
    Account {
      setupSubscription(successUrl: $successUrl, cancelUrl: $cancelUrl) {
        id
      }
    }
  }
`;

const updatePlanMutation = gql`
  mutation updatePlan($plan: Plan!) {
    Project {
      updatePlan(plan: $plan) {
        id
        plan
      }
    }
  }
`;

const QUERY_PARAMS = {
  setupKey: "setupPayment",
  planKey: "plan",
  setupSuccess: "success",
  setupCancelled: "cancelled",
};

const wait = duration => new Promise(resolve => setTimeout(resolve, duration))

export default function usePlans({
  onSuccess = () => {},
  onCancelled = () => {},
  onError = window.rollbar.error,
  returnUrl,
 } = {}) {
  const history = useHistory();

  const {
    selectedProject,
    refetchCurrentProject,
  } = useContext(SelectedProjectContext);
  const [
    setupSubscription,
    { loading: isFetchingCheckoutSession },
  ] = useMutation(setupSubscriptionMutation);
  const [
    updatePlan,
    { loading: isUpdatingPlan }
  ] = useMutation(updatePlanMutation);
  const { data: billingData, refetch: refetchBilling } = useBilling(true);
  const { projects = [] } = useProjectsList()
  const currentPlan = Plans[selectedProject?.plan];
  const hasFreeProCoupon = billingData?.paymentDetails?.coupon === FREE_PRO_COUPON
  const numProProjects = projects.filter(project => project.plan === Plans.pro.key).length;
  const hasFreePro = hasFreeProCoupon || numProProjects >= MAX_PAID_PRO

  // Checkout state is passed as query params via stripe's success/cancelUrls,
  // so let's convert those QPs into in-memory state for later processing.
  const searchParams = new URLSearchParams(history.location.search);
  const setupState = searchParams.get(QUERY_PARAMS.setupKey);
  const didSucceedSetup = setupState === QUERY_PARAMS.setupSuccess;
  const didCancelSetup = setupState === QUERY_PARAMS.setupCancelled;
  const setupPlan = searchParams.get(QUERY_PARAMS.planKey);
  const pendingUpdateForPlan = didSucceedSetup && setupPlan;

  searchParams.delete(QUERY_PARAMS.setupKey);
  searchParams.delete(QUERY_PARAMS.planKey);
  const searchParamsRedirectString = searchParams.toString();

  const setupStripe = useCallback(plan => {
    const url = new URL(returnUrl || window.location.href);
    if (plan) {
      url.searchParams.set(QUERY_PARAMS.planKey, plan.key);
    }
    url.searchParams.set(QUERY_PARAMS.setupKey, QUERY_PARAMS.setupSuccess);
    const successUrl = url.toString();
    url.searchParams.set(QUERY_PARAMS.setupKey, QUERY_PARAMS.setupCancelled);
    const cancelUrl = url.toString();

    return setupSubscription({
      variables: {
        successUrl,
        cancelUrl,
      },
    })
      .then(resp => resp?.data?.Account?.setupSubscription?.id)
      .then(id => {
        if (!id) return onError(new Error('Oops! Something went wrong, please try again'));
        const stripe = window.Stripe(stripeKey);
        console.log("Rediret to the checkout")
        return stripe.redirectToCheckout({
          sessionId: id,
        });
      });
  },
  [setupSubscription, returnUrl, onError]);

	// TODO: This function is unncessarily complex because we rely
	// on the coordination between Stripe Checkout and the hasPaymentDetails state
	// which is managed by the API in response to a Stripe webhook. This asynchronicity
	// is not something that the client knows about, so it does its best to manage
	// this asynchronicty with a bunch of fallbacks. When we move off Checkout
	// to managing our own Checkout flow, this will all go away.
  const updateProjectPlan = useCallback(
    (plan = Plans.community, numRetries) => (plan === currentPlan
      ? Promise.resolve(selectedProject).then(onSuccess)
      : refetchBilling().then(({ data: { hasPaymentDetails } }) => {
          if (!hasPaymentDetails && plan === Plans.pro) return setupStripe(plan);

          return updatePlan({ variables: { plan: plan.key } })
            .then(refetchCurrentProject)
            .then(onSuccess)
            .catch(error => {
              const message = error?.graphQLErrors?.[0]?.message || error.message;
              if (message !== "Payment details missing") return Promise.reject(message || "Oops something went wrong, please try again");

              // When Checkout has completed and the user is redirected back,
              // it might take a while for the card to be attached. The API
              // will throw this error again since it thinks that the Org has
              // no payment Details.
              // In this case, we don't want to go through the Stripe
              // setup flow again, so we pause and try again after some time
              if (typeof numRetries === "number") {
                return numRetries > 0
                  ? wait(1000).then(() => updateProjectPlan(plan, numRetries - 1))
                  : Promise.reject("Oops, something went wrong while saving your payment details, please try again.")
              } else {
                return setupStripe(plan);
              }
            })
            .catch(onError);
        })),
    [
      currentPlan,
      selectedProject,
      refetchBilling,
      updatePlan,
      refetchCurrentProject,
      onSuccess,
      onError,
      setupStripe,
    ]
  );

  const confirmUpdateProjectPlan = useCallback(async(plan = Plans.community) => {
    if (
      plan === Plans.pro &&
      !hasFreePro &&
      !window.confirm(`Do you want to upgrade "${selectedProject.name || selectedProject.primaryDomain}" to the ${Plans.pro.name} plan? You will be charged an additional £${PRO_MONTHLY_COST_GBP} a month.`)
    ) return;
    updateProjectPlan(plan)
  }, [selectedProject, hasFreePro, updateProjectPlan])

  // Since we already have the Stripe checkout state in memory (specifically in
  // the pendingUpdateForPlan variable), we can dispense with them in the
  // url. Replacing history prevents the leak of query-param-as-state implementation
  // detail, so that consumers can just rely on whatever on the hook's lifecycle.
  useEffect(() => {
    const { location } = history;
    if (location.search !== searchParamsRedirectString) {
      const newLocation = {
        ...location,
        search: searchParamsRedirectString,
      };
      console.log("History replace", newLocation)
      history.replace(newLocation);
    }
  },[searchParamsRedirectString, history])

  useEffect(() => {
    if (didCancelSetup) {
      onCancelled();
    }
  }, [didCancelSetup, onCancelled])

  useEffect(() => {
    if (pendingUpdateForPlan) {
      updateProjectPlan(Plans[pendingUpdateForPlan], MAX_RETRIES_FOR_STRIPE);
    }
  }, [pendingUpdateForPlan, updateProjectPlan]);

  return {
    currentPlan,
    updateProjectPlan,
    hasFreePro,
    confirmUpdateProjectPlan,
    isUpdatingPlan,
    isFetchingCheckoutSession,
    pendingUpdateForPlan,
    loading: isUpdatingPlan || isFetchingCheckoutSession || !!pendingUpdateForPlan
  };
}
