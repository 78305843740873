import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const requestPasswordResetMutation = gql`
	mutation RequestPasswordReset($emailAddress: String!) {
		Account {
			requestPasswordReset(email: $emailAddress)
		}
	}
`

const useRequestPasswordReset = () => {
	const [requestPasswordReset] = useMutation(requestPasswordResetMutation)
	return requestPasswordReset
}

export default useRequestPasswordReset