import React, {useEffect, useState, useMemo, useCallback } from "react"
import Loading from "Components/Loading"
import useProjectsList from "hooks/useProjectsList"
import ApolloClient from "../graphql/client"
export const SelectedProjectContext = React.createContext({})

// Localstorage operations
const getStoredSelectedProjectId = () => localStorage.getItem("selectedProjectId")
const updateStoredSelectedProject = (id) => localStorage.setItem("selectedProjectId", id)

const defaultValue = getStoredSelectedProjectId() || null

// No point converting to lum, will only be called once, so creating a lum would require loop + lookup,
// whereas find is just at worst single loop.
const findProject = (projects, matchId) => projects.find(({id}) => id === matchId)

const SelectedProjectProvider = ({ children }) => {
  const { projects, defaultProject, loading, refetch } = useProjectsList()
  const [selectedProject, setSelectedProject] = useState(null)

  const updateSelectedProject = useCallback((projectId) => {
    updateStoredSelectedProject(projectId)
    return ApolloClient.resetStore().then(() => {
      const foundProject = findProject(projects || [], projectId)
      setSelectedProject(foundProject);
      return foundProject;
    }
      )
  }, [projects])

  const refetchCurrentProject = useCallback(() => {
    return refetch().then(({ data }) => {
      const foundProject = findProject(data.projects || [], selectedProject?.id ?? data.projects[0].id )
      setSelectedProject(foundProject);
      return foundProject;
    })
  }, [refetch, selectedProject])


  // During initial render, projects won't exist (e.g. still loading). Therefore,
  // update the selected project once loaded. Same as componentDidUpdate in old React
  useEffect(() => {
    if (!!projects && projects.length > 0 && !selectedProject) {
      const project = findProject(projects, defaultValue) || defaultProject || {}
      setSelectedProject(project)
      project.id && updateStoredSelectedProject(project.id)
    }
  }, [projects, defaultProject, selectedProject])

  const value = useMemo(() => ({
      selectedProject,
      updateSelectedProject,
      refetchCurrentProject,
      projects
    }), [
      selectedProject,
      updateSelectedProject,
      refetchCurrentProject,
      projects
    ])

  return (
    <SelectedProjectContext.Provider
      value={value}
    >
      {loading ? <Loading /> : children}
    </SelectedProjectContext.Provider>
  )
}

export default SelectedProjectProvider
