import React, {useState} from 'react'
import prettify from 'code-prettify'
import copy from 'copy-to-clipboard'
import styled, { css } from 'styled-components'

import Button from '../Button'
import {Clipboard,Check} from '../Icons'

const Copier = ({text, className, onCopy}) => {
  const [showCopied, setShowCopied] = useState(false)

  return (
    <div className={className}>
      <Button type={"white"} onClick={() => {
        copy(text)
        setShowCopied(true)
        setTimeout(() => setShowCopied(false),1500)
        onCopy && onCopy()
      }}>
        {showCopied
          ? (<span className={"animated flash"}><Check/>Copied</span>)
          : (<><Clipboard style={{ width: 16, height: 16 }} />Copy</>)
        }
      </Button>
    </div>
  )
}

const PrettyPrinted = styled.pre`
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-size: 13px;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;

  background: #414141;

  .copier{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 4px;
    margin-right: 4px;
  }

  .linenums {
    margin-top: 0;
    margin-bottom: 0;
    color: black;
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }

  ol li {
    list-style: none;
    counter-increment: my-awesome-counter;
  }

  ol li::before {
    content: counter(my-awesome-counter) "";
    font-family: Source Code Pro;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;

    color: rgba(88, 70, 109, 0.31);;
  }

  // enable side-scrolling
  code{
    > div{padding-bottom:8px;padding-top:8px;}
    display: flex;
    .lineNumberBlock{
      flex-shrink: 0;
      .pln{ display: none }
    }
    .codeBlock{
      flex-grow: 1;
      overflow-x: auto;
      ol li::before { display: none; content:'' }
    }
  }

  ${props => props.lightMode ? css`
    font-weight: 500;
    background: #EBEBF7;

    ol li::before {color: #818181;}
    .pln {color: #979797;}
    .str {color: #BCC077;}
    .kwd {color: #58466D;}
    .com {color: #969896;}
    .typ {color: #EB4967;}
    .lit {color: #5EB037;}
    .pun {color: #5EB037;}
    .opn {color: #5EB037;}
    .clo {color: #5EB037;}
    .tag {color: #E89668;}
    .atn {color: #5EB037;}
    .atv {color: #EF4089;}

    /* declaration */
    .dec {color: #5EB037;}
    .var {color: #cc6666;}
    .fun {color: #81a2be;}

  ` : css`
    background: #414141;

    ol li::before {color: #818181;}


    .pln {color: #c5c8c6;}
    .str {color: #b5bd68;}
    .kwd {color: #58466D;}
    .com {color: #969896;}
    .typ {color: #81a2be;}
    .lit {color: #5EB037;}
    .pun {color: #5FCF91;}
    .opn {color: #5EB037;}
    .clo {color: #5EB037;}
    .tag {color: #E89668;}
    .atn {color: #5EB037;}
    .atv {color: #EF4089;}

    /* declaration */
    .dec {color: #5EB037;}
    .var {color: #cc6666;}
    .fun {color: #81a2be;}
  `}
`

class Code extends React.Component {
	htmlEscape(s) {
		return s
			.replace(/&/g, '&amp')
			.replace(/</g, '&lt')
			.replace(/>/g, '&gt')
	}

	render() {
	  const {codeString="",language,lightMode=false,copyable=false, className, onCopy} = this.props
		const codeData = prettify.prettyPrintOne( language === 'html' ? this.htmlEscape(codeString) : codeString,language, true)
    const linenums = prettify.prettyPrintOne(codeString.replace(/.*/g,''), language,true)

		return (
			<PrettyPrinted lightMode={lightMode} className={className}>
        {copyable && <Copier text={codeString} className="copier" onCopy={onCopy} />}
				<code>
          <div className={'lineNumberBlock'} dangerouslySetInnerHTML={{__html: linenums}} />
					<div className={'codeBlock'} dangerouslySetInnerHTML={{__html: codeData}} />
				</code>
			</PrettyPrinted>
		)
	}
}

export default Code
